// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_commentViewerNextBtn_click", inline: true, disabled: false };
const msgs = {
  start: ["comment viewer next-btn.", "click"],
  no_comments: ["comment viewer next-btn.", "click", "no comments found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_commentViewerNextBtn_click(COMMENTVIEWEREL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Show next comment...
  const comments = COMMENTVIEWEREL.querySelectorAll("[data-ref='comment']");
  if (!comments.length) return this.logger("warning", msgs.no_comments, "warning", logArgs);
  const commentsArr = [...comments];
  const currentComment = commentsArr.find((c) => c.dataset.isHidden === "false");
  const currentCommentIndex = commentsArr.indexOf(currentComment);
  const maxIndex = comments.length - 1;
  const nextCommentIndex = currentCommentIndex === maxIndex ? 0 : currentCommentIndex + 1;
  const nextComment = comments[nextCommentIndex];
  currentComment.dataset.isHidden = "true";
  nextComment.dataset.isHidden = "false";
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
