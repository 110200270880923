// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_slideChange", inline: true };
const msgs = {
  start: ["slide has changed"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_slideChange(ACTIVESLIDEINDEX) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  ////////////////////////////////// Active img. credits updt. ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Check for credits string array on comp. el...
  const compEl = this.element;
  const credits = compEl.dataset.credits;
  const has_credits = valid_str(credits);

  // Set active img credits...
  if (has_credits) {
    const credits_parsed = JSON.parse(credits);
    let activeImgCredits = credits_parsed[ACTIVESLIDEINDEX];
    if (!activeImgCredits || activeImgCredits === undefined) activeImgCredits = "";
    this.setState({ activeImgCredits });
  }

  /////////////////////////////////////// Slide dots updt. ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { sideDotsWrapper } = this.ref;
  if (sideDotsWrapper) {
    const slideDots = sideDotsWrapper.children;
    [...slideDots].forEach((dot, i) => dot.setAttribute("data-is-active", i === ACTIVESLIDEINDEX));
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
