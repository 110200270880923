// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_gameSelectBtn_click from "../eventHandlers/eh_gameSelectBtn_click.js";
import eh_gameSelectBtn_mouseLeave from "../eventHandlers/eh_gameSelectBtn_mouseLeave.js";
import eh_gameSelectBtn_mouseEnter from "../eventHandlers/eh_gameSelectBtn_mouseEnter.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_gameSelectBtns", inline: true };
const msgs = {
  start: ["init. gameSelectBtns"],
  end: ["init. gameSelectBtns", "init. success"],
  no_el: ["init. gameSelectBtns", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_gameSelectBtns() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { gameSelectBtns } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (gameSelectBtns === undefined) return this.cancel_featInit(msgs.no_el);
  if (gameSelectBtns.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Event hdl...
  gameSelectBtns.forEach((btn, i) => {
    btn.addEventListener("click", eh_gameSelectBtn_click.bind(this, btn));
    btn.addEventListener("mouseenter", eh_gameSelectBtn_mouseEnter.bind(this, btn));
    btn.addEventListener("mouseleave", eh_gameSelectBtn_mouseLeave.bind(this, btn));
  });

  // Init. btn. borders...
  this.init_btnSVGborders(gameSelectBtns);

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Conclude...
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
