// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.submit_comment", inline: true };
const msgs = {
  start: ["CommentSection", "API", "submit_comment"],
  no_text: ["CommentSection", "API", "submit_comment", "no text entered yet"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function submit_comment() {
  return new Promise(async (resolve, reject) => {
    //////////////////////////////////////////// Setup /////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    const { postId } = this.options;
    const { newCommentText } = this.state;
    const { commentInput, submitNotif, errorNotif_noText } = this.ref;
    this.logger("event", msgs.start, "event", logArgs);

    // Guard: Stop, if not text has been entered yet...
    if (!valid_str(newCommentText)) {
      // - Log error...
      // - Show error notification...
      // - Reject promise...
      console.log("%cNo text entered yet.", consoleStyles.error);
      errorNotif_noText.setAttribute("data-is-hidden", false);
      setTimeout(() => errorNotif_noText.setAttribute("data-is-hidden", true), 2000);
      return reject(new Error("No text entered yet."));
    }

    ////////////////////////////////////// Comment submission //////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    try {
      // Set up backend request...
      const req = new XMLHttpRequest();
      const data = new FormData();
      data.append("postID", postId);
      data.append("text", newCommentText);

      // Init. + send request...
      req.open("POST", "/api.post.submit.comment", true);
      req.onreadystatechange = async () => {
        ///////////////////////////
        // Successful subm. hdl. //
        ///////////////////////////

        if (req.readyState === 4 && req.status === 200) {
          console.log("%cPost comment submitted.", consoleStyles.success);

          // - Clear comment input...
          // - Clear new comment text state...
          // - Show submission notification (for 2s)...
          this.setState({ newCommentText: "" });
          commentInput.value = "";
          submitNotif.setAttribute("data-is-hidden", false);
          setTimeout(() => submitNotif.setAttribute("data-is-hidden", true), 2000);

          // Resolve promise...
          return resolve(req.responseText);
        }

        //////////////////
        // Failed subm. //
        //////////////////

        if (req.status !== 200) {
          console.log("%cPost comment submission failed.", consoleStyles.error);
          return reject(req.responseText);
        }
      };
      req.send(data);
    } catch (err) {
      console.log("%cError: ", consoleStyles.error, err);
      return reject(err);
    }
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
