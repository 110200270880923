// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_submitDrawingBtn_click", inline: true };
const msgs = {
  start: ["drawing-game-submit btn. has been clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_submitDrawingBtn_click() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const api = this.api;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - Submit drawing via...
  // - Insert drawing into viewer...
  // - Updt. currSubmitIndex_drawingGame opt...
  // - Move to next step...
  const drawingSubmission = await api.submit_drawing();
  const { drawingDataURL, submitIndex } = drawingSubmission || {};
  await api.insert_drawingIntoViewer({ url_drawing: drawingDataURL, submitIndex });
  this.options.currSubmitIndex_drawingGame = submitIndex;
  this.setState({ currentStep: "drawingSubmitted" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
