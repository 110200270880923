// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_filterMenuOpen", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`filterMenuOpen: ${CHANGE}`],
  no_stateChange: () => ["No change to state provided."],
  invalid_stateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_filterMenuOpen(CHANGES) {
  if (!("filterMenuOpen" in CHANGES)) return this.logger("error", msgs.no_stateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.filterMenuOpen))
    return this.logger("error", msgs.invalid_stateValue(CHANGES.filterMenuOpen), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { filterMenuOpen } = CHANGES;
  const { monthFilterMenu, activeMonthBtn } = this.ref;
  this.logger("state-change", msgs.stateChange(filterMenuOpen), "default", logArgs);

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  /////// filter menu is-open attr. updt. ////////
  ////////////////////////////////////////////////

  monthFilterMenu.setAttribute("data-is-open", filterMenuOpen);

  ////// filter btn. is-active attr. updt. ///////
  ////////////////////////////////////////////////

  activeMonthBtn.setAttribute("data-is-active", filterMenuOpen);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
