// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.submit_matrixAnswer", inline: true };
const msgs = {
  start: ["PartiForm", "API", "submit_matrixAnswer"],
};
const consoleStyles = {
  def: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function submit_matrixAnswer(ANSWERDATA, QUESTIONEL) {
  return new Promise((resolve, reject) => {
    //////////////////////////////// Setup /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const { participationPageId, with_consentCheck } = this.options;
    const progressBarEl = QUESTIONEL?.querySelector('[data-ref="answerUploadProgressBar"]');
    const submitBtnEl = QUESTIONEL?.querySelector('[data-ref="answerSubmitBtn"]');
    this.logger("event", msgs.start, "event", logArgs);

    ////////////////////// Backend submit-API request //////////////////////
    ////////////////////////////////////////////////////////////////////////

    // Hide submit btn...
    if (submitBtnEl) submitBtnEl.setAttribute("data-is-hidden", "true");

    // Set up backend request...
    const req = new XMLHttpRequest();
    const data = new FormData();
    const { questionText, answerMethod, answerText, answerImg } = ANSWERDATA;
    data.append("partiPageID", participationPageId);
    data.append("questionText", questionText);
    data.append("answerMethod", answerMethod);
    data.append("answerText", answerText);
    data.append("answerImg", answerImg);

    // Add consent check data (if enabled)...
    data.append("with_consentCheck", with_consentCheck);
    if (with_consentCheck) {
      const { userConsent } = this.state;
      if (userConsent) data.append("userConsentData", JSON.stringify(userConsent));
    }

    // - Track upload progress...
    // - Updt. progress bar...
    req.upload.onprogress = (EVENTDATA) => {
      if (answerMethod !== "img") return;
      if (EVENTDATA.lengthComputable) {
        const progress = (EVENTDATA.loaded / EVENTDATA.total) * 100;
        if (progressBarEl) progressBarEl.style.setProperty("--w", `${progress}%`);
        console.log(`%cUpload progress: ${progress.toFixed(2)}%`, consoleStyles.def);
      }
    };

    // Init. + send request...
    req.open("POST", "/api.parti-form.submit.matrix-answer", true);
    req.onreadystatechange = () => {
      ///////////////////////////
      // Successful subm. hdl. //
      ///////////////////////////

      if (req.readyState === 4 && req.status === 200) {
        console.log("%cParti. form matrix answer submitted.", consoleStyles.success);

        // - Store answer in state...
        // - Resolve promise...
        const userMatrixAnswer = {
          questionText,
          answerMethod,
          answerText,
          answerImg,
          xValue: null,
          yValue: null,
        };
        this.setState({ userMatrixAnswer });
        resolve(req.responseText);
      }

      //////////////////
      // Failed subm. //
      //////////////////

      if (req.status !== 200) {
        console.log("%cParti. form matrix answer submission failed.", consoleStyles.error);
        reject(req.responseText);
      }
    };
    req.send(data);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
