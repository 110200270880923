// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeImgCredits", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`activeImgCredits:`, CHANGE],
  no_stateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeImgCredits(CHANGES) {
  if (!("activeImgCredits" in CHANGES)) return this.logger("error", msgs.no_stateChange(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { activeImgCredits } = CHANGES;
  const { imgCreditsDisplay } = this.ref;
  this.logger("state-change", msgs.stateChange(activeImgCredits), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////// Img. credits display text updt. ////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (imgCreditsDisplay) imgCreditsDisplay.textContent = activeImgCredits;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
