// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_glossaryLink_click", inline: true };
const msgs = {
  start: ["glossary link was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_glossaryLink_click(LINKEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  ///////////////////////////////// Glossary term widget reveal. /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Get glossary term attached to link...
  const glossaryTerm = LINKEL.dataset.term;

  // Guard: stop, if no term found...
  if (!glossaryTerm) return;

  // Show widget (via API call which hdls. random positioning)...
  this.api.show_widget();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
