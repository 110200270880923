// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_prevVpWidth", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`prevVpWidth: ${CHANGE}`],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_prevVpWidth(CHANGES) {
  if (!("prevVpWidth" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { prevVpWidth } = CHANGES;
  this.logger("state-change", msgs.stateChange(prevVpWidth), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // [action]...

  ////////////// [some attr.] updt. //////////////
  ////////////////////////////////////////////////

  // [action]...
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
