// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.submit_comment", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "submit_comment"],
  end: ["PartiForm", "API", "submit_comment", "API call complete"],
  no_postId: ["DragCards", "API", "submit_comment", "No post id provided"],
  no_commentText: ["DragCards", "API", "submit_comment", "No comment text provided"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function submit_comment(
  DATA = {
    postId: null,
    commentText: null,
  }
) {
  return new Promise(async (resolve, reject) => {
    //////////////////////////////// Setup /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const { postId, commentText } = DATA;
    this.logger("event", msgs.start, "event", logArgs);

    // Guard...
    if (!postId) {
      this.logger("warning", msgs.no_postId, "warning", logArgs);
      return reject("No post id provided");
    }
    if (!commentText) {
      this.logger("warning", msgs.no_commentText, "warning", logArgs);
      return reject("No comment text provided");
    }

    ////////////////////////// Comment submission //////////////////////////
    ////////////////////////////////////////////////////////////////////////

    try {
      // Set up backend request...
      const req = new XMLHttpRequest();
      const data = new FormData();
      data.append("postID", postId);
      data.append("text", commentText);

      // Init. + send request...
      req.open("POST", "/api.post.submit.comment", true);
      req.onreadystatechange = async () => {
        ///////////////////////////
        // Successful subm. hdl. //
        ///////////////////////////

        if (req.readyState === 4 && req.status === 200) {
          console.log("%cPost comment submitted.", consoleStyles.success);

          // Resolve promise...
          resolve(req.responseText);
          return req.responseText;
        }

        //////////////////
        // Failed subm. //
        //////////////////

        if (req.status !== 200) {
          console.log("%cPost comment submission failed.", consoleStyles.error);
          reject(req.responseText);
          return req.responseText;
        }
      };
      req.send(data);
    } catch (err) {
      console.log("%cError: ", consoleStyles.error, err);
      reject(err);
    }

    /////////////////////////////// Conclude ///////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.logger("success", msgs.end, "success", logArgs);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
