// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_document_click" };
const loggerMsgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_document_click(ARGS) {
  // Setup...
  const { infoDisplay, listItems } = this.ref;
  const { eventData, _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  // Check if click occured outside of info display while it's visible...
  // - Deactivate list item...
  // - Close info display...
  if (this.state.infoDisplayState.is_hidden) return;
  const click_on_infoDisplay = infoDisplay.contains(eventData.target);
  const click_on_listItem = listItems.some((el) => el.contains(eventData.target));
  if (!click_on_infoDisplay && !click_on_listItem) this.api.close_infoDisplay();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
