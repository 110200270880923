// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_filterMenuOpen", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`filter menu open: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_filterMenuOpen(CHANGES) {
  if (!("filterMenuOpen" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.filterMenuOpen))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.change), "error", logArgs);

  // Setup...
  const { filterMenuOpen } = CHANGES;
  const { openFilterMenuBtn, filterMenu } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(filterMenuOpen), "default", logArgs);

  // Hide/Show filter menu...
  filterMenu.setAttribute("data-is-open", filterMenuOpen);

  // Toggle active-state of open-filter-menu-btn...
  openFilterMenuBtn.setAttribute("data-is-active", filterMenuOpen);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
