// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function format_playerDuration(SECONDS) {
  if (!SECONDS) return console.warn("format_playerDuration(): No valid duration in sec. provided");
  const hours = Math.floor(SECONDS / 3600);
  const minutes = Math.floor((SECONDS % 3600) / 60);
  const remainingSeconds = Math.floor(SECONDS % 60);
  let formattedDuration = "";
  if (hours > 0) formattedDuration += `${hours.toString().padStart(2, "0")}:`;
  formattedDuration += `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  return formattedDuration;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
