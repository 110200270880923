// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.set_pageHeaderColor", inline: true };
const loggerMsgs = {
  eventStart: ["PartiForm", "API", "set_pageHeaderColor"],
  noColor: ["PartiForm", "API", "set_pageHeaderColor", "no color provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_pageHeaderColor(COLOR) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!COLOR) return this.logger("error", loggerMsgs.noColor, "error", logArgs);

  // Set page header text color...
  const pageHeaderEl = document.querySelector("[g-component='SubpageHeader']");
  gsap.to(pageHeaderEl, { color: COLOR, duration: 0.7 });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
