// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_filterBtn_click from "../eventHandlers/eh_filterBtn_click.js";
import eh_openFilterMenuBtn_click from "../eventHandlers/eh_openFilterMenuBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_filterMenu", inline: true };
const msgs = {
  start: ["init. filterMenu"],
  end: ["init. filterMenu", "init. success"],
  no_filterMenuEl: ["init. filterMenu", "no filterMenu el. found"],
  no_openFilterMenuBtnEl: ["init. filterMenu", "no openFilterMenuBtn el. found"],
  no_filterBtnEls: ["init. filterMenu", "no filterBtn els. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_filterMenu() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { openFilterMenuBtn, filterMenu, filterBtns } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(filterMenu)) return this.cancel_featInit(msgs.no_filterMenuEl);
  if (!this.validate_refEl(openFilterMenuBtn)) return this.cancel_featInit(msgs.no_openFilterMenuBtnEl);
  if (filterBtns === undefined) return this.cancel_featInit(msgs.no_filterBtnEls);
  if (filterBtns.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_filterBtnEls);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Event hdl.
  openFilterMenuBtn?.addEventListener("click", eh_openFilterMenuBtn_click.bind(this));
  filterBtns
    .filter((btnEl) => this.validate_refEl(btnEl))
    .forEach((btnEl) => btnEl.addEventListener("click", eh_filterBtn_click.bind(this, btnEl)));

  // Set initial state...
  this.setState({
    filterMenuHidden: false,
    filterMenuPosition: "bottom",
    filterMenuOpen: false,
  });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
