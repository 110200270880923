// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_enterMatrixAnswerViewerBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["enter-matrix-answer-viewer-btn was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_enterMatrixAnswerViewerBtn_click(EVENTDATA) {
  // Setup...
  const api = this.api;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  ///////////////////// Matrix answer-viewer enter. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - set mode to 'entering_answerViewer'...
  // - reveal + scroll to next section group...
  // - block fullpage scroll...
  // - set mode to 'viewingMatrixAnswers'...
  // - set currentStep to 'viewMatrixAnswers'...

  this.setState({ mode: "entering_answerViewer" });
  api.show_sectionGroup("3");
  api.scrollTo_sectionGroup("3", () => console.log("scrolling to answer viewer..."));
  api.blockFullpageScroll();
  setTimeout(() => {
    this.setState({
      mode: "viewingMatrixAnswers",
      currentStep: "viewMatrixAnswers",
    });
  }, 700); // ← let scroll anim. finish (to do: promise based solution)
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
