// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_matrixAnswerSubmitBtn_click", inline: true };
const msgs = {
  start: ["matrix answer-submit btn. was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_matrixAnswerSubmitBtn_click(QUESTIONEL, DROPZONEINST) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  /////////////////////// Answer submit. API call ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Setup //
  const { questionText, answerMethod } = QUESTIONEL.dataset;
  let answerText = null;
  let answerImg = null;

  ///////////// Text answer extract. /////////////
  ////////////////////////////////////////////////

  if (answerMethod == "text") {
    const answerInputWrapper = QUESTIONEL.querySelector('[data-ref="answerInputWrapper"]');
    const answerInput = answerMethod == "text" ? answerInputWrapper.querySelector("textarea") : null;
    if (answerInput) answerText = answerInput.value;
  }

  ///////////// Img. answer extract. /////////////
  ////////////////////////////////////////////////

  if (answerMethod == "imgUpload" && DROPZONEINST) {
    const file = DROPZONEINST.files[0];
    if (file) answerImg = file;
  }

  /////// Multiple choice answer extract. ////////
  ////////////////////////////////////////////////

  if (answerMethod == "multipleChoice") {
    const customMultipleChoiceOpt = QUESTIONEL.querySelector('[data-ref="customMultipleChoiceOpt"]');
    const multipleChoiceOpts = QUESTIONEL.querySelectorAll('[data-ref="multipleChoiceOpt"]');
    const selectedOpt = Array.from(multipleChoiceOpts).find((opt) => opt.checked);
    if (selectedOpt) {
      answerText = selectedOpt.dataset.value;
    } else if (customMultipleChoiceOpt) {
      const customOptInput = customMultipleChoiceOpt.querySelector("input");
      if (customOptInput.value) answerText = customOptInput.value;
    }
  }

  /////////////////// API call ///////////////////
  ////////////////////////////////////////////////

  const answerData = { questionText, answerMethod, answerText, answerImg };
  const answerSubmitReq = await this.api.submit_matrixAnswer(answerData, QUESTIONEL);
  const answerSubmitRes = JSON.parse(answerSubmitReq);
  const { answerPageID } = answerSubmitRes;

  // - Move to next step...
  // - Store answer page ID...
  this.setState({
    currentStep: "matrixAnswerSubmitted",
    matrixAnswerPageId: answerPageID,
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
