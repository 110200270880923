// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.show_wordViewer", inline: true };
const msgs = {
  start: ["PartiForm", "API", "show_wordViewer"],
  end: ["PartiForm", "API", "show_wordViewer", "API call complete"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_wordViewer(WORDS) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { wordViewer } = this.ref;
  const { config_writingGame, currSubmitIndex_writingGame } = this.options;
  const { textpartsperuser: textPartsPerUser } = config_writingGame;
  const has_wordsToShow = WORDS && WORDS.length > 0;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (has_wordsToShow) {
    // Insert words into viewer display cards...
    // (skip card corresp. to curr. submit index if textPartsPerUser is set to 'one')...
    const wordDisplayCards = wordViewer.querySelectorAll("[data-ref='wordDisplayCard']");
    WORDS.forEach((word) => {
      const cardIndex = WORDS.indexOf(word);
      if (textPartsPerUser == "one" && cardIndex == currSubmitIndex_writingGame) return;
      const { catId, word: wordText } = word;
      const wordDisplayCard = [...wordDisplayCards].find((c) => c.dataset.catId == catId);
      if (wordDisplayCard) wordDisplayCard.textContent = wordText;
    });

    // Reveal viewer...
    wordViewer.setAttribute("data-is-hidden", false);
  } else {
    // Reveal viewer...
    wordViewer.setAttribute("data-is-hidden", false);
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
