// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function interpolate_HSLcolorBrightness(
  COLOR = {
    hue: 0,
    saturation: 0,
    brightness: 0,
  },
  PROGRESS,
  BRIGHTNESS_start
) {
  const { hue, saturation, brightness } = COLOR;
  const colorBrightness_end = brightness;
  const colorBrightness_curr = gsap.utils.interpolate(BRIGHTNESS_start, colorBrightness_end, PROGRESS);
  const color_curr = `hsl(${hue} ${saturation}% ${colorBrightness_curr}%)`;
  return color_curr;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
