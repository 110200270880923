// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_infoOverlayState", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`infoOverlayState:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_infoOverlayState(CHANGES) {
  if (!("infoOverlayState" in CHANGES)) return this.logger("error", msgs.error_noStateChange(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { infoOverlayState } = CHANGES;
  const { infoOverlay, openInfoOverlayBtn } = this.ref;
  this.logger("state-change", msgs.stateChange(infoOverlayState), "default", { ...logArgs, inline: false });

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // [action]...

  ////////////// is-hidden attr. updt. //////////////
  ////////////////////////////////////////////////

  if ("is_hidden" in infoOverlayState) {
    // Updt info overlay hidden attr...
    const is_hidden = infoOverlayState.is_hidden;
    infoOverlay.setAttribute("data-is-hidden", is_hidden);

    // Updt open info overlay btn active attr...
    openInfoOverlayBtn.setAttribute("data-is-active", !is_hidden);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
