// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * The `shrink_textToMinWidth` function is designed to adjust the width of a given DOM element (`EL`)
 * to its minimum possible width without altering its height. It is used to make a text-containing
 * element as narrow as possible without causing the text to wrap to a new line, which would increase
 * the element's height.
 *
 * The function works by first checking if a valid DOM element is provided. If not, it logs a warning
 * message to the console and returns.
 *
 * It then retrieves the current width and height of the element using the `getBoundingClientRect`
 * method. This gives the initial dimensions of the element.
 *
 * The function enters a loop, decrementing the width of the element by 1 pixel at each iteration
 * and applying this new width to the element's style. It continually checks if the height of the
 * element has changed. The height would change if the text inside the element wraps to a new line due
 * to the decreasing width.
 *
 * As soon as the height changes, the function breaks out of the loop. This means that the current
 * width is the minimum width that can be applied to the element without altering its height.
 *
 * After the loop, the function checks if the final width (`w`) is less than the scroll width of the
 * element (the width of the content including overflow). If it is, it sets the element's width and
 * maximum width to the scroll width. This ensures that all content is visible without scrolling.
 *
 * If the final width is not less than the scroll width, it sets the element's width to `w + 1` pixels.
 * This is likely to undo the last decrement that caused the height to change.
 *
 * @param {HTMLElement} EL - The DOM element to be resized.
 *
 * @returns {void}      If no valid DOM element is provided, a warning message is logged to the
 *                      console and the function returns.
 *
 */

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function shrink_textToMinWidth(EL) {
  if (!EL) return console.warn("No valid DOM el. provided to shrink_textToMinWidth()");
  const { width, height } = EL.getBoundingClientRect();

  let w;
  for (w = width; w; w--) {
    EL.style.width = w + "px";
    if (EL.offsetHeight !== height) break;
  }

  if (w < EL.scrollWidth) {
    EL.style.width = EL.style.maxWidth = EL.scrollWidth + "px";
  } else {
    EL.style.width = w + 1 + "px";
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
