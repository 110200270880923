// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_wordViewerNextBtn_click", inline: true };
const msgs = {
  eventStart: ["word-viewer-next-btn. was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_wordViewerNextBtn_click(EVENTDATA) {
  // Setup...
  const api = this.api;
  this.logger("event", msgs.eventStart, "event", logArgs);

  // Insert new words into viewer...
  const newViewerWords = await api.fetch_wordViewerWords();
  if (newViewerWords) api.show_wordViewer(newViewerWords);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
