// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_formHeaderState", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`form header state:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_formHeaderState(CHANGES) {
  if (!("formHeaderState" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { formHeaderState } = CHANGES;
  const { floatingFormHeader } = this.ref;
  const { is_hidden, titleText } = formHeaderState;
  const titleWrapper = floatingFormHeader.querySelector("[data-ref='titleWrapper']");
  this.logger("state-change", loggerMsgs.stateChange(formHeaderState), "default", { ...logArgs, inline: false });

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  ////////////// hidden attr. updt. //////////////
  ////////////////////////////////////////////////

  if ("is_hidden" in formHeaderState) floatingFormHeader.setAttribute("data-is-hidden", is_hidden);

  ////////////// title text updt. //////////////
  //////////////////////////////////////////////

  if ("titleText" in formHeaderState && titleText != null && titleWrapper) {
    // Find pill el. to contain title text...
    const titlePill = titleWrapper.querySelector("[g-ref='pills']");
    const pillTextEl = titlePill.querySelector("[data-ref='textEl']");

    // Update text content...
    if (pillTextEl) pillTextEl.textContent = titleText;
  }

  ///////// title height CSS var. updt. //////////
  ////////////////////////////////////////////////

  if ("titleHeight" in formHeaderState && titleWrapper) {
    const { titleHeight } = formHeaderState;
    this.element.style.setProperty("--h_floatingTitle", `${titleHeight}px`);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
