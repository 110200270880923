// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_windowScroll_stCh_scrollPosition" };
const loggerMsgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_windowScroll_stCh_scrollPosition(ARGS) {
  // Setup...
  const { scrollPercentage, _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  // Updt. headline-stretch state...
  this.setState({ headlineStrech: scrollPercentage });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
