// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_bgIlluStates", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`backgr. illu. states:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_bgIlluStates(CHANGES) {
  if (!("bgIlluStates" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { bgIlluStates } = CHANGES;
  const { bgIllus } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(bgIlluStates), "default", { ...logArgs, inline: false });

  // Execute state changes...
  bgIlluStates?.forEach((state) => {
    // Setup...
    const { id, is_hidden } = state;

    ////////////////////////////
    // Illu. visibility updt. //
    ////////////////////////////

    const bgIlluEl = bgIllus.find((el) => el.getAttribute("data-id") === id);
    if (bgIlluEl) bgIlluEl.setAttribute("data-is-hidden", is_hidden);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
