// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_mobileNextSectBtn", inline: true, disabled: false };
const msgs = {
  start: ["init. mobileNextSectBtn"],
  end: ["init. mobileNextSectBtn", "init. success"],
  no_el: ["init. mobileNextSectBtn", "no valid DOM el. provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_mobileNextSectBtn() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { mobileNextSectBtn } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(mobileNextSectBtn)) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Click hdl. //
  mobileNextSectBtn.addEventListener("click", () => {
    // Determ. next (sub)section based on curr. subsection...
    const { sections } = this.ref;
    const { currentSubsection } = this.state;
    const currSubsectionEl = sections.find((el) => el.dataset.id === currentSubsection);
    const index_currSubsect = sections.indexOf(currSubsectionEl);
    const nextSubsection = sections[index_currSubsect + 1];

    // - Scroll to next (sub)section...
    // - Updt. curr. subsection state...
    // - Hide mobileNextSectBtn...
    if (!nextSubsection) return;
    const id_nextSubsection = nextSubsection.dataset.id;
    // Special case //
    if (currentSubsection == "futureMatrix") {
      api.scrollTo_subsection(`section[data-id='futureMatrix'] section[data-ref='subSection_questions']`);
      api.set_pageBgColor("#fff");
      api.hide_pageHeader();
    }
    // Regular case //
    else {
      api.scrollTo_subsection(`section[g-ref='sections'][data-id='${id_nextSubsection}']`);
      this.setState({ currentSubsection: id_nextSubsection });
    }
    this.setState({ mobileNextSectBtnState: { ...this.state.mobileNextSectBtnState, is_hidden: true } });
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
