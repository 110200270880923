// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_eventCards from "./init/init_eventCards.js";
import init_eventContentSlider from "./init/init_eventContentSlider.js";
import init_eventCardContentScroller from "./init/init_eventCardContentScroller.js";
import init_eventList from "./init/init_eventList.js";
import init_widgets from "./init/init_widgets.js";
import init_activeMonthDisplay from "./init/init_activeMonthDisplay.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_windowScroll_stCh_scrollPosition from "./eventbusHandlers/ebh_windowScroll_stCh_scrollPosition.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_activeContext from "./stateChangeHandlers/stChH_activeContext.js";
import stChH_eventCardStates from "./stateChangeHandlers/stChH_eventCardStates.js";
import stChH_lastActiveEventCard from "./stateChangeHandlers/stChH_lastActiveEventCard.js";
import stChH_widgetStates from "./stateChangeHandlers/stChH_widgetStates.js";
import stChH_filterMenuOpen from "./stateChangeHandlers/stChH_filterMenuOpen.js";
import stChH_activeMonthFilters from "./stateChangeHandlers/stChH_activeMonthFilters.js";
import stChH_activeMonthDisplay_hidden from "./stateChangeHandlers/stChH_activeMonthDisplay_hidden.js";

import set_widgetPositions from "./api/set_widgetPositions.js";
import set_widgetPosition from "./api/set_widgetPosition.js";
import get_activeEventCard from "./api/get_activeEventCard.js";
import close_eventCard from "./api/close_eventCard.js";
import updt_widgets_onEventCardsChange from "./api/updt_widgets_onEventCardsChange.js";
import updt_widgetFollowerPos from "./api/updt_widgetFollowerPos.js";
import bringWidgetToFront from "./api/bringWidgetToFront.js";
import blockScroll from "./api/blockScroll.js";
import unblockScroll from "./api/unblockScroll.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// Default log styles

const defaultLogStyles = {
  default: "#6682d6",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00c853",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class EventCalendar extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      eventCardScroller: null,
      eventCardsWrapper: null,
      eventCards: [],
      eventListItems: [],
      background: null,
      eventWidget_date: null,
      eventWidget_marquee: null,
      eventWidget_description: null,
      eventWidget_img: null,
      eventWidget_slideShow: null,
      eventWidget_location: null,
      eventWidget_ticketLink: null,
      widgetFollower_location: null,
      activeMonthDisplay: null,
      activeMonthBtn: null,
      monthFilterMenu: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "EventCalendar",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
      //////
      breakpoints: {
        tablet_prt: 700,
        tablet_ls: 1000,
        desktop_sm: 1200,
      },
      //////
      // run_withLogs: false,
      // logStyles: defaultLogStyles,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_eventCardContentScroller = init_eventCardContentScroller.bind(this);
    this.init_eventContentSlider = init_eventContentSlider.bind(this);
    this.init_widgets = init_widgets.bind(this);
    this.init_eventList = init_eventList.bind(this);
    this.init_eventCards = init_eventCards.bind(this);
    this.init_activeMonthDisplay = init_activeMonthDisplay.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      updt_widgetFollowerPos: updt_widgetFollowerPos.bind(this),
      unblockScroll: unblockScroll.bind(this),
      blockScroll: blockScroll.bind(this),
      bringWidgetToFront: bringWidgetToFront.bind(this),
      set_widgetPosition: set_widgetPosition.bind(this),
      updt_widgets_onEventCardsChange: updt_widgets_onEventCardsChange.bind(this),
      close_eventCard: close_eventCard.bind(this),
      get_activeEventCard: get_activeEventCard.bind(this),
      set_widgetPositions: set_widgetPositions.bind(this),
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_windowScroll_stCh_scrollPosition = ebh_windowScroll_stCh_scrollPosition.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_activeMonthDisplay_hidden = stChH_activeMonthDisplay_hidden.bind(this);
    this.stChL_activeMonthFilters = stChH_activeMonthFilters.bind(this);
    this.stChL_filterMenuOpen = stChH_filterMenuOpen.bind(this);
    this.stChL_lastActiveEventCard = stChH_lastActiveEventCard.bind(this);
    this.stChL_widgetStates = stChH_widgetStates.bind(this);
    this.stChL_activeContext = stChH_activeContext.bind(this);
    this.stChL_eventCardStates = stChH_eventCardStates.bind(this);
    this.stChL_mode = stChH_mode.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { eventName: "mount", inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { eventName: "unmount", inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("windowScroll_stCh_scrollPosition", this.ebl_windowScroll_stCh_scrollPosition);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("EventCalendar.api.updt_widgetFollowerPos", this.api.updt_widgetFollowerPos);
    eventbus.off("EventCalendar.api.unblockScroll", this.api.unblockScroll);
    eventbus.off("EventCalendar.api.blockScroll", this.api.blockScroll);
    eventbus.off("EventCalendar.api.bringWidgetToFront", this.api.bringWidgetToFront);
    eventbus.off("EventCalendar.api.set_widgetPosition", this.api.set_widgetPosition);
    eventbus.off("EventCalendar.api.updt_widgets_onEventCardsChange", this.api.updt_widgets_onEventCardsChange);
    eventbus.off("EventCalendar.api.close_eventCard", this.api.close_eventCard);
    eventbus.off("EventCalendar.api.get_activeEventCard", this.api.get_activeEventCard);
    eventbus.off("EventCalendar.api.set_widgetPositions", this.api.set_widgetPositions);
    eventbus.off("EventCalendar.api.hideComp", this.api.hideComp);
    eventbus.off("EventCalendar.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.setState({ mode: "init" });
    this.init_states();
    this.init_widgets();
    this.init_eventCards();
    this.init_activeMonthDisplay();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    const { breakpoints } = this.options;
    const w_viewport = window.innerWidth;
    this.setState({
      activeMonthFilters: ["all"],
      activeMonthDisplay_hidden: false,
      is_mobile: w_viewport < breakpoints.tablet_prt,
      is_tablet_prt: w_viewport >= breakpoints.tablet_prt && w_viewport < breakpoints.tablet_ls,
      is_tablet_ls: w_viewport >= breakpoints.tablet_ls && w_viewport < breakpoints.desktop_sm,
      is_desktop_sm: w_viewport >= breakpoints.desktop_sm,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { eventName: "init_eventbus", inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("windowScroll_stCh_scrollPosition", this.ebl_windowScroll_stCh_scrollPosition);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("EventCalendar.api.updt_widgetFollowerPos", this.api.updt_widgetFollowerPos);
    eventbus.on("EventCalendar.api.unblockScroll", this.api.unblockScroll);
    eventbus.on("EventCalendar.api.blockScroll", this.api.blockScroll);
    eventbus.on("EventCalendar.api.bringWidgetToFront", this.api.bringWidgetToFront);
    eventbus.on("EventCalendar.api.set_widgetPosition", this.api.set_widgetPosition);
    eventbus.on("EventCalendar.api.updt_widgets_onEventCardsChange", this.api.updt_widgets_onEventCardsChange);
    eventbus.on("EventCalendar.api.close_eventCard", this.api.close_eventCard);
    eventbus.on("EventCalendar.api.get_activeEventCard", this.api.get_activeEventCard);
    eventbus.on("EventCalendar.api.set_widgetPositions", this.api.set_widgetPositions);
    eventbus.on("EventCalendar.api.hideComp", this.api.hideComp);
    eventbus.on("EventCalendar.api.showComp", this.api.showComp);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("activeMonthDisplay_hidden" in CHANGES) this.stChL_activeMonthDisplay_hidden(CHANGES);
    if ("activeMonthFilters" in CHANGES) this.stChL_activeMonthFilters(CHANGES);
    if ("filterMenuOpen" in CHANGES) this.stChL_filterMenuOpen(CHANGES);
    if ("lastActiveEventCard" in CHANGES) this.stChL_lastActiveEventCard(CHANGES);
    if ("widgetStates" in CHANGES) this.stChL_widgetStates(CHANGES);
    if ("activeContext" in CHANGES) this.stChL_activeContext(CHANGES);
    if ("eventCardStates" in CHANGES) this.stChL_eventCardStates(CHANGES);
    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default EventCalendar;
