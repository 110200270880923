// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getWidth, getRect } from "mezr";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const msgs = {
  start: "PartiForm → util. → updt. word card input width",
  end: "PartiForm → util. → updt. word card input width → util. call complete",
  no_inputEl: "PartiForm → util. → updt. word card input width → no valid input el. provided",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_wordCardInputWidth(INPUTEL) {
  if (!INPUTEL || !INPUTEL.tagName || INPUTEL.tagName !== "INPUT") return console.log(msgs.no_wordCardEl);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  console.log(msgs.start);

  // Measure width wrapping words-group el. to determ. max. input width...
  const wordSelectBtnEl = INPUTEL.parentElement.querySelector('[data-ref="selectCustomWordBtn"]');
  const wordSelectBtnWidth = getWidth(wordSelectBtnEl);
  const wordsGroupEl = INPUTEL.closest('[g-ref="writingGameWordGroups"]');
  const wordsGroupWidth = getWidth(wordsGroupEl);

  /////////////////////// Temp. span el. insert. /////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Create temp. span el...
  const tempSpan = document.createElement("span");

  // Copy relevant styles from input to span...
  tempSpan.style.fontSize = getComputedStyle(INPUTEL).fontSize;
  tempSpan.style.fontFamily = getComputedStyle(INPUTEL).fontFamily;
  tempSpan.style.fontWeight = getComputedStyle(INPUTEL).fontWeight;
  tempSpan.style.letterSpacing = getComputedStyle(INPUTEL).letterSpacing;

  // Make sure span is not visible in doc...
  tempSpan.style.position = "absolute";
  tempSpan.style.visibility = "hidden";
  tempSpan.style.whiteSpace = "pre"; // ← preserve spaces + tabs

  // Set span's text content to input's value...
  tempSpan.textContent = INPUTEL.value || INPUTEL.placeholder || " ";

  // Append span to doc. body...
  document.body.appendChild(tempSpan);

  /////////////// Temp. span measure + input width updt. /////////////////
  ////////////////////////////////////////////////////////////////////////

  const maxWidth = wordsGroupWidth - wordSelectBtnWidth;

  // Measure width of span, then remove it...
  let newWidth = tempSpan.offsetWidth;
  document.body.removeChild(tempSpan);

  // If new width fits in word group, set input width to new width...
  if (newWidth < maxWidth) {
    INPUTEL.style.width = `${newWidth}px`;
  }

  // Otherwise, set words group width as input width & max. width...
  else {
    newWidth = maxWidth;
    INPUTEL.style.width = `${newWidth}px`;
    INPUTEL.style.maxWidth = `${newWidth}px`;
  }

  ////////////////////////////// Right edge mesaure (after width updt.) ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Measure remaining space between input and words-group el. right edges...
  const wordCardEl = INPUTEL.closest('[g-ref="writingGameCustomWordCards"]');
  const rightEdge_wordsGroupEl = wordsGroupEl.getBoundingClientRect().right;
  const rightEdge_wordCard = getRect(wordCardEl).right;
  const remainingSpace = rightEdge_wordsGroupEl - rightEdge_wordCard;

  // If no remaining space, updt. word card translateX so that the word card aligns with right edge of words-group...
  if (remainingSpace < 0) {
    const wordCardWidth = getWidth(wordCardEl);
    const newTranslateX = wordsGroupWidth - wordCardWidth;
    wordCardEl.style.transform = `translateX(${newTranslateX}px)`;
  }

  ////////////////////////////// Conclude ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  console.log(msgs.end);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
