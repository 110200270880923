// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_input_consentAge_input", inline: true, disabled: false };
const msgs = {
  start: ["user typed in consent check age input"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_input_consentAge_input() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { input_consentName, input_consentAge, consentNameAgeSubmitBtn } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  ////////////////// Consent name/age submit btn. updt. //////////////////
  ////////////////////////////////////////////////////////////////////////

  // If both consentName and consentAge are filled, enable confirm button...
  const has_consentName = input_consentName?.value.length > 0;
  const has_consentAge = input_consentAge?.value.length > 0;
  consentNameAgeSubmitBtn.setAttribute("data-is-hidden", !(has_consentName && has_consentAge));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
