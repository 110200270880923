// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_windowScroll_stCh_scrollPosition" };
const msgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_windowScroll_stCh_scrollPosition(ARGS) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { _name } = ARGS;
  const api = this.api;
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  ////////////////////////////////// Active event card closing ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Close active event card when it scrolls out of view...
  const activeEventCard = api.get_activeEventCard();
  if (activeEventCard) {
    // Prevent closing for card that has been active on page load...
    if (activeEventCard.is_activeOnLoad) {
      // activeEventCard.isActiveOnLoad = false; // Reset flag...
    } else {
      const vpH = window.innerHeight;
      const cardRect = activeEventCard.el.getBoundingClientRect();
      const cardTop = cardRect.top;
      const cardBottom = cardRect.bottom;
      const is_outOfView = cardBottom < 0 || cardTop > vpH;
      if (cardBottom < 0 || cardTop > vpH) api.close_eventCard(activeEventCard.id);
    }
  }

  ////////////////////////////////// Active-month-display updt. //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Determ. which event card is closest to center of viewport...
  const { eventCards } = this.ref;
  const vpCenter = window.innerHeight / 2;
  const cardDistances = eventCards.map((card) => {
    const cardRect = card.getBoundingClientRect();
    const cardTop = cardRect.top;
    const cardBottom = cardRect.bottom;
    const cardCenter = cardTop + (cardBottom - cardTop) / 2;
    return { card, distance: Math.abs(vpCenter - cardCenter) };
  });
  const closestCard = cardDistances.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr)).card;

  // Updt. active month display to month of closest event card...
  const { activeMonthDisplay } = this.ref;
  const activeMonthTextEl = activeMonthDisplay.querySelector("[data-ref='activeMonthText']");
  if (activeMonthTextEl) activeMonthTextEl.textContent = closestCard.dataset.eventMonth;

  ////////////////////////////// Footer-in-view check & attr. updt. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // DEPRECATED //
  // const { eventCardScroller } = this.ref;
  // const selector_footerEl = "[data-ref='Nav:footer']";
  // const footerEl = document.querySelector(selector_footerEl);
  // const footerMenuEl = footerEl?.querySelector("nav");
  // if (footerMenuEl && eventCardScroller) {
  //   const footerMenuRect = footerMenuEl.getBoundingClientRect();
  //   const { top: top_footer } = footerMenuRect;
  //   const footerIsInView = top_footer < window.innerHeight;
  //   eventCardScroller.setAttribute("data-footer-in-view", footerIsInView);
  // }
  // DEPRECATED //
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
