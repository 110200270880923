// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_scrollTrigger_gameSelectSection", inline: true };
const msgs = {
  start: ["init. scrollTrigger_gameSelectSection"],
  end: ["init. scrollTrigger_gameSelectSection", "init. success"],
  no_sectionGroupEl: ["no valid section el. found"],
  no_sectionEl: ["no valid section group el. found"],
  no_overflowEl: ["no valid overflow el. found"],
  stop_mobile: ["scrollTrigger_gameSelectSection", "stop. on mobile"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_scrollTrigger_gameSelectSection() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { sections, sectionGroups, keynotes_floating } = this.ref;
  const { sectionHeadlines } = this.options;
  const gameSelectSect = sections.find((el) => el.dataset.id === "gameSelection");
  const introSectGroup = sectionGroups?.find((el) => el.dataset.id === "0");
  const overflowWrapperEL = introSectGroup.querySelector(".fp-overflow");
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(introSectGroup)) return this.cancel_featInit(msgs.no_sectionGroupEl);
  if (!this.validate_refEl(gameSelectSect)) return this.cancel_featInit(msgs.no_sectionEl);
  if (!this.validate_refEl(overflowWrapperEL)) return this.cancel_featInit(msgs.no_overflowEl);

  //////////////////////////////// Scroll trigger creation ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ScrollTrigger.create({
    trigger: gameSelectSect,
    scroller: overflowWrapperEL,
    start: "top center",
    end: "bottom center",
    onEnter: () => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // - Upt. form header state:
      //   - updt. form header title text...
      //   - show form header...
      this.setState({ formHeaderState: { is_hidden: false, titleText: sectionHeadlines?.game } });
    },
    onLeaveBack: () => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // Show floating keynote els. (unless in blocked mode)...
      const { mode } = this.state;
      const blockedModes = ["drawing", "futureMatrix", "entering_futureMatrix"];
      if (!blockedModes.includes(mode)) {
        if (keynotes_floating)
          keynotes_floating.forEach((el) => gsap.to(el, { opacity: 1, duration: 0.5, ease: "power2.inOut" }));
      }
    },
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
