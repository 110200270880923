// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.show_bgIllu", inline: true };
const loggerMsgs = {
  eventStart: ["PartiForm", "API", "show_bgIllu"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_bgIllu(ILLUID) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Set backgr. illu. states w/ updt. is_hidden values...
  const bgIlluStates = this.state.bgIlluStates || [];
  const bgIlluStates_new = bgIlluStates.map((state) => {
    const { id } = state;
    const is_hidden = id === ILLUID ? false : state.is_hidden;
    return { ...state, is_hidden };
  });
  this.setState({ bgIlluStates: bgIlluStates_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
