// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import calc_randomElPosition from "../../../../../app/baseUtilities/calc/calc_randomElPosition";
function valid_infoText(TEXT) {
  if (TEXT === undefined || !TEXT || typeof TEXT !== "string" || TEXT.length === 0) return false;
  return true;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_listItem_click", inline: true };
const msgs = {
  start: ["list item was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_listItem_click(ITEMEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { infoDisplay } = this.ref;
  this.logger("event", msgs.eventStart, "event", logArgs);

  //////////////////////////// Active list item & info display updt. /////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Updt. active-list-item state...
  // - Updt. info-display state...
  //   - text
  //   - context
  //   - visibility
  const itemID = ITEMEL.dataset.id;
  const itemInfoText = ITEMEL.dataset.infoText;
  const itemContext = ITEMEL.dataset.context;
  this.setState({
    activeListItem: { id: itemID },
    infoDisplayState: {
      text: itemInfoText,
      context: itemContext,
      is_hidden: !valid_infoText(itemInfoText),
    },
  });

  /////////////////////////////////// Random info display pos. ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Calc. random position anywhere on screen (with padding on all sides)...
  // - Updt. info-display state w/ new position...
  const position = calc_randomElPosition(infoDisplay, { padding: 100 });
  const infoDisplayState_new = { ...this.state.infoDisplayState, position };
  this.setState({ infoDisplayState: infoDisplayState_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
