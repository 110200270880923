// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_document_click", inline: true, disabled: false };
const msgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_document_click(ARGS) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { eventData, _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Stop, if click occurred inside search bar...
  const { searchBar } = this.ref;
  if (searchBar.contains(eventData.target)) return;

  // Toggle search input visibility...
  const searchBtnText = searchBar.querySelector("[data-ref='searchBtnText']");
  const searchInput = searchBar.querySelector("[data-ref='searchInput']");
  const searchBtn = searchBar.querySelector("[data-ref='searchBtn']");
  searchBtnText.setAttribute("data-is-hidden", "false");
  searchInput.setAttribute("data-is-hidden", "true");
  searchBtn.setAttribute("data-is-hidden", "true");

  // Clear search input...
  searchInput.value = "";
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
