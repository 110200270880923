// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.show_drawingViewer", inline: true };
const msgs = {
  start: ["PartiForm", "API", "show_drawingViewer"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_drawingViewer(IMGS) {
  return new Promise((resolve) => {
    //////////////////////////////////////////// Setup /////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    const { drawingViewer } = this.ref;
    const { currSubmitIndex_drawingGame } = this.options;
    const has_imgsToShow = IMGS && IMGS.length > 0;
    this.logger("event", msgs.start, "event", logArgs);

    //////////////////////////////////////////// ACTION ////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    if (has_imgsToShow) {
      // Insert imgs. into viewer sections
      // (skip section matching curr.submit index as it shows the curr.user’s img.)...
      const viewerSections = drawingViewer.querySelectorAll("[data-ref='viewerSection']");
      const imgs_toUse = IMGS.filter((img) => img.submitIndex != currSubmitIndex_drawingGame - 1);
      imgs_toUse.forEach((img, i) => {
        const { submitIndex, url } = img;
        const viewerSection = [...viewerSections].find((s) => s.dataset.index == submitIndex);
        if (viewerSection) {
          const image = new Image();
          image.src = url;
          image.addEventListener("load", () => {
            viewerSection.setAttribute("src", url);
            if (i === imgs_toUse.length - 1) {
              // Reveal viewer...
              setTimeout(() => {
                drawingViewer.setAttribute("data-is-hidden", false);
                resolve();
              }, 250);
            }
          });
        }
      });
    } else {
      // Reveal viewer...
      drawingViewer.setAttribute("data-is-hidden", false);
      resolve();
    }
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
