// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeSection", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`active section:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeSection(CHANGES) {
  if (!("activeSection" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { activeSection } = CHANGES;
  const { sections, illus } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(activeSection), "default", { ...logArgs, inline: false });

  // Updt. section data-is-active attr...
  sections.forEach((s) => s.setAttribute("data-is-active", s === activeSection.el));

  // Show illu. corresponding to active section (hide others)...
  illus.forEach((i) => i.setAttribute("data-is-active", i.dataset.id === activeSection.id));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
