// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.contract_cardBg", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "contract_cardBg"],
  end: ["DragCards", "API", "contract_cardBg", "API call complete"],
  no_cardEl: ["DragCards", "API", "contract_cardBg", "no valid card el. provided"],
  no_bgSVG: ["DragCards", "API", "contract_cardBg", "no valid card bg. SVG found"],
  no_elToContract: ["DragCards", "API", "contract_cardBg", "no valid SVG element to contract found"],
};
const animDuration = 0.4;

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function contract_cardBg(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.logger("error", msgs.no_cardEl, "error", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - Get card background SVG...
  // - Extract background shape data...
  const bgSVG = CARDEL.querySelector("[data-role='cardBgShape']");
  if (!bgSVG) return this.logger("error", msgs.no_bgSVG, "error", logArgs);
  const bgShape = CARDEL.dataset.bgShape;

  // - Get the SVG el. to contract...
  const el_toContract = bgSVG.querySelector(bgShape == "angular" ? "polygon" : "ellipse");
  if (!el_toContract) return this.logger("error", msgs.no_elToContract, "error", logArgs);

  // Contract background el...
  switch (bgShape) {
    case "angular":
      const points_initial = el_toContract.dataset.pointsInitial;
      gsap.to(el_toContract, { attr: { points: points_initial }, duration: animDuration });
      break;
    case "ellipse":
      const clipPathToExpand = bgSVG.getElementById("ellipseClip");
      const ellipseEl = clipPathToExpand.querySelector("ellipse");
      gsap.to(ellipseEl, { duration: animDuration, attr: { rx: 50, ry: 50 } });
      break;
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
