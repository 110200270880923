// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_formHeader", inline: true };
const msgs = {
  start: ["init. formHeader"],
  end: ["init. formHeader", "init. success"],
  no_el: ["init. formHeader", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_formHeader() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { floatingFormHeader } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(floatingFormHeader)) return this.cancel_featInit(msgs.no_el);

  /////////////////////////////////// Form header state init. ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Get init. header text...
  const initText = floatingFormHeader.dataset.initText;

  // Determ. form header title el. height...
  // (needed for subsequent spacing of elements in form component)
  const titleWrapper = floatingFormHeader.querySelector("[data-ref='titleWrapper']");
  const titlePill = titleWrapper.querySelector("[g-ref='pills']");
  const titleHeight = titlePill.getBoundingClientRect().height;

  // Set init. form header state...
  const formHeaderState = { is_hidden: false, titleText: initText, titleHeight };
  this.setState({ formHeaderState });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
