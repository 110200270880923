// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_enterDrawingViewerBtn_click from "../eventHandlers/eh_enterDrawingViewerBtn_click.js";
import eh_drawingViewerNextBtn_click from "../eventHandlers/eh_drawingViewerNextBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_drawingViewer", inline: true };
const msgs = {
  start: ["init. drawingViewer"],
  end: ["init. drawingViewer", "init. success"],
  no_el: ["init. drawingViewer", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_drawingViewer() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { drawingViewer, enterDrawingViewerBtn, drawingViewerDownloadBtn, drawingViewerNextBtn } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(drawingViewer)) return this.cancel_featInit(msgs.no_el);

  /////////////////////// Enter-viewer btn. init. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (enterDrawingViewerBtn) enterDrawingViewerBtn.addEventListener("click", eh_enterDrawingViewerBtn_click.bind(this));

  /////////////////////// Download-btn. init. ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (drawingViewerDownloadBtn) drawingViewerDownloadBtn.addEventListener("click", () => this.api.download_drawingViewerImg());

  //////////////////////////// Next-btn. init. ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (drawingViewerNextBtn) drawingViewerNextBtn.addEventListener("click", eh_drawingViewerNextBtn_click.bind(this));

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
