// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function adjust_borderRadius(KEYNOTEEL, KEYNOTEEL_floating) {
  const borderSVG = KEYNOTEEL.querySelector("[data-ref='borderSVG']");
  const borderSVG_floating = KEYNOTEEL_floating ? KEYNOTEEL_floating.querySelector("[data-ref='borderSVG']") : null;

  if (borderSVG) {
    const borderShape = borderSVG.querySelector("rect");
    const { width, height } = KEYNOTEEL.getBoundingClientRect();
    const cornerRadius = Math.min(width, height) * 0.5;
    borderShape.setAttribute("rx", cornerRadius);
    borderShape.setAttribute("ry", cornerRadius);

    // Ddjust corner rad. of floating keynote el. as well...
    if (borderSVG_floating) {
      const borderShape_floating = borderSVG_floating.querySelector("rect");
      borderShape_floating.setAttribute("rx", cornerRadius);
      borderShape_floating.setAttribute("ry", cornerRadius);
    }
  }
}

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_keynotes", inline: true };
const loggerMsgs = {
  initStart: ["init. keynotes"],
  initSuccess: ["init. keynotes", "init. success"],
  noValidDOMEl: ["init. keynotes", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_keynotes() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { keynotes, keynotes_floating } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (keynotes === undefined) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (keynotes.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  //////////////////////////// Keynotes init. ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - Set corner radius of keynotes’ border SVG...
  // - Position floating keynotes in relation to their corresp. inline keynotes...
  // - Set width/height of floating keynotes to match their corresp. inline keynotes...
  keynotes.forEach(async (keynote, i) => {
    // Get corresp. floating keynote el...
    const floatingKeynoteEl = keynotes_floating[i];

    // Border SVG corner rad. adjustm. //
    adjust_borderRadius(keynote, floatingKeynoteEl);

    // Floating keynote el. positioning/sizing //
    if (floatingKeynoteEl) {
      const keynoteRect = keynote.getBoundingClientRect();
      const { width, height } = keynoteRect;
      const { x } = keynoteRect;
      gsap.set(floatingKeynoteEl, { width, height, left: x });
    }
  });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
