// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.releaseSubsectionScroll", inline: true, disabled: false };
const loggerMsgs = {
  eventStart: ["PartiForm", "API", "releaseSubsectionScroll"],
  eventEnd: ["PartiForm", "API", "releaseSubsectionScroll", "API call complete"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function releaseSubsectionScroll() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // ...

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", loggerMsgs.eventEnd, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
