// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import html2canvas from "html2canvas";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.download_wordViewerImg", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "download_wordViewerImg"],
  end: ["PartiForm", "API", "download_wordViewerImg", "API call complete"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function download_wordViewerImg() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { wordViewer } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Convert word viewer DOM el. to image...
  const displayCardsWrapper = wordViewer.querySelector("[data-ref='displayCardsWrapper']");
  html2canvas(displayCardsWrapper, {
    allowTaint: true,
    useCORS: true,
    y: -20,
  }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const imgLink = document.createElement("a");
    imgLink.href = imgData;
    imgLink.download = "text.png";
    imgLink.click();
    document.body.removeChild(imgLink);
  });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
