// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import TweenMax from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.set_widgetPositions", inline: true };
const loggerMsgs = {
  eventStart: ["EventCalendar", "API", "set_widgetPositions"],
  noWidgetEls: ["EventCalendar", "API", "set_widgetPositions", "no valid widget element(s) provided"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_widgetPositions(WIDGETS) {
  if (WIDGETS === undefined) return this.logger("error", loggerMsgs.noWidgetEls, "error", logArgs);

  // Setup...
  const { eventCardsWrapper } = this.ref;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Measure viewport dimensions...
  const { innerWidth: w_vp, innerHeight: h_vp } = window;

  // Measure eventCardsWrapper...
  const cardsWrapperRect = eventCardsWrapper.getBoundingClientRect();

  // Randomly position widgets without overlap...
  const occupiedPositions = [];
  const maxOverlap = 40; // ← allow for small overlap
  WIDGETS.forEach((widget) => {
    const widgetRect = widget.getBoundingClientRect();
    const { width: w_widget, height: h_widget } = widgetRect;

    const w_leftSpace = cardsWrapperRect.left - 100; // Ensure min. 100px margin from left vp. edge
    const w_rightSpace = w_vp - cardsWrapperRect.right - 100; // Ensure min. 100px margin from right vp. edge

    let pos;
    let overlap;
    let attempts = 0;

    do {
      const placeOnLeftSide = Math.random() < w_leftSpace / (w_leftSpace + w_rightSpace + 200); // Adjusted for margins
      if (placeOnLeftSide) {
        pos = {
          x: 100 + Math.floor(Math.random() * Math.max(0, w_leftSpace - w_widget)), // Ensure 100px margin from left edge
          y: 100 + Math.floor(Math.random() * Math.max(0, h_vp - h_widget - 200)), // Adjust for top and bottom margins
        };
      } else {
        pos = {
          x: Math.floor(cardsWrapperRect.right + Math.random() * Math.max(0, w_rightSpace - w_widget)),
          y: 100 + Math.floor(Math.random() * Math.max(0, h_vp - h_widget - 200)), // Adjust for top and bottom margins
        };
      }

      // Check for overlap with any already positioned widget
      overlap = occupiedPositions.some((occupied) => {
        return !(
          pos.x + w_widget - maxOverlap < occupied.x + maxOverlap ||
          pos.x + maxOverlap > occupied.x + occupied.width - maxOverlap ||
          pos.y + h_widget - maxOverlap < occupied.y + maxOverlap ||
          pos.y + maxOverlap > occupied.y + occupied.height - maxOverlap
        );
      });

      attempts++;
    } while (overlap && attempts < 100); // Limit attempts to prevent infinite loop

    if (!overlap) {
      TweenMax.set(widget, { x: pos.x, y: pos.y });
      occupiedPositions.push({ x: pos.x, y: pos.y, width: w_widget, height: h_widget });
    } else {
      console.log("Could not find a non-overlapping position for widget after 100 attempts.");
    }
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
