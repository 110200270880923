// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_surveyForm_textArea_input from "../eventHandlers/eh_surveyForm_textArea_input.js";
import eh_surveyForm_submitBtn_click from "../eventHandlers/eh_surveyForm_submitBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_surveyForm", inline: true };
const msgs = {
  start: ["init. surveyForm"],
  end: ["init. surveyForm", "init. success"],
  no_el: ["init. surveyForm", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_surveyForm(CARDEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// Event hdl. ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Submit btn. //
  const submitBtn = CARDEL.querySelector("[data-ref='submitBtn']");
  if (submitBtn) submitBtn.addEventListener("click", eh_surveyForm_submitBtn_click.bind(this, CARDEL));

  // Text area input //
  const textArea = CARDEL.querySelector("[data-ref='answerInput']");
  if (textArea) textArea.addEventListener("input", eh_surveyForm_textArea_input.bind(this, CARDEL));

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
