// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { Howl, Howler } from "howler";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import format_playerDuration from "../util/format_playerDuration";

function updt_timeDisp(DISPLAYEL, TIME) {
  DISPLAYEL.textContent = format_playerDuration(TIME);
}

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_audioPlayer", inline: true };
const loggerMsgs = {
  initStart: ["init. audioPlayer"],
  initSuccess: ["init. audioPlayer", "init. success"],
  noValidDOMEl: ["init. audioPlayer", "no valid DOM element(s) provided"],
  noFollowerEl: ["init. audioPlayer", "no follower element found"],
  noAudioPlayerEl: ["init. audioPlayer", "no audioPlayer element found"],
  noSrc: ["init. audioPlayer", "no audio source provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_audioPlayer(CARDEL, SRC) {
  // Setup...
  const { cardFollowers } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (!SRC) return this.cancel_featInit(loggerMsgs.noSrc);

  ///////////////////////
  // AudioPlayer init. //
  ///////////////////////

  // Get player el. (inside follower el.)...
  const cardID = CARDEL.dataset.id;
  const cardFollower = cardFollowers.find((el) => el.dataset.parentCardId === cardID);
  if (!cardFollower) return this.cancel_featInit(loggerMsgs.noFollowerEl);
  const audioPlayerEl = cardFollower.querySelector("[data-role='audioPlayer']");
  if (!audioPlayerEl) return this.cancel_featInit(loggerMsgs.noAudioPlayerEl);

  // Create Howl instance...
  const playerInst = new Howl({ src: [SRC] });

  // Wait for player to load...
  playerInst.on("load", () => {
    // Init. controls...
    const playBtn = audioPlayerEl.querySelector("[data-role='playBtn']");
    const pauseBtn = audioPlayerEl.querySelector("[data-role='pauseBtn']");
    const stopBtn = audioPlayerEl.querySelector("[data-role='stopBtn']");
    if (this.validate_refEl(playBtn)) playBtn.addEventListener("click", () => playerInst.play());
    if (this.validate_refEl(pauseBtn)) pauseBtn.addEventListener("click", () => playerInst.pause());
    if (this.validate_refEl(stopBtn)) stopBtn.addEventListener("click", () => playerInst.stop());

    // Init. time display...
    const currTimeDisp = audioPlayerEl.querySelector("[data-role='currentTimeDisp']");
    const durationDisp = audioPlayerEl.querySelector("[data-role='durationDisp']");
    if (this.validate_refEl(durationDisp)) durationDisp.textContent = format_playerDuration(playerInst.duration());
    if (this.validate_refEl(currTimeDisp)) {
      let timeUpdtIntervalID;
      playerInst.on("play", () => (timeUpdtIntervalID = setInterval(() => updt_timeDisp(currTimeDisp, playerInst.seek()), 1000)));
      playerInst.on("pause", () => clearInterval(timeUpdtIntervalID));
      playerInst.on("stop", () => ((currTimeDisp.textContent = "00:00"), clearInterval(timeUpdtIntervalID)));
    }

    // Conclude...
    this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
