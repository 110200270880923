// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_selectedCanvasSect", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`selected canvas sect. index: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_selectedCanvasSect(CHANGES) {
  if (!("selectedCanvasSect" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { selectedCanvasSect } = CHANGES;
  const { drawingCanvasSections } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(selectedCanvasSect), "default", logArgs);

  // - Updt. is-selected attr. on canvas sections...
  // - Disable unselected canvas sections...
  drawingCanvasSections.forEach((s, i) => {
    s.setAttribute("data-is-selected", i === selectedCanvasSect);
    s.setAttribute("data-is-disabled", i !== selectedCanvasSect);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
