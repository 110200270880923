// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.submit_matrixAnswerXYvalues", inline: true };
const loggerMsgs = {
  eventStart: ["PartiForm", "API", "submit_matrixAnswerXYvalues"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function submit_matrixAnswerXYvalues(XYVALUES) {
  return new Promise((resolve, reject) => {
    //////////////////////////////// Setup /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const { participationPageId } = this.options;
    const { matrixAnswerPageId } = this.state;
    const { xValue, yValue } = XYVALUES;
    this.logger("event", loggerMsgs.eventStart, "event", logArgs);

    ///////////////////////// Backend API request //////////////////////////
    ////////////////////////////////////////////////////////////////////////

    ////////////////////// Backend submit-API request //////////////////////
    ////////////////////////////////////////////////////////////////////////

    // Set up backend request...
    const req = new XMLHttpRequest();
    const data = new FormData();
    data.append("partiPageID", participationPageId);
    data.append("answerPageID", matrixAnswerPageId);
    data.append("xValue", xValue);
    data.append("yValue", yValue);

    // Init. + send request...
    req.open("POST", "/api.parti-form.submit.matrix-answer.xy-values", true);
    req.onreadystatechange = () => {
      ///////////////////////////
      // Successful subm. hdl. //
      ///////////////////////////

      if (req.readyState === 4 && req.status === 200) {
        console.log("%cParti. form matrix answer x/y values submitted.", consoleStyles.success);

        // Updt. user matrix answer state...
        this.setState({ userMatrixAnswer: { ...this.state.userMatrixAnswer, xValue, yValue } });

        // Add matrix answer as datapoint to matrix answer graph (chart inst.)...
        this.api.add_matrixAnswerGraphDataPoint(this.state.userMatrixAnswer);

        // Resolve promise...
        resolve(req.responseText);
      }

      //////////////////
      // Failed subm. //
      //////////////////

      if (req.status !== 200) {
        console.log("%cParti. form matrix answer x/y values submission failed.", consoleStyles.error);
        reject(req.responseText);
      }
    };
    req.send(data);

    /////////////////////////////// Conclude ///////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // ...
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
