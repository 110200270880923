// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`menu state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_menuState(CHANGES) {
  if (!("menuState" in CHANGES)) return;

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { menuState } = CHANGES;
  const { menu, menuBtn, links } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(menuState));

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////// is-closed ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_open" in menuState) {
    // - Toggle data-is-closed attr. on menu el...
    // - Toggle data-is-active attr. on menuBtn el...
    const { is_open } = menuState;
    menu.setAttribute("data-is-closed", !is_open);
    menuBtn.setAttribute("data-is-active", is_open);
  }

  ////////////////////////////// activeLink //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("activeLink" in menuState) {
    // Updt. data-is-active attr. on link els...
    const { activeLink } = menuState;
    links.forEach((l) => l.setAttribute("data-is-active", l.dataset.id === activeLink));
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
