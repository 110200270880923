// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_enterMatrixAnswerGraphBtn_click from "../eventHandlers/eh_enterMatrixAnswerGraphBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_enterMatrixAnswerGraphBtn", inline: true };
const loggerMsgs = {
  initStart: ["init. enterMatrixAnswerGraphBtn"],
  initSuccess: ["init. enterMatrixAnswerGraphBtn", "init. success"],
  noValidDOMEl: ["init. enterMatrixAnswerGraphBtn", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_enterMatrixAnswerGraphBtn() {
  // Setup...
  const { enterMatrixAnswerGraphBtn } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(enterMatrixAnswerGraphBtn)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Event hdl...
  enterMatrixAnswerGraphBtn.addEventListener("click", eh_enterMatrixAnswerGraphBtn_click.bind(this));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
