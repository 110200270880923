// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stretch_textToWrapperWidth(TEXTEL) {
  if (!TEXTEL) return console.warn("stretch_textToWrapperWidth() → no valid text el. provided to");

  // Scale text to fit wrapper width...
  const wrapper = TEXTEL.parentElement;
  const scale = wrapper.getBoundingClientRect().width / TEXTEL.getBoundingClientRect().width;
  TEXTEL.style.transform = `scaleX(${scale})`;
}

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_strechedCardTags", inline: true };
const loggerMsgs = {
  initStart: ["init. strechedCardTags"],
  initSuccess: ["init. strechedCardTags", "init. success"],
  noCardEl: ["init. strechedCardTags", "no valid card el. provided"],
  noTagEls: ["init. strechedCardTags", "no valid tag els. found in card el."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_strechedCardTags(CARDEL) {
  // Setup...
  const tagEls = CARDEL.querySelectorAll('[data-role="tag"]');
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(loggerMsgs.noCardEl);
  if (!tagEls || !tagEls.length) return this.cancel_featInit(loggerMsgs.noTagEls);

  // Strech tag text to wrapper width...
  tagEls.forEach((t) => {
    const textEl = t.querySelector("span");
    if (textEl) stretch_textToWrapperWidth(textEl);
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
