// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect } from "mezr";
import gsap from "gsap";

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.updt_cardFollowerPos", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "updt_cardFollowerPos"],
  end: ["PartiForm", "API", "updt_cardFollowerPos", "API call complete"],
  no_cardEl: ["DragCards", "API", "updt_cardFollowerPos", "no valid card el. provided"],
  no_followerEl: (CARDEL) => ["DragCards", "API", "updt_cardFollowerPos", "no follower el. found for card el.: ", CARDEL],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_cardFollowerPos(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.logger("error", msgs.no_cardEl, "error", logArgs);

  ///////////////////// Card follower position updt. /////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Find related follower el...
  const followerEl = this.get_cardFollowerEl(CARDEL);
  if (!followerEl) return this.logger("error", msgs.no_followerEl(CARDEL), "error", { ...logArgs, inline: false });

  // Get card & follower el. dimensions...
  const rect_card = getRect(CARDEL);
  const l_card = rect_card.left;
  const b_card = rect_card.bottom;

  // Get offset of follower container relative to viewport...
  const followerContainerEl = followerEl.offsetParent;
  const rect_container = getRect(followerContainerEl);
  const l_container = rect_container.left;
  const t_container = rect_container.top;

  // Determ. follower x/y pos. (account for container offset)...
  const x = l_card - l_container + rect_card.width / 3;
  const y = b_card - t_container;

  // Set card follower pos...
  gsap.to(followerEl, { duration: 0.3, ease: "power3.out", x, y });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
