// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Marquee from "vanilla-marquee";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. marquee"],
  initSuccess: ["init. marquee", "init. success"],
  noValidDOMEl: ["init. marquee", "no valid DOM element provided"],
  noMarqueeEl: ["init. marquee", "no marquee element found"],
};

const defaultMarqueeConfig = {
  startVisible: true,
  duplicated: true,
  speed: 40,
  gap: 0,
  delayBeforeStart: 1000,
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_marquee(CARDEL, CONFIG = defaultMarqueeConfig) {
  // Setup...
  const marqueeConfig = { ...defaultMarqueeConfig, ...CONFIG };
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Init. marquee...
  const marqueeEl = CARDEL.querySelector("[data-role='marquee']");
  if (!marqueeEl) return this.cancel_featInit(loggerMsgs.noMarqueeEl);
  const marquee = new Marquee(marqueeEl, marqueeConfig);

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
