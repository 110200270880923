// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.fetch_drawingViewerImgs", inline: true };
const loggerMsgs = {
  eventStart: ["PartiForm", "API", "fetch_drawingViewerImgs"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function fetch_drawingViewerImgs() {
  return new Promise((resolve, reject) => {
    // Setup...
    this.logger("event", loggerMsgs.eventStart, "event", logArgs);

    // Fetch drawings via API route...
    const { participationPageId: partiPageID } = this.options;
    const url = "/api.parti-form.get.drawings";
    const data = { partiPageID, random: true };
    const options = { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(data) };
    try {
      fetch(url, options)
        .then((res) => res.json())
        .then((respData) => {
          // Guard...
          if (!respData) return reject("No submit index data found for page.");

          // Resolve...
          const drawings = respData.drawings;
          resolve(drawings);
        })
        .catch((respError) => {
          // Guard...
          if (respError) return reject(respError);

          // Resolve...
          resolve([]);
        });
    } catch (error) {
      reject(error);
    }
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
