// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_window_resize(ARGS) {
  // Setup...
  const { _name } = ARGS;
  const flicktiyInst = this.modules.flickity.instance;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Updt. is_mobile state...
  this.setState({ is_mobile: window.innerWidth < 640 });

  // Updt. flickity instance...
  if (flicktiyInst) {
    flicktiyInst.resize();
    flicktiyInst.reposition();
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
