// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_declineBtn_researchUseConsent_click from "../eventHandlers/eh_declineBtn_researchUseConsent_click.js";
import eh_confirmBtn_researchUseConsent_click from "../eventHandlers/eh_confirmBtn_researchUseConsent_click.js";
import eh_input_consentName_input from "../eventHandlers/eh_input_consentName_input.js";
import eh_input_consentAge_input from "../eventHandlers/eh_input_consentAge_input.js";
import eh_consentNameAgeSubmitBtn_click from "../eventHandlers/eh_consentNameAgeSubmitBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_consentCheck", inline: true, disabled: false };
const msgs = {
  start: ["init. consentCheck"],
  end: ["init. consentCheck", "init. success"],
  no_el: ["init. consentCheck", "no valid DOM el. provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_consentCheck() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const {
    consentQuest_researchUse,
    consentQuest_nameAgeQuery,
    confirmBtn_researchUseConsent: confirmBtn_researchUse,
    declineBtn_researchUseConsent: declineBtn_researchUse,
    input_consentName,
    input_consentAge,
    consentNameAgeSubmitBtn,
  } = this.ref;

  const ELEMENTS = [
    consentQuest_researchUse,
    consentQuest_nameAgeQuery,
    confirmBtn_researchUse,
    declineBtn_researchUse,
    input_consentName,
    input_consentAge,
    consentNameAgeSubmitBtn,
  ];

  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (ELEMENTS.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Event hdl. //
  if (confirmBtn_researchUse) confirmBtn_researchUse.addEventListener("click", eh_confirmBtn_researchUseConsent_click.bind(this));
  if (declineBtn_researchUse) declineBtn_researchUse.addEventListener("click", eh_declineBtn_researchUseConsent_click.bind(this));
  if (input_consentName) input_consentName.addEventListener("input", eh_input_consentName_input.bind(this));
  if (input_consentAge) input_consentAge.addEventListener("input", eh_input_consentAge_input.bind(this));
  if (consentNameAgeSubmitBtn) consentNameAgeSubmitBtn.addEventListener("click", eh_consentNameAgeSubmitBtn_click.bind(this));

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
