// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_sectionGroups", inline: true };
const loggerMsgs = {
  initStart: ["init. sectionGroups"],
  initSuccess: ["init. sectionGroups", "init. success"],
  noValidDOMEl: ["init. sectionGroups", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_sectionGroups() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { sectionGroups } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (sectionGroups === undefined) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (sectionGroups.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  ////////////////////// Section group states init. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Set init. section group states...
  const sectionGroupStates = sectionGroups.map((el) => ({
    el,
    id: el.dataset.id,
    is_hidden: el.dataset.isHidden === "true",
  }));
  this.setState({ sectionGroupStates });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
