// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_slideChange from "../eventHandlers/eh_slideChange.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_flickity", inline: true };
const msgs = {
  start: ["init. flickity"],
  end: ["init. flickity", "init. success"],
  no_el: ["init. flickity", "no valid DOM element(s) provided"],
};

const defaultFlktyConfig = {
  wraparound: true,
  cellalign: "center",
  draggable: false,
  fade: false,
  autoplay: false,
  selectedattraction: 0.025,
  friction: 0.28,
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_flickity() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { slidesWrapper } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(slidesWrapper)) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Create Flickity instance...
  const config = defaultFlktyConfig;
  const flickityInst = new Flickity(slidesWrapper, {
    prevNextButtons: false,
    pageDots: false,
    //////
    wrapAround: config.wraparound,
    cellAlign: config.cellalign,
    autoPlay: config.autoplay,
    selectedAttraction: config.selectedattraction,
    draggable: true,
    fade: config.fade,
    friction: config.friction,
    //////
    on: { change: (INDEX) => eh_slideChange.bind(this)(INDEX) },
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
  return flickityInst;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
