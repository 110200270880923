// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_consentNameAgeSubmitBtn_click", inline: true, disabled: false };
const msgs = {
  start: ["consent name/age submit btn. has been clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_consentNameAgeSubmitBtn_click() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { input_consentName, input_consentAge } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  ////////////////// Consent info submit. (state updt.) //////////////////
  ////////////////////////////////////////////////////////////////////////

  // - Store entered name/age in user-consent state...
  // - set curr. step to 'consentCheckDone' (move to next step)...
  const consentName = input_consentName.value;
  const consentAge = input_consentAge.value;
  const { userConsent } = this.state;
  const userConsent_new = { ...userConsent, name: consentName, age: consentAge };
  this.setState({
    userConsent: userConsent_new,
    currentStep: "consentCheckDone",
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
