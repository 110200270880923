// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import html2canvas from "html2canvas";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.download_drawingViewerImg", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "download_drawingViewerImg"],
  end: ["PartiForm", "API", "download_drawingViewerImg", "API call complete"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function download_drawingViewerImg() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { drawingViewer } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Convert word viewer DOM el. to image...
  const viewerSectionsWrapper = drawingViewer.querySelector("[data-ref='viewerSectionsWrapper']");
  html2canvas(viewerSectionsWrapper, {
    allowTaint: true,
    useCORS: true,
  }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const imgLink = document.createElement("a");
    imgLink.href = imgData;
    imgLink.download = "zeichnung.png";
    imgLink.click();
    document.body.removeChild(imgLink);
  });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
