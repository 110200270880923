// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect, getWidth } from "mezr";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_cardCommentBtn_click", inline: true, disabled: false };
const msgs = {
  start: ["card comment btn. click"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_cardCommentBtn_click(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const api = this.api;
  this.logger("event", msgs.start, "event", logArgs);

  ////////////////////// Comment widget reveal/hide //////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { commentWidgetState } = this.state;
  const { is_hidden } = commentWidgetState;

  //////////////// Widget reveal /////////////////
  ////////////////////////////////////////////////

  if (is_hidden) {
    // Fetch post comments...
    const postId = CARDEL.dataset.id;
    const commentsData = await api.fetch_postComments(postId);

    // Get card dimensions...
    const rect_card = getRect(CARDEL);
    const w_card = getWidth(CARDEL);
    const l_card = rect_card.left;
    const b_card = rect_card.bottom;

    // Determ. new widget x/y pos.
    const x = l_card;
    const y = b_card;

    // Show + reposition comment widget via comp. API...
    api.show_commentWidget({ x, y }, w_card, commentsData);
  }

  //////////////// Widget hide /////////////////
  ////////////////////////////////////////////////
  else {
    // Hide comment widget via comp. API...
    api.hide_commentWidget();
  }

  //////////////////////// Comment widget anchor el. setting /////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Mark card el. as anchor el. of comment widget...
  CARDEL.setAttribute("data-is-comment-widget-anchor", "true");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
