// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getHeight } from "mezr";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_mobileInstructCardState", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`mobileInstructCardState:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_mobileInstructCardState(CHANGES) {
  if (!("mobileInstructCardState" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { mobileInstructCardState } = CHANGES;
  const { mobileInstructCard } = this.ref;
  this.logger("state-change", msgs.stateChange(mobileInstructCardState), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////// hidden attr. updt. //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_hidden" in mobileInstructCardState) {
    const { is_hidden } = mobileInstructCardState;
    mobileInstructCard.setAttribute("data-is-hidden", is_hidden);
  }

  /////////////////////////// Card text updt. ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("text" in mobileInstructCardState) {
    const { text } = mobileInstructCardState;

    // - Find text wrapper el...
    // - Updt. text content...
    const cardTextWrapper = mobileInstructCard.querySelector("[data-ref='textWrapper']");
    if (cardTextWrapper) cardTextWrapper.textContent = text;

    // Re-measure + updt. text wrapper widht/height...
    cardTextWrapper.style.setProperty("--h", `auto`); // ← reset height before re-measuring
    const h_cardTextWrapper = getHeight(cardTextWrapper);
    cardTextWrapper.style.setProperty("--h", `${h_cardTextWrapper}px`);
  }

  ////////////////////////// Card colors updt. ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("color_bg" in mobileInstructCardState) {
    const { color_bg } = mobileInstructCardState;
    mobileInstructCard.style.setProperty("--color-bg", color_bg);
  }

  if ("color_text" in mobileInstructCardState) {
    const { color_text } = mobileInstructCardState;
    mobileInstructCard.style.setProperty("--color-text", color_text);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
