// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.releaseFullpageScroll", inline: true };
const msgs = {
  eventStart: ["PartiForm", "API", "releaseFullpageScroll"],
  eventEnd: ["PartiForm", "API", "releaseFullpageScroll", "API call complete"],
  noSectionGroupID: ["PartiForm", "API", "show_section", "no section group ID provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function releaseFullpageScroll() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const fullpageInst = this.modules.fullpage.instance;
  this.logger("event", msgs.eventStart, "event", logArgs);

  // Guard...
  if (!fullpageInst) return this.logger("error", msgs.noFullpageInst, "error", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Release fullpage scroll...
  fullpageInst.setAllowScrolling(true);

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.eventEnd, "event", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
