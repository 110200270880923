// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";
import compare_stateArrays from "../../../../../app/baseUtilities/compare/compare_stateArrays";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.processSteps_updtInstructCards_drawingGame", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "processSteps_updtInstructCards_drawingGame"],
  end: ["PartiForm", "API", "processSteps_updtInstructCards_drawingGame", "API call complete"],
  no_stepString: () => ["PartiForm", "API", "processSteps_updtInstructCards_writingGame", "No step string provided."],
  stepNotAllowed: (step) => ["PartiForm", "API", "processSteps_updtInstructCards_writingGame", `step not allowed: ${step}`],
  drawingGameDisabled: [
    "PartiForm",
    "API",
    "processSteps_updtInstructCards_drawingGame",
    "Drawing Game is disabled.",
    "stopping step process",
  ],
};

const possibleSteps = ["startDrawingGame", "selectDrawTool", "startDrawing", "drawingSubmitted", "viewDrawings"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function processSteps_updtInstructCards_drawingGame(
  DATA = {
    step: null,
  }
) {
  if (!valid_str(DATA?.step)) return this.logger("error", msgs.no_stepString(), "error", logArgs);
  if (!possibleSteps.includes(DATA?.step)) return this.logger("error", msgs.stepNotAllowed(DATA?.step), "error", logArgs);
  if (!this.options.with_drawingGame) return this.logger("warning", msgs.drawingGameDisabled, "warning", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { step } = DATA;
  const { instructCardStates } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const instructCardStates_new = instructCardStates.map((cS) => {
    const { sectionId, stepId } = cS;
    let is_hidden = cS.is_hidden;
    let is_active = cS.is_active;
    switch (true) {
      ////////////// Case: does not belong to "drawing" section //////////////
      ////////////////////////////////////////////////////////////////////////

      case sectionId != "drawing":
        is_hidden = true;
        is_active = false;
        break;

      ////////////////// Case: belongs to "drawing" section //////////////////
      ////////////////////////////////////////////////////////////////////////

      case sectionId == "drawing":
        switch (true) {
          ////// Case: Card corresp. to curr. step ///////
          ////////////////////////////////////////////////

          case stepId == step:
            is_hidden = false; // ← show card
            is_active = true; // ← set active (reveals card text)
            break;

          // Case: Card does not corresp. to curr. step //
          ////////////////////////////////////////////////

          case stepId != step:
            is_active = false; // ← set inactive (hides card text)
            break;

          // // Case: step index is equal to curr. step index...
          // case cardStepIndex == currStepIndex:
          //   is_hidden = false;
          //   is_active = true;
          //   break;

          // // Special case: step index is 2, set card 2 and 3 to active...
          // case currStepIndex == 2 && cardStepIndex == 2:
          // case currStepIndex == 2 && cardStepIndex == 3:
          //   is_hidden = false;
          //   is_active = true;
          //   break;

          // // Case: step index is equal or less than curr. step index...
          // case cardStepIndex <= currStepIndex:
          //   is_hidden = false;
          //   is_active = false;
          //   break;

          // default:
          //   is_hidden = true;
          //   is_active = false;
          //   break;
        }
      // break;

      ///////////////////////////// Default case /////////////////////////////
      ////////////////////////////////////////////////////////////////////////

      default:
        break;
    }

    return { ...cS, is_hidden, is_active };
  });

  // Conditional state updt. //
  const instructCardStates_haveChanged = compare_stateArrays(instructCardStates, instructCardStates_new);
  if (instructCardStates_haveChanged) this.setState({ instructCardStates: instructCardStates_new });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
