// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getWidth, getHeight } from "mezr";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_card_click from "../eventHandlers/eh_card_click.js";
import eh_card_mouseLeave from "../eventHandlers/eh_card_mouseLeave.js";
import eh_card_mouseEnter from "../eventHandlers/eh_card_mouseEnter.js";
import eh_card_drag from "../eventHandlers/eh_card_drag.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_cards", inline: true };
const msgs = {
  start: ["init. cards"],
  end: ["init. cards", "init. success"],
  no_el: ["init. cards", "no valid DOM element provided"],
  setInitPos: ["init. cards", "setting init. card positions"],
  shiftInitPos: ["init. cards", "shifting init. card grid positions"],
  formatCardData: ["init. cards", "formatting card data"],
  registerDragPlugin: ["init. cards", "registering GSAP Draggable plugin"],
};
const initCardState = { is_hidden: false, is_hovered: false, is_dragging: false, is_expanded: false };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function init_cards() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { cards: cardElements } = this.ref;
  const { is_mobile, is_tablet_prt } = this.state;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (cardElements.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  /////////////////////////////////////// Card data prep. ////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Format card data (extract info. needed to init. card from card els.)...
  this.logger("init", msgs.formatCardData, "action", logArgs);
  const cardData = this.format_cardData(cardElements);

  ////////////////////////////////////////// Card init. //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const cardStates = []; // ← used to store card states during card init.
  cardData.forEach((c) => {
    //////////////////////////////// Setup /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const { el, config } = c;
    if (!el) return console.warn("init. card: No valid DOM el. found");
    const { type, with_marquee, with_follower, with_comments, followerConfig, mediaSrcs, with_animBorder } = config;
    const { src_audioFile = null } = mediaSrcs ?? {};
    const with_podcast = config.with_podcast && src_audioFile;

    ////////////////////////////// Event hdl. //////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    el.addEventListener("click", eh_card_click.bind(this, el));
    el.addEventListener("mouseenter", eh_card_mouseEnter.bind(this, el));
    el.addEventListener("mouseleave", eh_card_mouseLeave.bind(this, el));

    //////////////////////////// Feature init. /////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    if (with_marquee) this.init_marquee(el);
    if (with_podcast) this.init_audioPlayer(el, src_audioFile);
    // if (type == "contactForm") this.init_contactForm(el); // DEPRECATED
    if (type == "surveyForm") this.init_surveyForm(el);
    if (with_follower) this.init_cardFollower(el, followerConfig);
    if (with_animBorder) this.init_animCardBorder(el);
    if (with_comments) this.init_cardCommentBtn(el);
    if (type == "slideShow") this.init_cardSlideShow(el);

    /////////////////////////// Visual adjustm. ////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // Measure and set explicit card dimensions...
    // (if card type is 'img_withoutBg', set dimensions based on img. dimensions)
    const types_toResize = ["img_withoutBg"];
    if (types_toResize.includes(type)) {
      const img = el.querySelector("img");
      if (img) {
        el.style.setProperty("--w", `${getWidth(img)}px`);
        el.style.setProperty("--h", `${getHeight(img)}px`);
      }
    }
    // DEPRECATED //
    // } else if (type != "post" && type != "event") {
    // el.style.setProperty("--w", `${getWidth(el)}px`);
    // el.style.setProperty("--h", `${getHeight(el)}px`);
    // }
    // DEPRECATED //

    ////////////////////////// State formulation ///////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const cardState = { el, config, state: { ...initCardState } };
    cardStates.push(cardState);
  });

  // Set init. card states...
  this.setState({ cardStates });

  ///////////////////////////////////// Card position init. //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Set random init. card positions...
  if (!is_mobile) {
    this.logger("init", msgs.setInitPos, "action", logArgs);
    await this.api.distribute_cards();
  }

  /////////////////////////////////////// Draggable init. ////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Register Draggable plugin...
  this.logger("init", msgs.registerDragPlugin, "action", logArgs);
  gsap.registerPlugin(Draggable);

  // Create Draggable instances for each card...
  const instConfig = { type: "x,y", edgeResistance: 0.65, inertia: true };
  const dragCards = cardData.map((c) => ({
    id: c.el.dataset.id,
    el: c.el,
    type: c.config.type,
    dragInst: Draggable.create(c.el, {
      ...instConfig,
      onDrag: eh_card_drag.bind(this, c.el),
    }),
  }));
  this.setState({ cardDragEnabled: true });

  // Store card instances...
  this.modules.cards.instances = dragCards;

  // When page loaded on mobile, disable card drag...
  if (is_mobile) this.api.disable_cardDrag_all();

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
