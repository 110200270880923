// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.blockScroll", inline: true, disabled: false };
const msgs = {
  start: ["EventCalendar", "API", "blockScroll"],
  end: ["EventCalendar", "API", "blockScroll", "API call complete"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function blockScroll() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Preserve page headline stretch value...
  const subpageHeaderEl = document.querySelector('[g-component="SubpageHeader"]');
  if (subpageHeaderEl) {
    const headlineEl = subpageHeaderEl.querySelector('[g-ref="headline"]');
    if (headlineEl) {
      const headlineStretch = getComputedStyle(subpageHeaderEl).getPropertyValue("--headlineStretch");
      headlineEl.style.transform = `scaleX(${headlineStretch})`;
    }
  }

  // Block scroll...
  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  document.body.style.overflow = "hidden";
  document.body.style.position = "fixed";
  document.body.style.top = `-${scrollPosition}px`;
  document.body.style.width = "100%";
  this.setState({ lastScrollPosition: scrollPosition });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
