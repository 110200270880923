// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_filterMenuHidden", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`filter menu hidden: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_filterMenuHidden(CHANGES) {
  if (!("filterMenuHidden" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.filterMenuHidden))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.filterMenuHidden), "error", logArgs);

  // Setup...
  const { filterMenuHidden } = CHANGES;
  const { filterMenuWrapper } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(filterMenuHidden), "default", logArgs);

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  ////////////// data-is-hidden attr. updt. //////////////
  ////////////////////////////////////////////////

  filterMenuWrapper.setAttribute("data-is-hidden", filterMenuHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
