// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import calc_randomElPosition from "../../../../../app/baseUtilities/calc/calc_randomElPosition";
function valid_infoText(TEXT) {
  if (TEXT === undefined || !TEXT || typeof TEXT !== "string" || TEXT.length === 0) return false;
  return true;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_listItem_click", inline: true };
const msgs = {
  start: ["list item was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_listItem_click(ITEMEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { infoDisplay } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////// Active list item & info display updt. /////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Updt. active-list-item state...
  // - Updt. info-display state...
  //   - Text
  //   - Visibility
  //   - Colors (from item el. data-attr.)
  const itemID = ITEMEL.dataset.id;
  const itemInfoText = ITEMEL.dataset.infoText;
  const itemContributorNames = ITEMEL.dataset?.contributors;
  const infoDate = ITEMEL.dataset.date;
  const has_contributors = itemContributorNames !== undefined && itemContributorNames.length > 0;
  this.setState({
    activeListItem: { id: itemID },
    infoDisplayState: {
      text: itemInfoText,
      contributors: has_contributors ? JSON.parse(itemContributorNames) : [],
      date: infoDate,
      colors: {
        bg: ITEMEL.dataset.infoBgColor,
        txt: ITEMEL.dataset.infoTextColor,
        txt_contributors: ITEMEL.dataset.contributorTextColor,
        bg_contributors: ITEMEL.dataset.contributorBgColor,
      },
      is_hidden: !valid_infoText(itemInfoText),
    },
  });

  /////////////////////////////////// Random info display pos. ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Calc. random position anywhere on screen (with padding on all sides)...
  // - Updt. info-display state w/ new position...
  const position = calc_randomElPosition(infoDisplay, { padding: 100 });
  const infoDisplayState_new = { ...this.state.infoDisplayState, position };
  this.setState({ infoDisplayState: infoDisplayState_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
