// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_menu from "./init/init_menu.js";
import init_colorSwitch from "./init/init_colorSwitch.js";
import init_menuBtn from "./init/init_menuBtn.js";
import init_searchBar from "./init/init_searchBar.js";
import init_siteTitle from "./init/init_siteTitle.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_page_load from "./eventbusHandlers/ebh_swup_page_load.js";
import ebh_swup_content_replace from "./eventbusHandlers/ebh_swup_content_replace.js";
import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_document_click from "./eventbusHandlers/ebh_document_click.js";

import stChH_menuState from "./stateChangeHandlers/stChH_menuState.js";
import stChH_colorEnabled from "./stateChangeHandlers/stChH_colorEnabled.js";
import stChH_hidden from "./stateChangeHandlers/stChH_hidden.js";

import hide_searchBar from "./api/hide_searchBar.js";
import show_searchBar from "./api/show_searchBar.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// ——————————————————————————————————————— COMP. DEFINITION ——————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class Nav extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      menuBtn: null,
      menu: null,
      links: [],
      linkGroups: [],
      colorSwitch: null,
      searchBar: null,
      siteControls: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "Nav",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_menu = init_menu.bind(this);
    this.init_searchBar = init_searchBar.bind(this);
    this.init_siteTitle = init_siteTitle.bind(this);
    this.init_colorSwitch = init_colorSwitch.bind(this);
    this.init_menuBtn = init_menuBtn.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
      openMenu: () => this.setState({ menuState: { is_open: true } }),
      closeMenu: () => this.setState({ menuState: { is_open: false } }),
      toggleMenu: () => this.setState({ menuState: { is_open: !this.state.menuState.is_open } }),
      show_searchBar: show_searchBar.bind(this),
      hide_searchBar: hide_searchBar.bind(this),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_swup_content_replace = ebh_swup_content_replace.bind(this);
    this.ebl_document_click = ebh_document_click.bind(this);
    this.ebl_swup_page_load = ebh_swup_page_load.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_hidden = stChH_hidden.bind(this);
    this.stChL_colorEnabled = stChH_colorEnabled.bind(this);
    this.stChL_menuState = stChH_menuState.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("swup_page_load", this.ebl_swup_page_load);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("Nav.api.hide_searchBar", this.api.hide_searchBar);
    eventbus.off("Nav.api.show_searchBar", this.api.show_searchBar);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init_menu();
    this.init_searchBar();
    this.init_siteTitle();
    this.init_colorSwitch();
    this.init_menuBtn();
    this.init_states();
    this.init_eventbus();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    const { hidden_onLoad = false } = this.options;
    this.setState({ is_mobile: window.innerWidth < 640, colorEnabled: true, hidden: hidden_onLoad });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    eventbus.on("swup_content_replace", this.ebl_swup_content_replace);
    eventbus.on("document_click", this.ebl_document_click);
    this.logger("init", ["eventbus"], "action", { inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("swup_page_load", this.ebl_swup_page_load);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("Nav.api.hide_searchBar", this.api.hide_searchBar);
    eventbus.on("Nav.api.show_searchBar", this.api.show_searchBar);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("hidden" in CHANGES) this.stChL_hidden(CHANGES);
    if ("colorEnabled" in CHANGES) this.stChL_colorEnabled(CHANGES);
    if ("menuState" in CHANGES) this.stChL_menuState(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default Nav;
