// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_menu", inline: true, disabled: false };
const msgs = {
  start: ["init. menu"],
  end: ["init. menu", "init. success"],
  no_el: ["init. menu", "no valid DOM el. provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_menu() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { menu, links } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);
  console.log({ menu, links });

  // Guard...
  if (!this.validate_refEl(menu)) return this.cancel_featInit(msgs.no_el);
  if (links === undefined) return this.cancel_featInit(msgs.no_el);
  if (links.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Find init. active link...
  const activeLink = links.find((el) => el.dataset.isActive === "true");
  const id_activeLink = activeLink?.dataset.id;

  // Set init. menu state...
  const menuState_init = { is_open: false, activeLink: id_activeLink };
  this.setState({ menuState: menuState_init });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
