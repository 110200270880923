// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_commentWidgetState", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`commentWidgetState:`, CHANGE],
  no_stateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_commentWidgetState(CHANGES) {
  if (!("commentWidgetState" in CHANGES)) return this.logger("error", msgs.no_stateChange(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { commentWidgetState } = CHANGES;
  const { commentWidget } = this.ref;
  this.logger("state-change", msgs.stateChange(commentWidgetState), "default", { ...logArgs, inline: false });

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  ///////////////// comments updt. //////////////////
  ////////////////////////////////////////////////

  if ("comments" in commentWidgetState) {
    const { comments } = commentWidgetState;
    const commentViewer = commentWidget.querySelector("[data-ref='commentViewer']");
    if (commentViewer) {
      comments.forEach((c, i) => {
        // Define comment markup...
        const { dateTagColors } = c;
        const is_first = i === 0;
        const commentMarkup = `
          <div data-ref      ="comment"
               data-is-hidden="${!is_first}"
               class         ="flex flex-col items-start hidden-via-attr-full">
            <div class="w-full bg-white shadow-btn">
              ${c.text}
            </div>
            <footer class="inline-flex txt-stretch-sm-trim-btn"
                    style="--color-text: ${dateTagColors.text};
                           --color-bg  : ${dateTagColors.bg};">
              <div class="pr-[0.12rem] text-[--color-text] bg-[--color-bg]">
                <span>${c.date}</span>
              </div>
              <div class="pl-[0.12rem] pr-[0.12rem] text-[--color-bg] bg-[--color-text]">
                <span>${c.time}</span>
              </div>
            </footer>
          </div>`;

        // Insert comment markup...
        commentViewer.insertAdjacentHTML("beforeend", commentMarkup);
      });
    }
  }

  ///////////////// width updt. //////////////////
  ////////////////////////////////////////////////

  if ("width" in commentWidgetState) {
    const { width } = commentWidgetState;
    gsap.set(commentWidget, { width });
  }

  //////////////// position updt. ////////////////
  ////////////////////////////////////////////////

  if ("position" in commentWidgetState) {
    const { position } = commentWidgetState;
    const { x, y } = position;
    gsap.set(commentWidget, { x, y });
  }

  ///////////// data-is-hidden updt. /////////////
  ////////////////////////////////////////////////

  if ("is_hidden" in commentWidgetState) {
    const { is_hidden } = commentWidgetState;
    commentWidget.setAttribute("data-is-hidden", is_hidden);

    // If hidden, clear comments...
    if (is_hidden) {
      const commentViewer = commentWidget.querySelector("[data-ref='commentViewer']");
      if (commentViewer) {
        const commentNodes = Array.from(commentViewer.querySelectorAll("[data-ref='comment']"));
        commentNodes.forEach((c) => c.remove());
      }
    }
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
