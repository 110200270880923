// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_window_resize", inline: true };
const loggerMsgs = {
  adjustCardPos_forPortrait: ["Adjusting card positions for portrait viewport ratio"],
  adjustCardPos_forLandscape: ["Adjusting card positions for landscape viewport ratio"],
};

function get_cardOpts(CARDEL) {
  const cardOptions = CARDEL.getAttribute("g-options");
  return JSON.parse(cardOptions);
}

function adjust_cardPos(CARDEL, POS) {
  gsap.to(CARDEL, { x: `${POS.x}vw`, y: `${POS.y}vw`, duration: 0.5, ease: "power2.out" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_window_resize(ARGS) {
  // Setup...
  const { _name } = ARGS;
  const { cards } = this.ref;
  this.logger("eventbus call", [_name], "event", logArgs);

  // Updt. is_mobile state...
  this.setState({ is_mobile: window.innerWidth < 640 });

  ////////////////////////////////////////////////////////////////
  // Card pos. adjustment for portrait/landscape viewport ratio //
  ////////////////////////////////////////////////////////////////

  const vpRatio = window.innerWidth / window.innerHeight;
  const vpRatio_isPortrait = vpRatio < 1;

  // Portrait ratio adjustment //
  if (vpRatio_isPortrait) {
    this.logger("action", loggerMsgs.adjustCardPos_forPortrait, "action", logArgs);
    cards.forEach((cardEl) => {
      // Get portrait ratio position opt. & adjust card position...
      const cardOpts = get_cardOpts(cardEl);
      const { initPos_portraitRatio } = cardOpts ?? { initPos_portraitRatio: { x: 0, y: 0 } };
      adjust_cardPos(cardEl, initPos_portraitRatio);
    });
  }

  // Landscape ratio adjustment //
  else {
    this.logger("action", loggerMsgs.adjustCardPos_forLandscape, "action", logArgs);
    cards.forEach((cardEl) => {
      // Get landscape ratio position opt. & adjust card position...
      const cardOpts = get_cardOpts(cardEl);
      const { initPos } = cardOpts ?? { initPos: { x: 0, y: 0 } };
      adjust_cardPos(cardEl, initPos);
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  // Card pos. adjustment to keep cards pos. relative to viewport bottom (if applicable) //
  /////////////////////////////////////////////////////////////////////////////////////////

  const is_mobile = window.innerWidth < 640;
  if (!is_mobile) this.api.updt_cardPos_toBeRelativeToVp();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
