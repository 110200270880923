// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_undoDrawBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["undo-draw btn. was clicked"],
  noFabricInst: ["no fabric instance available"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_undoDrawBtn_click(EVENTDATA) {
  // Setup...
  const fabricInst = this.modules.fabric.instances[0];
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!fabricInst) return this.logger("error", loggerMsgs.noFabricInst, "error", logArgs);

  // Undo last drawing action...
  fabricInst.undo();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
