// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_filterMenuPosition", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`filterMenuPosition: ${CHANGE}`],
  no_stateChange: () => ["No change to state provided."],
  invalid_stateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = ["bottom", "center"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_filterMenuPosition(CHANGES) {
  if (!("filterMenuPosition" in CHANGES)) return this.logger("error", msgs.no_stateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.filterMenuPosition))
    return this.logger("error", msgs.invalid_stateValue(CHANGES.filterMenuPosition), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { filterMenuPosition } = CHANGES;
  const { filterMenuWrapper } = this.ref;
  this.logger("state-change", msgs.stateChange(filterMenuPosition), "default", logArgs);

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // [action]...

  ////// filter menu wrapper position updt. //////
  ////////////////////////////////////////////////

  filterMenuWrapper.setAttribute("data-position", filterMenuPosition);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
