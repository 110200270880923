// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_commentSubmitBtn_click", inline: true, disabled: false };
const msgs = {
  start: ["comment submit btn. click"],
  no_anchorEl: ["comment submit btn. click", "no post-card el. found to which comment widget is anchored"],
  no_postId: ["comment submit btn. click", "no post id found"],
  no_commentText: ["comment submit btn. click", "no comment text found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_commentSubmitBtn_click(COMMENTWIDGETEL, EVENTDATA) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////// Comment submit via API ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Find comment widget anchor element (post-card element)...
  const { cards } = this.ref;
  const commendWidgetAnchorEl = cards.find((card) => card.getAttribute("data-is-comment-widget-anchor") === "true");
  if (!commendWidgetAnchorEl) return this.logger("warning", msgs.no_anchorEl, "warning", logArgs);

  // Get post id...
  const postId = commendWidgetAnchorEl.dataset.id;
  if (!valid_str(postId)) return this.logger("warning", msgs.no_postId, "warning", logArgs);

  // Get comment text...
  const commentInput = COMMENTWIDGETEL.querySelector("[data-ref='commentInput']");
  const commentText = commentInput?.value;
  if (!valid_str(commentText)) return this.logger("warning", msgs.no_commentText, "warning", logArgs);

  // Submit comment...
  const submitReq = await this.api.submit_comment({ postId, commentText });

  // - Clear comment input...
  // - Clear new comment text state...
  // - Show submission notification (for 2s)...
  if (submitReq === "comment submitted") {
    const submitNotif = COMMENTWIDGETEL.querySelector("[data-ref='commentSubmitNotif']");
    commentInput.value = "";
    submitNotif.setAttribute("data-is-hidden", false);
    setTimeout(() => submitNotif.setAttribute("data-is-hidden", true), 2000);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
