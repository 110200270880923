// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_filterBtn_click", inline: true };
const msgs = {
  start: ["filter btn. was clicked"],
  no_filterID: ["filter btn. click", "no filter ID found on button el."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_filterBtn_click(BUTTONEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { activeCardFilters: activeFilters } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  ////////////////////////////////// Active card filters updt. ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Get filter ID...
  const filterID = BUTTONEL.dataset.filterId;
  if (!filterID) return this.logger("warning", msgs.no_filterID, "warning", logArgs);

  // If filter is already active, remove it...
  if (activeFilters.includes(filterID)) {
    const activeFilters_new = activeFilters.length === 1 ? ["all"] : activeFilters.filter((ID) => ID !== filterID);
    this.setState({ activeCardFilters: activeFilters_new });
  }

  // Otherwise, add filter to active filters...
  else {
    const activeFilters_new = [...activeFilters, filterID];
    this.setState({ activeCardFilters: activeFilters_new });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
