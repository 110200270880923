// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { Dropzone } from "dropzone";
// import { getIntersection } from "mezr/getIntersection"; // DISABLED
import { gsap } from "gsap";
// import { Draggable } from "gsap/Draggable"; // DISABLED
// gsap.registerPlugin(Draggable); // DISABLED

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function adjust_inputWidthToContent(INPUTEL) {
  // Create temp. span el...
  const tempSpan = document.createElement("span");

  // Copy relevant styles from input to span...
  tempSpan.style.fontSize = getComputedStyle(INPUTEL).fontSize;
  tempSpan.style.fontFamily = getComputedStyle(INPUTEL).fontFamily;
  tempSpan.style.fontWeight = getComputedStyle(INPUTEL).fontWeight;
  tempSpan.style.letterSpacing = getComputedStyle(INPUTEL).letterSpacing;

  // Make sure span is not visible in doc...
  tempSpan.style.position = "absolute";
  tempSpan.style.visibility = "hidden";
  tempSpan.style.whiteSpace = "pre"; // ← preserve spaces + tabs

  // Set span's text content to input's value...
  tempSpan.textContent = INPUTEL.value || INPUTEL.placeholder || " ";

  // Append span to doc. body...
  document.body.appendChild(tempSpan);

  // Measure width of span, then remove it...
  const width = tempSpan.offsetWidth;
  document.body.removeChild(tempSpan);

  // Set input element's width based on span's width...
  INPUTEL.style.width = `${width + 20}px`; // ← +20px for padding
}

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_matrixAnswerSubmitBtn_click from "../eventHandlers/eh_matrixAnswerSubmitBtn_click.js";
import eh_xyValueSubmitBtn_click from "../eventHandlers/eh_xyValueSubmitBtn_click.js";
import eh_matrixMultipleChoiceOpt_change from "../eventHandlers/eh_matrixMultipleChoiceOpt_change.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_matrixQuestions", inline: true };
const msgs = {
  initStart: ["init. matrixQuestions"],
  initSuccess: ["init. matrixQuestions", "init. success"],
  noValidDOMEl: ["init. matrixQuestions", "no valid DOM element(s) provided"],
};
// const dragConfig = { type: "x,y", edgeResistance: 0.65, inertia: true }; // DISABLED

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_matrixQuestions() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { matrixQuestions, matrixXYvalueSubmitBtn } = this.ref;
  this.logger("init", msgs.initStart, "action", logArgs);

  // Guard...
  if (matrixQuestions === undefined) return this.cancel_featInit(msgs.noValidDOMEl);
  if (matrixQuestions.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.noValidDOMEl);

  /////////////////////////////////////// Questions init. ////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  matrixQuestions.forEach((questionEl) => {
    //////////////////////////////// Setup /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const { answerMethod } = questionEl.dataset;
    const submitBtn = questionEl.querySelector('[data-ref="answerSubmitBtn"]');
    let dropzoneInstance = null;

    //////////////////////////// Dropzone init. ////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    if (answerMethod == "imgUpload") {
      const imgUploadDropzone = questionEl.querySelector('[data-ref="imgUploadDropzone"]');
      if (imgUploadDropzone) {
        dropzoneInstance = new Dropzone(imgUploadDropzone, {
          url: "/api.parti-form.submit.matrix-answer",
          maxFiles: 1,
          autoProcessQueue: false,
          acceptedFiles: "image/jpeg, image/jpeg, image/png",
          thumbnailWidth: 500,
          thumbnailHeight: 500,
          thumbnailMethod: "contain",
        });

        // Event hdl.: file added //
        let currentFile = null;
        dropzoneInstance.on("addedfile", (file) => {
          // Reveal submit btn...
          if (submitBtn) submitBtn.setAttribute("data-is-hidden", "false");

          // Remove previous file...
          if (currentFile) dropzoneInstance.removeFile(currentFile);
          currentFile = file;
        });
      }
    }

    //////////////////////// Multiple-choice init. /////////////////////////
    ////////////////////////////////////////////////////////////////////////

    if (answerMethod == "multipleChoice") {
      const optionsWrapper = questionEl.querySelector('[data-ref="multipleChoiceWrapper"]');
      const wrapperRect = optionsWrapper ? optionsWrapper.getBoundingClientRect() : null;
      const wrapperWidth = wrapperRect ? wrapperRect.width : null;
      const multipleChoiceOpts = questionEl.querySelectorAll('[data-ref="multipleChoiceOpt"]');
      const customMultipleChoiceOptEl = questionEl.querySelector('[data-ref="customMultipleChoiceOpt"]');

      ///////////////// Option init. /////////////////
      ////////////////////////////////////////////////

      if (multipleChoiceOpts) {
        multipleChoiceOpts.forEach((optEl) => {
          // Checkbox change hdl. //
          optEl.addEventListener(
            "change",
            eh_matrixMultipleChoiceOpt_change.bind(
              this,
              questionEl,
              submitBtn // ← submit btn. el. is passed so its position can be updated...
            )
          );

          // Checkbox random position init. //
          // Randomize horizontal position of checkboxes...
          const optWrapperEl = optEl.closest('[data-ref="checkboxWrapper"]');
          const optWrapperRect = optWrapperEl.getBoundingClientRect();
          const optWrapperWidth = optWrapperRect.width;
          const optX = Math.random() * (wrapperWidth - optWrapperWidth);
          optWrapperEl.style.transform = `translateX(${optX}px)`;
        });
      }

      ///////////// Custom option init. //////////////
      ////////////////////////////////////////////////

      if (customMultipleChoiceOptEl) {
        // Opt. click event hdl. //
        customMultipleChoiceOptEl.addEventListener("click", () => {
          // - Deselect other options in group...
          const multipleChoiceOpts = questionEl.querySelectorAll('[data-ref="multipleChoiceOpt"]');
          multipleChoiceOpts.forEach((opt) => {
            const optWrapper = opt.closest('[data-ref="checkboxWrapper"]');
            opt.checked = false;
            optWrapper.setAttribute("data-is-checked", "false");
          });

          // Move submit btn. below custom option...
          if (submitBtn) {
            const customOptWrapperRect = customMultipleChoiceOptEl.getBoundingClientRect();
            const customOptWrapperBottom = customOptWrapperRect.bottom;
            const btnRect = submitBtn.getBoundingClientRect();

            // Measure dist. between selected option and submit btn...
            const dist_optToBtn_vertical = customOptWrapperBottom - btnRect.top;
            const dist_optToBtn_horizontal = customOptWrapperRect.right - btnRect.left - btnRect.width;

            // Move btn...
            gsap.set(submitBtn, {
              x: `+=${dist_optToBtn_horizontal}`,
              y: `+=${dist_optToBtn_vertical}`,
              onComplete: () => submitBtn.setAttribute("data-is-hidden", "false"),
            });
          }
        });

        // Input event hdl. //
        const inputEl = customMultipleChoiceOptEl.querySelector("input");
        inputEl.addEventListener("input", () => {
          adjust_inputWidthToContent(inputEl);

          // Update submit btn. position...
          if (submitBtn) {
            const customOptWrapperRect = customMultipleChoiceOptEl.getBoundingClientRect();
            const customOptWrapperBottom = customOptWrapperRect.bottom;
            const btnRect = submitBtn.getBoundingClientRect();

            // Measure dist. between selected option and submit btn...
            const dist_optToBtn_vertical = customOptWrapperBottom - btnRect.top;
            const dist_optToBtn_horizontal = customOptWrapperRect.right - btnRect.left - btnRect.width;

            // Move btn...
            gsap.set(submitBtn, {
              x: `+=${dist_optToBtn_horizontal}`,
              y: `+=${dist_optToBtn_vertical}`,
            });
          }
        });

        // Random horizontal position init. //
        const minDist_fromWrapperEdge = wrapperWidth * 0.1;
        const optWidth = customMultipleChoiceOptEl.getBoundingClientRect().width;
        const optX = minDist_fromWrapperEdge + Math.random() * (wrapperWidth - optWidth - minDist_fromWrapperEdge * 2);
        customMultipleChoiceOptEl.style.transform = `translateX(${optX}px)`;
      }
    }

    ////////////////////////// Submit btn. init. ///////////////////////////
    ////////////////////////////////////////////////////////////////////////

    if (submitBtn) {
      // Event hdl. //
      submitBtn.addEventListener("click", eh_matrixAnswerSubmitBtn_click.bind(this, questionEl, dropzoneInstance));
    }
  });

  ////////////////////// x/y value submission init. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (matrixXYvalueSubmitBtn) {
    ////////////////// Event hdl. //////////////////
    ////////////////////////////////////////////////

    matrixXYvalueSubmitBtn.addEventListener("click", eh_xyValueSubmitBtn_click.bind(this));
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
