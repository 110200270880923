// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function get_cardOpts(CARDEL) {
  const cardOptions = CARDEL.getAttribute("g-options");
  return JSON.parse(cardOptions);
}

function adjust_cardPos(CARDEL, POS) {
  if (POS == undefined) return;
  gsap.to(CARDEL, { x: `${POS.x}vw`, y: `${POS.y}vw`, duration: 0.5, ease: "power2.out" });
}

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_cards", inline: true };
const loggerMsgs = {
  initStart: ["init. cards"],
  initSuccess: ["init. cards", "init. success"],
  noValidDOMEl: ["init. cards", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_cards() {
  // Setup...
  const { cards } = this.ref;
  const is_mobile = window.innerWidth < 640;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (cards === undefined) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (cards.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  ////////////////
  // Card init. //
  ////////////////

  cards.forEach((cardEl) => {
    // Setup...
    const cardOptions = cardEl.getAttribute("g-options");
    const optionsObj = JSON.parse(cardOptions);
    const { initPos, is_banner, is_collapsible, posRelativeTo_vpBottom } = optionsObj ?? {
      initPos: { x: 0, y: 0 },
      is_banner: false,
      is_collapsible: false,
    };

    // Set init. card positions...
    cardEl.style.transform = `translate(${initPos.x}vw, ${initPos.y}vw)`;

    // Init. card banner (is applicable)...
    if (is_banner) this.init_marquee(cardEl, { speed: 20, delayBeforeStart: 500 });

    // Init. card collapsible (is applicable)...
    if (is_collapsible) this.init_cardCollapsible(cardEl);

    // Set init. mobile card positions...
    if (is_mobile) {
      cards.forEach((cardEl) => {
        // Get portrait ratio position opt. & adjust card position...
        const cardOpts = get_cardOpts(cardEl);
        const { initPos_portraitRatio } = cardOpts ?? { initPos_portraitRatio: { x: 0, y: 0 } };
        adjust_cardPos(cardEl, initPos_portraitRatio);
      });
    }

    // Set init. positions of cards to be positioned relative to viewport bottom...
    if (!is_mobile && posRelativeTo_vpBottom) {
      const cardRect = cardEl.getBoundingClientRect();
      const cardHeight = cardRect.height;
      const vpBottom = window.innerHeight;
      const cardPos = vpBottom - cardHeight;
      gsap.to(cardEl, { y: `${cardPos}px`, duration: 0.5, ease: "power2.out" });
    }
  });

  /////////////////////
  // Draggable init. //
  /////////////////////

  // Create Draggable instances for each card...
  gsap.registerPlugin(Draggable);
  const instConfig = { type: "x,y", edgeResistance: 0.65, inertia: true };
  const dragCards = cards
    .filter((cardEl) => {
      const cardOptions = cardEl.getAttribute("g-options");
      const optionsObj = JSON.parse(cardOptions);
      return optionsObj.disable_draggable == false || optionsObj.disable_draggable == undefined;
    })
    .map((cardEl) => ({
      id: cardEl.dataset.id,
      el: cardEl,
      dragInst: Draggable.create(cardEl, { ...instConfig }),
    }));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
  this.modules.cards.instances = dragCards;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
