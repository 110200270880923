// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { OverlayScrollbars } from "overlayscrollbars";
gsap.registerPlugin(Draggable);

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_widgetCloseBtn_click from "../eventHandlers/eh_widgetCloseBtn_click";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_activeTermDisplayWidget", inline: true };
const msgs = {
  start: ["init. active term display widget"],
  end: ["init. active term display widget", "init. success"],
};
const dragInstConfig = { type: "x,y", edgeResistance: 0.65, inertia: true };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_activeTermDisplayWidget() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { features } = this.state;
  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Find widget el...
  const widgetEl = document.querySelector("[data-ref=glossaryLinkWidget]");

  // Init close btn.
  const widgetCloseBtnEl = widgetEl.querySelector("[data-ref=closeBtn]");
  if (widgetCloseBtnEl) widgetCloseBtnEl.addEventListener("click", () => eh_widgetCloseBtn_click.call(this));

  // Init drag. inst...
  const dragInst = Draggable.create(widgetEl, dragInstConfig);

  // Disable drag if not mouse...
  if (!features?.mouse) dragInst[0].disable();

  // Init. overlay scrollbar inst...
  let osInstance = null;
  const scrollContentWrapper = widgetEl.querySelector("[data-ref='content']");
  if (scrollContentWrapper) osInstance = OverlayScrollbars(scrollContentWrapper, { scrollbars: { theme: "os-theme-ash" } });

  // Set init. widget state...
  this.setState({
    activeTermWidgetState: {
      el: widgetEl,
      term: "",
      text: "",
      dragInst,
      osInstance,
      is_hidden: true,
    },
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
