// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function get_cardRelatedModalEl(CARDEL) {
  if (!CARDEL) return console.warn("get_cardRelatedModalEl(): No valid card el. provided");

  // Find related modal el...
  const cardID = CARDEL.dataset.id;
  const relatedModalEl = document.querySelector(`[data-role="Modal"][data-parent-id="${cardID}"]`);
  return relatedModalEl;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
