// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_matrixAnswerViewer", inline: true, disabled: false };
const msgs = {
  initStart: ["init. matrixAnswerViewer_v2"],
  initSuccess: ["init. matrixAnswerViewer_v2", "init. success"],
  noValidDOMEl: ["init. matrixAnswerViewer_v2", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_matrixAnswerViewer_v2() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const {
    matrixAnswerViewer_v2: answerViewer,
    matrixAnswerViewerPrevBtn: prevBtn,
    matrixAnswerViewerNextBtn: nextBtn,
  } = this.ref;
  this.logger("init", msgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(answerViewer)) return this.cancel_featInit(msgs.noValidDOMEl);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const cardCounter = answerViewer.querySelector("[data-ref='cardCounter']");
  const answerCards = answerViewer.querySelectorAll("[data-ref='answerCard']");

  // Prev-btn. click hdl. //
  prevBtn.addEventListener("click", () => {
    const activeCard = [...answerCards].find((card) => card.dataset.isHidden === "false");

    // Hide currently active card...
    activeCard.setAttribute("data-is-hidden", true);

    // Show prev. card...
    const currCardIndex = [...answerCards].indexOf(activeCard);
    const maxIndex = answerCards.length - 1;
    const prevCardIndex = currCardIndex === 0 ? maxIndex : currCardIndex - 1;
    answerCards[prevCardIndex].setAttribute("data-is-hidden", false);

    // Updt. card counter...
    if (cardCounter) cardCounter.querySelector("[data-ref='curr']").textContent = prevCardIndex + 1;
  });

  // Next-btn. click hdl. //
  nextBtn.addEventListener("click", () => {
    const activeCard = [...answerCards].find((card) => card.dataset.isHidden === "false");

    // Hide currently active card...
    activeCard.setAttribute("data-is-hidden", true);

    // Show next card...
    const currCardIndex = [...answerCards].indexOf(activeCard);
    const maxIndex = answerCards.length - 1;
    const nextCardIndex = currCardIndex === maxIndex ? 0 : currCardIndex + 1;
    answerCards[nextCardIndex].setAttribute("data-is-hidden", false);

    // Updt. card counter...
    if (cardCounter) cardCounter.querySelector("[data-ref='curr']").textContent = nextCardIndex + 1;
  });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
