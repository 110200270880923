// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["prev-/next-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_nextBtn_click(BUTTONEL) {
  // Setup...
  const flickityInstance = this.modules.flickity.instance;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Change slide...
  const { direction } = BUTTONEL.dataset;
  if (direction === "prev") flickityInstance.previous(true);
  else if (direction === "next") flickityInstance.next(true);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
