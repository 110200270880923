// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeMonthDisplay_hidden", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`activeMonthDisplay_hidden: ${CHANGE}`],
  no_change: () => ["No change to state provided."],
  inval_stateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeMonthDisplay_hidden(CHANGES) {
  if (!("activeMonthDisplay_hidden" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.activeMonthDisplay_hidden))
    return this.logger("error", msgs.inval_stateValue(CHANGES.activeMonthDisplay_hidden), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { activeMonthDisplay_hidden } = CHANGES;
  this.logger("state-change", msgs.stateChange(activeMonthDisplay_hidden), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // [action]...

  //////////////////// Active month display el. updt. ////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { activeMonthDisplay } = this.ref;
  if (activeMonthDisplay) activeMonthDisplay.setAttribute("data-is-hidden", activeMonthDisplay_hidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
