// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_consentCheck from "./init/init_consentCheck.js";
import init_infoOverlay from "./init/init_infoOverlay.js";
import init_fullpage from "./init/init_fullpage.js";
import init_formHeader from "./init/init_formHeader.js";
import init_formBorder from "./init/init_formBorder.js";
import init_mouse from "./init/init_mouse.js";
import init_sectionGroups from "./init/init_sectionGroups.js";
import init_mobileSectionContent from "./init/init_mobileSectionContent.js";
import init_mobileNextSectBtn from "./init/init_mobileNextSectBtn.js";
import init_mobileInstructCard from "./init/init_mobileInstructCard.js";
import init_btnSVGborders from "./init/init_btnSVGborders.js";
import init_tooltips from "./init/init_tooltips.js";
import init_keynotes from "./init/init_keynotes.js";
import init_bgIllus from "./init/init_bgIllus.js";
import init_gameSelectBtns from "./init/init_gameSelectBtns.js";
import init_scrollToSubsectionBtns from "./init/init_scrollToSubsectionBtns.js";
import init_instructCards from "./init/init_instructCards.js";
import init_pills from "./init/init_pills.js";
import init_measurements from "./init/init_measurements.js";

import init_fabric from "./init/init_fabric.js";
import init_drawingToolbar from "./init/init_drawingToolbar.js";
import init_drawingViewer from "./init/init_drawingViewer.js";

import init_writingGame from "./init/init_writingGame.js";
import init_wordViewer from "./init/init_wordViewer.js";

import init_matrixQuestions from "./init/init_matrixQuestions.js";
import init_matrixAnswerViewer_v2 from "./init/init_matrixAnswerViewer_v2.js";
import init_matrixAnswerGraph from "./init/init_matrixAnswerGraph.js";

import init_scrollTrigger_introSection from "./init/init_scrollTrigger_introSection.js";
import init_scrollTrigger_consentCheck from "./init/init_scrollTrigger_consentCheck.js";
import init_scrollTrigger_keynotesSection from "./init/init_scrollTrigger_keynotesSection.js";
import init_scrollTrigger_gameSelectSection from "./init/init_scrollTrigger_gameSelectSection.js";
// import init_scrollTrigger_drawingSection from "./init/init_scrollTrigger_drawingSection.js"; // DEPRECATED
import init_scrollTrigger_futureMatrixSection from "./init/init_scrollTrigger_futureMatrixSection.js";

import init_submitDrawingBtn from "./init/init_submitDrawingBtn.js";
import init_enterMatrixAnswerViewerBtn from "./init/init_enterMatrixAnswerViewerBtn.js";
import init_enterMatrixAnswerGraphBtn from "./init/init_enterMatrixAnswerGraphBtn.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_enable from "./eventbusHandlers/ebh_swup_enable.js";
import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_formBorderState from "./stateChangeHandlers/stChH_formBorderState.js";
import stChH_formHeaderState from "./stateChangeHandlers/stChH_formHeaderState.js";
import stChH_mobileNextSectBtnState from "./stateChangeHandlers/stChH_mobileNextSectBtnState.js";
import stChH_mobileInstructCardState from "./stateChangeHandlers/stChH_mobileInstructCardState.js";
import stChH_currentStep from "./stateChangeHandlers/stChH_currentStep.js";
import stChH_currentSubsection from "./stateChangeHandlers/stChH_currentSubsection.js";
import stChH_currentWordCat from "./stateChangeHandlers/stChH_currentWordCat.js";
import stChH_sectionGroupStates from "./stateChangeHandlers/stChH_sectionGroupStates.js";
import stChH_selectedCanvasSect from "./stateChangeHandlers/stChH_selectedCanvasSect.js";
import stChH_selectedDrawTool from "./stateChangeHandlers/stChH_selectedDrawTool.js";
import stChH_instructCardStates from "./stateChangeHandlers/stChH_instructCardStates.js";
import stChH_bgIlluStates from "./stateChangeHandlers/stChH_bgIlluStates.js";
import stChH_lastScrollPosition from "./stateChangeHandlers/stChH_lastScrollPosition.js";
import stChH_tooltipStates from "./stateChangeHandlers/stChH_tooltipStates.js";
import stChH_selectedWords from "./stateChangeHandlers/stChH_selectedWords.js";
import stChH_infoOverlayState from "./stateChangeHandlers/stChH_infoOverlayState.js";
import stChH_userMatrixAnswer from "./stateChangeHandlers/stChH_userMatrixAnswer.js";
import stChH_userConsent from "./stateChangeHandlers/stChH_userConsent.js";

import processSteps_consentCheck from "./api/processSteps_consentCheck.js";
import processSteps_drawingGame from "./api/processSteps_drawingGame.js";
import processSteps_writingGame from "./api/processSteps_writingGame.js";
import processSteps_futureMatrix from "./api/processSteps_futureMatrix.js";
import processSteps_updtInstructCards_writingGame from "./api/processSteps_updtInstructCards_writingGame.js";
import processSteps_updtInstructCards_drawingGame from "./api/processSteps_updtInstructCards_drawingGame.js";

import refresh_canvasSize from "./api/refresh_canvasSize.js";

import show_sectionGroup from "./api/show_sectionGroup.js";
import show_subsection from "./api/show_subsection.js";
import show_bgIllu from "./api/show_bgIllu.js";
import show_drawingViewer from "./api/show_drawingViewer.js";
import show_wordViewer from "./api/show_wordViewer.js";
import show_pageHeader from "./api/show_pageHeader.js";
import show_matrixDatapointTooltip from "./api/show_matrixDatapointTooltip.js";

import hide_bgIllu from "./api/hide_bgIllu.js";
import hide_pageHeader from "./api/hide_pageHeader.js";

import scrollTo_sectionGroup from "./api/scrollTo_sectionGroup.js";
import scrollTo_subsection from "./api/scrollTo_subsection.js";

import blockScroll from "./api/blockScroll.js";
import blockFullpageScroll from "./api/blockFullpageScroll.js";
import blockSubsectionScroll from "./api/blockSubsectionScroll.js";
import releaseScroll from "./api/releaseScroll.js";
import releaseFullpageScroll from "./api/releaseFullpageScroll.js";
import releaseSubsectionScroll from "./api/releaseSubsectionScroll.js";

import submit_drawing from "./api/submit_drawing.js";
import submit_writingGame from "./api/submit_writingGame.js";
import submit_matrixAnswer from "./api/submit_matrixAnswer.js";
import submit_matrixAnswerXYvalues from "./api/submit_matrixAnswerXYvalues.js";

import set_pageBgColor from "./api/set_pageBgColor.js";
import set_pageHeaderColor from "./api/set_pageHeaderColor.js";

import updt_wordViewer_wordDisplayCard from "./api/updt_wordViewer_wordDisplayCard.js";
import updt_matrixAnswerGraph_userTooltip from "./api/updt_matrixAnswerGraph_userTooltip.js";
import updt_mobileInstructCard from "./api/updt_mobileInstructCard.js";

import fetch_matrixAnswerData from "./api/fetch_matrixAnswerData.js";
import fetch_drawingViewerImgs from "./api/fetch_drawingViewerImgs.js";
import fetch_wordViewerWords from "./api/fetch_wordViewerWords.js";
import fetch_currDrawingSubmitIndex from "./api/fetch_currDrawingSubmitIndex.js";
import fetch_currWritingSubmitIndex from "./api/fetch_currWritingSubmitIndex.js";

import download_wordViewerImg from "./api/download_wordViewerImg.js";
import download_drawingViewerImg from "./api/download_drawingViewerImg.js";

import add_matrixAnswerGraphDataPoint from "./api/add_matrixAnswerGraphDataPoint.js";

import insert_drawingIntoViewer from "./api/insert_drawingIntoViewer.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// Default log styles

const defaultLogStyles = {
  default: "#6682d6",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00c853",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class PartiForm extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      floatingFormHeader: null,
      formBorder: null,
      mobileNextSectBtn: null,
      mobileInstructCard: null,
      bgIllus: [],
      gameSectionIllus: [],
      sectionGroups: [],
      sectionsWrapper: null,
      sections: [],
      sectionFooter_drawing: null,
      sectionFooter_writing: null,
      sectionFooter_futureMatrix: null,
      sectionFooter_futureMatrixAnswerViewer: null,
      sectionFooter_futureMatrixAnswerGraph: null,
      scrollToSubsectionBtns: [],
      //////
      keynotes: [],
      keynotes_floating: [],
      keynoteExplainers: [],
      //////
      gameSelectBtnWrapper: null,
      gameSelectBtns: [],
      //////
      drawingToolbar: null,
      drawingCanvasWrapper: null,
      drawingCanvasInnerWrapper: null,
      drawingCanvas: null,
      drawingCursor: null,
      submitDrawingBtn: null,
      drawingSubmitBtnWrapper: null,
      enterDrawingViewerBtn: null,
      drawingViewer: null,
      drawingViewerNextBtn: null,
      drawingViewerDownloadBtn: null,
      //////
      pencilToolBtn: null,
      spraycanToolBtn: null,
      shadowBrushToolBtn: null,
      undoDrawBtn: null,
      clearDrawBtn: null,
      instructCards: [],
      instructCards_wordCat: [],
      backBtn: null,
      tooltip_drawingToolbar: null,
      pills: [],
      //////
      writingGameWordGroups: [],
      writingGameWordCards: [],
      writingGameCustomWordCards: [],
      writingGameSelectedWordsGroup: null,
      writingGameSelectedWordCards: [],
      submitWritingGameWordsBtn: null,
      enterWordViewerBtn: null,
      wordViewer: null,
      wordViewerNextBtn: null,
      wordViewerDownloadBtn: null,
      //////
      matrixQuestionsWrapper: null,
      matrixQuestions: [],
      matrixXYInputsWrapper: null,
      matrixXValueInput: null,
      matrixYValueInput: null,
      matrixXYvalueSubmitBtn: null,
      enterMatrixAnswerViewerBtn: null,
      matrixAnswerViewer: null,
      matrixAnswerViewer_v2: null,
      matrixAnswerViewerPrevBtn: null,
      matrixAnswerViewerNextBtn: null,
      enterMatrixAnswerGraphBtn: null,
      matrixAnswerGraph: null,
      matrixDatapointTooltip: null,
      matrixDatapointTooltip_userAnser: null,
      //////
      drawingSubmitNotif: null,
      writingSubmitNotif: null,
      matrixAnswerSubmitNotif: null,
      //////
      infoOverlay: null,
      openInfoOverlayBtn: null,
      //////
      consentQuest_researchUse: null,
      consentQuest_nameAgeQuery: null,
      confirmBtn_researchUseConsent: null,
      declineBtn_researchUseConsent: null,
      input_consentName: null,
      input_consentAge: null,
      consentNameAgeSubmitBtn: null,
      //////
      notif_noDrawingSubmissions: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "PartiForm",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
      //////
      breakpoints: {
        tablet_prt: 700,
        tablet_ls: 1000,
        desktop_sm: 1200,
      },
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_mobileInstructCard = init_mobileInstructCard.bind(this);
    this.init_mobileNextSectBtn = init_mobileNextSectBtn.bind(this);
    this.init_mobileSectionContent = init_mobileSectionContent.bind(this);
    this.init_measurements = init_measurements.bind(this);
    this.init_scrollTrigger_consentCheck = init_scrollTrigger_consentCheck.bind(this);
    this.init_consentCheck = init_consentCheck.bind(this);
    this.init_mouse = init_mouse.bind(this);
    this.init_scrollToSubsectionBtns = init_scrollToSubsectionBtns.bind(this);
    this.init_infoOverlay = init_infoOverlay.bind(this);
    this.init_wordViewer = init_wordViewer.bind(this);
    this.init_drawingViewer = init_drawingViewer.bind(this);
    this.init_writingGame = init_writingGame.bind(this);
    this.init_formBorder = init_formBorder.bind(this);
    this.init_formHeader = init_formHeader.bind(this);
    this.init_pills = init_pills.bind(this);
    this.init_matrixAnswerGraph = init_matrixAnswerGraph.bind(this);
    this.init_matrixAnswerViewer_v2 = init_matrixAnswerViewer_v2.bind(this);
    this.init_enterMatrixAnswerGraphBtn = init_enterMatrixAnswerGraphBtn.bind(this);
    this.init_enterMatrixAnswerViewerBtn = init_enterMatrixAnswerViewerBtn.bind(this);
    this.init_sectionGroups = init_sectionGroups.bind(this);
    this.init_fullpage = init_fullpage.bind(this);
    this.init_matrixQuestions = init_matrixQuestions.bind(this);
    this.init_btnSVGborders = init_btnSVGborders.bind(this);
    this.init_tooltips = init_tooltips.bind(this);
    this.init_submitDrawingBtn = init_submitDrawingBtn.bind(this);
    this.init_gameSelectBtns = init_gameSelectBtns.bind(this);
    this.init_bgIllus = init_bgIllus.bind(this);
    this.init_keynotes = init_keynotes.bind(this);
    this.init_drawingToolbar = init_drawingToolbar.bind(this);
    this.init_instructCards = init_instructCards.bind(this);
    this.init_fabric = init_fabric.bind(this);

    this.init_scrollTrigger_gameSelectSection = init_scrollTrigger_gameSelectSection.bind(this);
    this.init_scrollTrigger_keynotesSection = init_scrollTrigger_keynotesSection.bind(this);
    this.init_scrollTrigger_introSection = init_scrollTrigger_introSection.bind(this);
    // this.init_scrollTrigger_drawingSection = init_scrollTrigger_drawingSection.bind(this); // DEPRECATED
    this.init_scrollTrigger_futureMatrixSection = init_scrollTrigger_futureMatrixSection.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = {
      fullpage: { instance: null },
      fabric: { instances: [] },
      matrixAnswerViewer: { flickity: { instance: null } },
      matrixAnswerGraph: { chart: null },
    };

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      updt_mobileInstructCard: updt_mobileInstructCard.bind(this),
      insert_drawingIntoViewer: insert_drawingIntoViewer.bind(this),
      processSteps_consentCheck: processSteps_consentCheck.bind(this),
      updt_matrixAnswerGraph_userTooltip: updt_matrixAnswerGraph_userTooltip.bind(this),
      add_matrixAnswerGraphDataPoint: add_matrixAnswerGraphDataPoint.bind(this),
      hide_pageHeader: hide_pageHeader.bind(this),
      processSteps_updtInstructCards_drawingGame: processSteps_updtInstructCards_drawingGame.bind(this),
      download_drawingViewerImg: download_drawingViewerImg.bind(this),
      download_wordViewerImg: download_wordViewerImg.bind(this),
      updt_wordViewer_wordDisplayCard: updt_wordViewer_wordDisplayCard.bind(this),
      processSteps_futureMatrix: processSteps_futureMatrix.bind(this),
      processSteps_writingGame: processSteps_writingGame.bind(this),
      processSteps_drawingGame: processSteps_drawingGame.bind(this),
      processSteps_updtInstructCards_writingGame: processSteps_updtInstructCards_writingGame.bind(this),
      //////
      submit_matrixAnswerXYvalues: submit_matrixAnswerXYvalues.bind(this),
      show_subsection: show_subsection.bind(this),
      scrollTo_subsection: scrollTo_subsection.bind(this),
      show_wordViewer: show_wordViewer.bind(this),
      fetch_wordViewerWords: fetch_wordViewerWords.bind(this),
      show_drawingViewer: show_drawingViewer.bind(this),
      fetch_drawingViewerImgs: fetch_drawingViewerImgs.bind(this),
      submit_writingGame: submit_writingGame.bind(this),
      set_pageHeaderColor: set_pageHeaderColor.bind(this),
      fetch_matrixAnswerData: fetch_matrixAnswerData.bind(this),
      fetch_currWritingSubmitIndex: fetch_currWritingSubmitIndex.bind(this),
      fetch_currDrawingSubmitIndex: fetch_currDrawingSubmitIndex.bind(this),
      show_matrixDatapointTooltip: show_matrixDatapointTooltip.bind(this),
      show_pageHeader: show_pageHeader.bind(this),
      set_pageBgColor: set_pageBgColor.bind(this),
      //////
      blockScroll: blockScroll.bind(this),
      blockFullpageScroll: blockFullpageScroll.bind(this),
      blockSubsectionScroll: blockSubsectionScroll.bind(this),
      releaseScroll: releaseScroll.bind(this),
      releaseFullpageScroll: releaseFullpageScroll.bind(this),
      releaseSubsectionScroll: releaseSubsectionScroll.bind(this),
      //////
      scrollTo_sectionGroup: scrollTo_sectionGroup.bind(this),
      show_sectionGroup: show_sectionGroup.bind(this),
      submit_matrixAnswer: submit_matrixAnswer.bind(this),
      submit_drawing: submit_drawing.bind(this),
      show_bgIllu: show_bgIllu.bind(this),
      hide_bgIllu: hide_bgIllu.bind(this),
      refresh_canvasSize: refresh_canvasSize.bind(this),
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_swup_enable = ebh_swup_enable.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_mobileInstructCardState = stChH_mobileInstructCardState.bind(this);
    this.stChL_mobileNextSectBtnState = stChH_mobileNextSectBtnState.bind(this);
    this.stChL_userConsent = stChH_userConsent.bind(this);
    this.stChL_userMatrixAnswer = stChH_userMatrixAnswer.bind(this);
    this.stChL_infoOverlayState = stChH_infoOverlayState.bind(this);
    this.stChL_selectedWords = stChH_selectedWords.bind(this);
    this.stChL_currentWordCat = stChH_currentWordCat.bind(this);
    this.stChL_formBorderState = stChH_formBorderState.bind(this);
    this.stChL_formHeaderState = stChH_formHeaderState.bind(this);
    this.stChL_sectionGroupStates = stChH_sectionGroupStates.bind(this);
    this.stChL_tooltipStates = stChH_tooltipStates.bind(this);
    this.stChL_lastScrollPosition = stChH_lastScrollPosition.bind(this);
    this.stChL_bgIlluStates = stChH_bgIlluStates.bind(this);
    this.stChL_mode = stChH_mode.bind(this);
    this.stChL_currentStep = stChH_currentStep.bind(this);
    this.stChL_currentSubsection = stChH_currentSubsection.bind(this);
    this.stChL_selectedCanvasSect = stChH_selectedCanvasSect.bind(this);
    this.stChL_selectedDrawTool = stChH_selectedDrawTool.bind(this);
    this.stChL_instructCardStates = stChH_instructCardStates.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { eventName: "mount", inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { eventName: "unmount", inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("swup_enable", this.ebl_swup_enable);
    eventbus.off("window_resize", this.ebl_window_resize);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("PartiForm.api.updt_mobileInstructCard", this.api.updt_mobileInstructCard);
    eventbus.off("PartiForm.api.insert_drawingIntoViewer", this.api.insert_drawingIntoViewer);
    eventbus.off("PartiForm.api.processSteps_consentCheck", this.api.processSteps_consentCheck);
    eventbus.off("PartiForm.api.updt_matrixAnswerGraph_userTooltip", this.api.updt_matrixAnswerGraph_userTooltip);
    eventbus.off("PartiForm.api.add_matrixAnswerGraphDataPoint", this.api.add_matrixAnswerGraphDataPoint);
    eventbus.off("PartiForm.api.hide_pageHeader", this.api.hide_pageHeader);
    eventbus.off("PartiForm.api.processSteps_updtInstructCards_drawingGame", this.api.processSteps_updtInstructCards_drawingGame);
    eventbus.off("PartiForm.api.download_drawingViewerImg", this.api.download_drawingViewerImg);
    eventbus.off("PartiForm.api.download_wordViewerImg", this.api.download_wordViewerImg);
    eventbus.off("PartiForm.api.updt_wordViewer_wordDisplayCard", this.api.updt_wordViewer_wordDisplayCard);
    eventbus.off("PartiForm.api.processSteps_updtInstructCards_writingGame", this.api.processSteps_updtInstructCards_writingGame);
    eventbus.off("PartiForm.api.releaseSubsectionScroll", this.api.releaseSubsectionScroll);
    eventbus.off("PartiForm.api.blockSubsectionScroll", this.api.blockSubsectionScroll);
    eventbus.off("PartiForm.api.processSteps_futureMatrix", this.api.processSteps_futureMatrix);
    eventbus.off("PartiForm.api.processSteps_writingGame", this.api.processSteps_writingGame);
    eventbus.off("PartiForm.api.processSteps_drawingGame", this.api.processSteps_drawingGame);
    eventbus.off("PartiForm.api.submit_matrixAnswerXYvalues", this.api.submit_matrixAnswerXYvalues);
    eventbus.off("PartiForm.api.show_subsection", this.api.show_subsection);
    eventbus.off("PartiForm.api.scrollTo_subsection", this.api.scrollTo_subsection);
    eventbus.off("PartiForm.api.show_wordViewer", this.api.show_wordViewer);
    eventbus.off("PartiForm.api.fetch_wordViewerWords", this.api.fetch_wordViewerWords);
    eventbus.off("PartiForm.api.show_drawingViewer", this.api.show_drawingViewer);
    eventbus.off("PartiForm.api.fetch_drawingViewerImgs", this.api.fetch_drawingViewerImgs);
    eventbus.off("PartiForm.api.fetch_currWritingSubmitIndex", this.api.fetch_currWritingSubmitIndex);
    eventbus.off("PartiForm.api.fetch_currDrawingSubmitIndex", this.api.fetch_currDrawingSubmitIndex);
    eventbus.off("PartiForm.api.submit_writingGame", this.api.submit_writingGame);
    eventbus.off("PartiForm.api.set_pageHeaderColor", this.api.set_pageHeaderColor);
    eventbus.off("PartiForm.api.fetch_matrixAnswerData", this.api.fetch_matrixAnswerData);
    eventbus.off("PartiForm.api.show_matrixDatapointTooltip", this.api.show_matrixDatapointTooltip);
    eventbus.off("PartiForm.api.show_pageHeader", this.api.show_pageHeader);
    eventbus.off("PartiForm.api.set_pageBgColor", this.api.set_pageBgColor);
    eventbus.off("PartiForm.api.releaseFullpageScroll", this.api.releaseFullpageScroll);
    eventbus.off("PartiForm.api.blockFullpageScroll", this.api.blockFullpageScroll);
    eventbus.off("PartiForm.api.scrollTo_sectionGroup", this.api.scrollTo_sectionGroup);
    eventbus.off("PartiForm.api.show_sectionGroup", this.api.show_sectionGroup);
    eventbus.off("PartiForm.api.submit_matrixAnswer", this.api.submit_matrixAnswer);
    eventbus.off("PartiForm.api.submit_drawing", this.api.submit_drawing);
    eventbus.off("PartiForm.api.releaseScroll", this.api.releaseScroll);
    eventbus.off("PartiForm.api.blockScroll", this.api.blockScroll);
    eventbus.off("PartiForm.api.show_bgIllu", this.api.show_bgIllu);
    eventbus.off("PartiForm.api.hide_bgIllu", this.api.hide_bgIllu);
    eventbus.off("PartiForm.api.refresh_canvasSize", this.api.refresh_canvasSize);
    eventbus.off("PartiForm.api.hideComp", this.api.hideComp);
    eventbus.off("PartiForm.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    const { with_consentCheck, with_futureMatrix, with_futureMatrixGraph, with_writingGame, with_drawingGame } = this.options;
    this.setState({ mode: "init" });

    // Setup //
    this.init_states();
    this.init_fullpage();
    this.init_measurements();
    this.init_formHeader();
    this.init_formBorder();
    this.init_sectionGroups();
    this.init_mobileNextSectBtn();
    this.init_mobileSectionContent();
    this.init_mobileInstructCard();
    this.init_bgIllus();
    this.init_instructCards();
    this.init_tooltips();
    this.init_infoOverlay();
    this.init_scrollToSubsectionBtns();
    this.init_mouse();
    this.init_pills(this.ref.pills);

    // Intro //
    this.init_scrollTrigger_introSection();
    this.init_scrollTrigger_keynotesSection();
    this.init_keynotes();

    // Consent check //
    if (with_consentCheck) {
      this.init_consentCheck();
      this.init_scrollTrigger_consentCheck();
    }

    // Game selection //
    if (with_drawingGame || with_writingGame || with_futureMatrix) {
      if (!with_consentCheck) this.init_gameSelectBtns();
      //  ^^^^^^^^^^^^^^^^^^
      //  ↳ only init.game - select btns.if consent check if disabled,
      //    as btns.are not visible until consent check is completed
      this.init_scrollTrigger_gameSelectSection();
    }

    // Drawing game //
    if (with_drawingGame) {
      this.init_drawingToolbar();
      this.init_drawingViewer();
      this.init_submitDrawingBtn();
    }

    // Writing game //
    if (with_writingGame) {
      this.init_wordViewer();
    }

    // Future matrix //
    if (with_futureMatrix) {
      this.init_matrixQuestions();
      this.init_enterMatrixAnswerViewerBtn();
      this.init_matrixAnswerViewer_v2();
      if (with_futureMatrixGraph) {
        this.init_matrixAnswerGraph();
        this.init_enterMatrixAnswerGraphBtn();
      }
    }

    // Misc. //
    this.init_eventbus();

    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    const { breakpoints } = this.options;
    const w_viewport = window.innerWidth;
    this.setState({
      currentStep: "intro",
      currentSubsection: "intro",
      currentWordCat: "null",
      userConsent: { researchUseConsent: null, name: null, age: null },
      is_mobile: w_viewport < breakpoints.tablet_prt,
      is_tablet_prt: w_viewport >= breakpoints.tablet_prt && w_viewport < breakpoints.tablet_ls,
      is_tablet_ls: w_viewport >= breakpoints.tablet_ls && w_viewport < breakpoints.desktop_sm,
      is_desktop_sm: w_viewport >= breakpoints.desktop_sm,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { eventName: "init_eventbus", inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("swup_enable", this.ebl_swup_enable);
    eventbus.on("window_resize", this.ebl_window_resize);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("PartiForm.api.updt_mobileInstructCard", this.api.updt_mobileInstructCard);
    eventbus.on("PartiForm.api.insert_drawingIntoViewer", this.api.insert_drawingIntoViewer);
    eventbus.on("PartiForm.api.processSteps_consentCheck", this.api.processSteps_consentCheck);
    eventbus.on("PartiForm.api.updt_matrixAnswerGraph_userTooltip", this.api.updt_matrixAnswerGraph_userTooltip);
    eventbus.on("PartiForm.api.add_matrixAnswerGraphDataPoint", this.api.add_matrixAnswerGraphDataPoint);
    eventbus.on("PartiForm.api.hide_pageHeader", this.api.hide_pageHeader);
    eventbus.on("PartiForm.api.processSteps_updtInstructCards_drawingGame", this.api.processSteps_updtInstructCards_drawingGame);
    eventbus.on("PartiForm.api.download_drawingViewerImg", this.api.download_drawingViewerImg);
    eventbus.on("PartiForm.api.download_wordViewerImg", this.api.download_wordViewerImg);
    eventbus.on("PartiForm.api.updt_wordViewer_wordDisplayCard", this.api.updt_wordViewer_wordDisplayCard);
    eventbus.on("PartiForm.api.processSteps_updtInstructCards_writingGame", this.api.processSteps_updtInstructCards_writingGame);
    eventbus.on("PartiForm.api.releaseSubsectionScroll", this.api.releaseSubsectionScroll);
    eventbus.on("PartiForm.api.blockSubsectionScroll", this.api.blockSubsectionScroll);
    eventbus.on("PartiForm.api.processSteps_futureMatrix", this.api.processSteps_futureMatrix);
    eventbus.on("PartiForm.api.processSteps_writingGame", this.api.processSteps_writingGame);
    eventbus.on("PartiForm.api.processSteps_drawingGame", this.api.processSteps_drawingGame);
    eventbus.on("PartiForm.api.submit_matrixAnswerXYvalues", this.api.submit_matrixAnswerXYvalues);
    eventbus.on("PartiForm.api.show_subsection", this.api.show_subsection);
    eventbus.on("PartiForm.api.scrollTo_subsection", this.api.scrollTo_subsection);
    eventbus.on("PartiForm.api.show_wordViewer", this.api.show_wordViewer);
    eventbus.on("PartiForm.api.fetch_wordViewerWords", this.api.fetch_wordViewerWords);
    eventbus.on("PartiForm.api.show_drawingViewer", this.api.show_drawingViewer);
    eventbus.on("PartiForm.api.fetch_drawingViewerImgs", this.api.fetch_drawingViewerImgs);
    eventbus.on("PartiForm.api.fetch_currWritingSubmitIndex", this.api.fetch_currWritingSubmitIndex);
    eventbus.on("PartiForm.api.fetch_currDrawingSubmitIndex", this.api.fetch_currDrawingSubmitIndex);
    eventbus.on("PartiForm.api.submit_writingGame", this.api.submit_writingGame);
    eventbus.on("PartiForm.api.set_pageHeaderColor", this.api.set_pageHeaderColor);
    eventbus.on("PartiForm.api.fetch_matrixAnswerData", this.api.fetch_matrixAnswerData);
    eventbus.on("PartiForm.api.show_matrixDatapointTooltip", this.api.show_matrixDatapointTooltip);
    eventbus.on("PartiForm.api.show_pageHeader", this.api.show_pageHeader);
    eventbus.on("PartiForm.api.set_pageBgColor", this.api.set_pageBgColor);
    eventbus.on("PartiForm.api.releaseFullpageScroll", this.api.releaseFullpageScroll);
    eventbus.on("PartiForm.api.blockFullpageScroll", this.api.blockFullpageScroll);
    eventbus.on("PartiForm.api.scrollTo_sectionGroup", this.api.scrollTo_sectionGroup);
    eventbus.on("PartiForm.api.show_sectionGroup", this.api.show_sectionGroup);
    eventbus.on("PartiForm.api.submit_matrixAnswer", this.api.submit_matrixAnswer);
    eventbus.on("PartiForm.api.submit_drawing", this.api.submit_drawing);
    eventbus.on("PartiForm.api.releaseScroll", this.api.releaseScroll);
    eventbus.on("PartiForm.api.blockScroll", this.api.blockScroll);
    eventbus.on("PartiForm.api.show_bgIllu", this.api.show_bgIllu);
    eventbus.on("PartiForm.api.hide_bgIllu", this.api.hide_bgIllu);
    eventbus.on("PartiForm.api.refresh_canvasSize", this.api.refresh_canvasSize);
    eventbus.on("PartiForm.api.hideComp", this.api.hideComp);
    eventbus.on("PartiForm.api.showComp", this.api.showComp);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("userConsent" in CHANGES) this.stChL_userConsent(CHANGES);
    if ("userMatrixAnswer" in CHANGES) this.stChL_userMatrixAnswer(CHANGES);
    if ("infoOverlayState" in CHANGES) this.stChL_infoOverlayState(CHANGES);
    if ("selectedWords" in CHANGES) this.stChL_selectedWords(CHANGES);
    if ("formBorderState" in CHANGES) this.stChL_formBorderState(CHANGES);
    if ("formHeaderState" in CHANGES) this.stChL_formHeaderState(CHANGES);
    if ("mobileNextSectBtnState" in CHANGES) this.stChL_mobileNextSectBtnState(CHANGES);
    if ("mobileInstructCardState" in CHANGES) this.stChL_mobileInstructCardState(CHANGES);
    if ("sectionGroupStates" in CHANGES) this.stChL_sectionGroupStates(CHANGES);
    if ("tooltipStates" in CHANGES) this.stChL_tooltipStates(CHANGES);
    if ("lastScrollPosition" in CHANGES) this.stChL_lastScrollPosition(CHANGES);
    if ("bgIlluStates" in CHANGES) this.stChL_bgIlluStates(CHANGES);
    if ("selectedDrawTool" in CHANGES) this.stChL_selectedDrawTool(CHANGES);
    if ("currentStep" in CHANGES) this.stChL_currentStep(CHANGES);
    if ("currentSubsection" in CHANGES) this.stChL_currentSubsection(CHANGES);
    if ("currentWordCat" in CHANGES) this.stChL_currentWordCat(CHANGES);
    if ("instructCardStates" in CHANGES) this.stChL_instructCardStates(CHANGES);
    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default PartiForm;
