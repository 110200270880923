// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_surveyForm_textArea_input", inline: true, disabled: false };
const msgs = {
  start: ["an event occurred"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_surveyForm_textArea_input(EL_form) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Adjust text area height...
  const textArea = EL_form.querySelector("[data-ref='answerInput']");
  textArea.style.height = "auto";
  textArea.style.height = textArea.scrollHeight + "px";

  // Show/hide submit btn...
  const submitBtn = EL_form.querySelector("[data-ref='submitBtn']");
  const has_value = textArea.value.trim().length > 0;
  submitBtn.setAttribute("data-is-hidden", !has_value);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
