// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { OverlayScrollbars } from "overlayscrollbars";
import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_eventContentSlider", inline: true };
const msgs = {
  start: ["init. eventContentSlider"],
  end: ["init. eventContentSlider", "init. success"],
  no_el: ["init. eventContentSlider", "no valid DOM element(s) provided"],
};

const defaultFlktyConfig = {
  wraparound: true,
  cellalign: "center",
  draggable: true,
  fade: false,
  autoplay: false,
  selectedattraction: 0.025,
  friction: 0.28,
};
const defScrollbarConfig = { scrollbars: { theme: "os-theme-ash" }, overflow: { x: "hidden", y: "scroll" } };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_eventContentSlider(EVENTCARDEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(EVENTCARDEL)) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Create Flickity instance...
  const config = defaultFlktyConfig;
  const slidesWrapper = EVENTCARDEL.querySelector("[data-ref~='slidesWrapper']");
  if (!slidesWrapper) return this.cancel_featInit(msgs.no_el);
  const flickityInst = new Flickity(slidesWrapper, {
    prevNextButtons: false,
    pageDots: false,
    //////
    wrapAround: config.wraparound,
    cellAlign: config.cellalign,
    adaptiveHeight: true,
    autoPlay: config.autoplay,
    selectedAttraction: config.selectedattraction,
    draggable: false,
    fade: config.fade,
    friction: config.friction,
    imagesLoaded: true,
    //////
    // DEPRECATED //
    // on: {
    //   change: (INDEX) => {
    //     // Center event card vertically in vp...
    //     setTimeout(() => {
    //       this.api.unblockScroll();
    //       const vpHeight = window.innerHeight;
    //       const cardHeight = EVENTCARDEL.offsetHeight;
    //       const topOffset = (vpHeight - cardHeight) / 2;
    //       console.log("topOffset:", topOffset);
    //       eventbus.emit("WindowScroll.api.scroll_toTarget", { target: EVENTCARDEL, config: { topOffset } });
    //     }, 1000);
    //   },
    // },
    // DEPRECATED //
  });

  // Init. slider controls...
  const prevBtn = EVENTCARDEL.querySelector("[data-ref~='prevBtn']");
  const nextBtn = EVENTCARDEL.querySelector("[data-ref~='nextBtn']");
  if (prevBtn) prevBtn.addEventListener("click", () => flickityInst.previous());
  if (nextBtn) nextBtn.addEventListener("click", () => flickityInst.next());

  // Init. custom text slide scrollbars...
  const textSlides = EVENTCARDEL.querySelectorAll("[data-ref='slide'][data-type='text']");
  textSlides.forEach((slideEl) => OverlayScrollbars(slideEl, defScrollbarConfig));

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
