// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_windowScroll_stCh_scrollPosition", inline: true };
const loggerMsgs = {
  eventStart: ["IlluScroller", "eventbus", "windowScroll_stCh_scrollPosition"],
  noActiveSection: ["IlluScroller", "eventbus", "windowScroll_stCh_scrollPosition", "No active section found."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function ebh_windowScroll_stCh_scrollPosition(ARGS) {
  // Setup...
  const { _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event", { logArgs, inline: false });

  // Updt. active section...
  const activeSection = this.api.get_activeSection();
  if (!activeSection) return this.logger("warning", loggerMsgs.noActiveSection, "warning", logArgs);
  this.setState({
    activeSection: {
      id: activeSection.dataset.id,
      el: activeSection,
    },
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
