// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import TweenMax from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import calc_randomElPosition from "../../../../../app/baseUtilities/calc/calc_randomElPosition";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.set_widgetPosition", inline: true, disabled: false };
const msgs = {
  start: ["EventCalendar", "API", "set_widgetPosition"],
  end: ["EventCalendar", "API", "set_widgetPosition", "API call complete"],
  no_widgetEls: ["EventCalendar", "API", "set_widgetPosition", "no widget el. provided"],
  setting_mobilePos: ["EventCalendar", "API", "set_widgetPosition", "setting mobile widget position"],
};
const defConfig = { position: "left", maxCenterOverlap: 100, with_follower: false, followerEl: null };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_widgetPosition(EL_widget, CONFIG = defConfig) {
  if (!EL_widget) return this.logger("warning", msgs.no_widgetEls, "warning", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const config = { ...defConfig, ...CONFIG };
  const { position, maxCenterOverlap, with_follower, followerEl } = config;
  const { eventCardsWrapper } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  // Determ. allowed overlap based on viewport width...
  const { is_mobile, is_tablet_ls } = this.state;
  const allowedOverlap = is_mobile || is_tablet_ls ? 0 : maxCenterOverlap;

  ////////////////////////////////////// Random widget pos. //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Measure viewport dimensions...
  const { innerWidth: w_vp, innerHeight: h_vp } = window;

  // Measure eventCardsWrapper...
  const cardsWrapperRect = eventCardsWrapper.getBoundingClientRect();
  const { left: left_cardsWrapper, right: right_cardsWrapper } = cardsWrapperRect;

  // Measure widget...
  const widgetRect = EL_widget.getBoundingClientRect();
  const { width: w_widget, height: h_widget } = widgetRect;

  // On mobile, randomly position widget anywhere within viewport...
  if (is_mobile) {
    this.logger("info", msgs.setting_mobilePos, "info", logArgs);
    const widgetPos_new = calc_randomElPosition(EL_widget, { padding: 30 });
    TweenMax.set(EL_widget, { x: widgetPos_new.x, y: widgetPos_new.y });
  }

  // On other screens, randomly position widget within space left or right of eventCardsWrapper...
  else {
    const x_min = position === "left" ? 0 - allowedOverlap : right_cardsWrapper - allowedOverlap;
    const x_max = position === "left" ? left_cardsWrapper - w_widget + allowedOverlap : w_vp - w_widget - allowedOverlap;
    const y_min = 0;
    const y_max = h_vp - h_widget;
    const x_widgetPos = Math.random() * (x_max - x_min) + x_min;
    const y_widgetPos = Math.random() * (y_max - y_min) + y_min;
    TweenMax.set(EL_widget, { x: x_widgetPos, y: y_widgetPos });
  }

  // If followerEl provided, update its position...
  if (with_follower && followerEl) this.api.updt_widgetFollowerPos(EL_widget, followerEl, { instant: true });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
