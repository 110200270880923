// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_swup_linkClick(VISITDATA) {
  // Stop, if no swup visit data provided...
  if (!VISITDATA) return;

  // Updt. body data-overlay-open attr. based on data-swup-opens-overlay attr. on clicked link...
  const triggerEl = VISITDATA.trigger?.el;
  if (!triggerEl) return console.warn("eh_swup_linkClick() → No trigger el. found in VISITDATA:", VISITDATA);
  const linkOpensOverlay = triggerEl.dataset.swupOpensOverlay === "true";
  document.body.setAttribute("data-overlay-open", linkOpensOverlay);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
