// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.show_pageHeader", inline: true };
const loggerMsgs = {
  eventStart: ["PartiForm", "API", "show_pageHeader"],
  noPageHeaderEl: ["PartiForm", "API", "show_pageHeader", "no page header el. found"],
};

const defConfig = { duration: 0.7 };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_pageHeader(CONFIG = defConfig) {
  // Setup...
  const config = { ...defConfig, ...CONFIG };
  const { duration } = config;
  const pageHeaderEl = document.querySelector("[g-component='SubpageHeader']");
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!pageHeaderEl) return this.logger("error", loggerMsgs.noPageHeaderEl, "error", logArgs);

  // Reveal page header...
  gsap.to(pageHeaderEl, {
    y: 0,
    duration,
    onComplete: () => pageHeaderEl.setAttribute("data-hidden-offscreen", false),
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
