// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { autoUpdate } from "@floating-ui/dom";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_tooltipStates", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`tooltip states:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_tooltipStates(CHANGES) {
  if (!("tooltipStates" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { tooltipStates } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(tooltipStates), "default", { ...logArgs, inline: false });

  //////////////////////////////
  // Tooltip visibility updt. //
  //////////////////////////////

  tooltipStates?.forEach((state) => {
    const { el, autoUpdtr, is_hidden } = state;

    // Enable/Disable auto-update when tooltip is toggled...
    if (!is_hidden) autoUpdtr();
    else autoUpdtr()(); // ← cleanup

    // Updt. data-is-hidden attr...
    el.setAttribute("data-is-hidden", is_hidden);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
