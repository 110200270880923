// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_mobileNextSectBtnState", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`mobileNextSectBtnState:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_mobileNextSectBtnState(CHANGES) {
  if (!("mobileNextSectBtnState" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { mobileNextSectBtnState } = CHANGES;
  const { mobileNextSectBtn } = this.ref;
  this.logger("state-change", msgs.stateChange(mobileNextSectBtnState), "default", { ...logArgs, inline: true });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////// hidden attr. updt. //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_hidden" in mobileNextSectBtnState) {
    const { is_hidden } = mobileNextSectBtnState;
    mobileNextSectBtn.setAttribute("data-is-hidden", is_hidden);
  }

  ////////////////////////// Button text updt. ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("text" in mobileNextSectBtnState) {
    const { text } = mobileNextSectBtnState;

    // Find pill el. to contain btn. text...
    const btnPill = mobileNextSectBtn.querySelector("[g-ref='pills']");
    const pillTextEl = btnPill?.querySelector("[data-ref='textEl']");

    // Update text content...
    if (pillTextEl) pillTextEl.textContent = text;
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
