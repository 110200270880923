// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { OverlayScrollbars } from "overlayscrollbars";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_eventCardContentScroller", inline: true, disabled: false };
const msgs = {
  start: ["init. eventCardContentScroller"],
  end: ["init. eventCardContentScroller", "init. success"],
  no_el: ["init. eventCardContentScroller", "no valid DOM el. provided"],
  no_scrollContentWrapper: ["init. eventCardContentScroller", "no scrollContentWrapper el. found"],
};
const defScrollbarConfig = { scrollbars: { theme: "os-theme-ash" }, overflow: { x: "hidden", y: "scroll" } };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_eventCardContentScroller(EL_eventCard) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(EL_eventCard)) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Create Overlay Scrollbars instance...
  const scrollContentWrapper = EL_eventCard.querySelector("[data-ref~='scrollContentWrapper']");
  if (!scrollContentWrapper) return this.cancel_featInit(msgs.no_scrollContentWrapper);
  OverlayScrollbars(scrollContentWrapper, defScrollbarConfig);

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
