// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_eventCard_click", inline: true };
const msgs = {
  start: ["event card has been clicked"],
  someCardActive: ["EventCalendar", "eventCard click", "some event card is already active", "stopping event hdl."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_eventCard_click(CARDEL, EVENTDATA) {
  // Guard: Stop, if clicked occurred on element with g-ref="eventContentSlider" attr.
  // or any of its children, unless the target has data-ref="closeBtn"
  if (EVENTDATA.target.closest("[g-ref='eventContentSlider']") && !EVENTDATA.target.closest("[data-ref='closeBtn']")) return;

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { eventCardStates, is_mobile, is_tablet_prt } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard: Stop if some card is already active and clicked card is not the active card...
  const activeEventCard = this.api.get_activeEventCard();
  const clickedCardIsActive = activeEventCard && activeEventCard.id === CARDEL.dataset.id;
  if (activeEventCard && !clickedCardIsActive) return this.logger("warning", msgs.someCardActive, "warning", logArgs);

  /////////////////////////////////// Card is_collapsed toggle ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Toggle card’s is_collapsed state (eventCardStates updt.)...
  const eventCardStates_new = eventCardStates.map((state) => {
    if (state.id === CARDEL.dataset.id) state.is_collapsed = !state.is_collapsed; // ← open clicked card
    else state.is_collapsed = true; // ← collapse other cards
    return { ...state };
  });
  this.setState({ eventCardStates: eventCardStates_new });

  ////////////////////////////////// Scroll to active/open card //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // If card has been set active, scroll to card (eventbus call to Window Scroll module)...
  const relatedCardState = eventCardStates_new.find((state) => state.id === CARDEL.dataset.id);
  if (!relatedCardState.is_collapsed) {
    const vpHeight = window.innerHeight;
    const cardHeight = CARDEL.offsetHeight;
    const topOffset = (vpHeight - cardHeight) / 2; // ← center card vertically in vp.
    eventbus.emit("WindowScroll.api.scroll_toTarget", { target: CARDEL, config: { topOffset } });
  }

  ////////////////////////////////// Body-scroll block. //////////////////////////////////
  /////////////////////////// (mobile + portrait tablet only) ////////////////////////////

  // If on mobile || portrait tablet, block/unblock body scroll...
  if (is_mobile || is_tablet_prt) {
    const clickedCard_isBeingOpened = CARDEL.dataset.isCollapsed === "false";
    if (clickedCard_isBeingOpened) setTimeout(() => this.api.blockScroll(), 850);
    else this.api.unblockScroll();
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
