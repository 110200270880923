// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_windowScroll_stCh_scrollPosition from "./eventbusHandlers/ebh_windowScroll_stCh_scrollPosition.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_headlineStrech from "./stateChangeHandlers/stChH_headlineStrech.js";

import reset_headlineStretch from "./api/reset_headlineStretch.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// Default log styles

const defaultLogStyles = {
  default: "#6682d6",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00c853",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class SubpageHeader extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      headline: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "SubpageHeader",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
      //////
      run_withLogs: false,
      logStyles: defaultLogStyles,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    // ...

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      reset_headlineStretch: reset_headlineStretch.bind(this),
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_windowScroll_stCh_scrollPosition = ebh_windowScroll_stCh_scrollPosition.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_headlineStrech = stChH_headlineStrech.bind(this);
    this.stChL_mode = stChH_mode.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { eventName: "mount", inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { eventName: "unmount", inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("windowScroll_stCh_scrollPosition", this.ebl_windowScroll_stCh_scrollPosition);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("SubpageHeader.api.reset_headlineStretch", this.api.reset_headlineStretch);
    eventbus.off("SubpageHeader.api.hideComp", this.api.hideComp);
    eventbus.off("SubpageHeader.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.setState({ mode: "init" });
    this.init_states();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    this.setState({ is_mobile: window.innerWidth < 640 });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { eventName: "init_eventbus", inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("windowScroll_stCh_scrollPosition", this.ebl_windowScroll_stCh_scrollPosition);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("SubpageHeader.api.reset_headlineStretch", this.api.reset_headlineStretch);
    eventbus.on("SubpageHeader.api.hideComp", this.api.hideComp);
    eventbus.on("SubpageHeader.api.showComp", this.api.showComp);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("headlineStrech" in CHANGES) this.stChL_headlineStrech(CHANGES);
    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default SubpageHeader;
