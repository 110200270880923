// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_mobileSectionContent", inline: true, disabled: false };
const msgs = {
  start: ["init. mobileSectionContent"],
  end: ["init. mobileSectionContent", "init. success"],
  no_el: ["init. mobileSectionContent", "no valid DOM el. provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_mobileSectionContent() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { sections } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (sections === undefined) return this.cancel_featInit(msgs.no_el);
  if (sections.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  ///////////////////////////////////// Mobile content init. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Init. mobile content for each section...
  sections.forEach((sectionEl) => {
    const mobileSectionContentWrapper = sectionEl.querySelector("[data-ref='mobileSectionContentWrapper']");
    if (!mobileSectionContentWrapper) return;

    ///////////////////////// Content slider init. /////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const sectionContentSlider = mobileSectionContentWrapper.querySelector("[data-ref='mobileSectionContentSlider']");
    if (sectionContentSlider) {
      // Extract options from data-options attr. (JSON)...
      const options = JSON.parse(sectionContentSlider?.dataset?.options);
      const { show_nextSectBtnOnLastSlide = true } = options;

      // Create Flickity instance...
      const slidesWrapper = sectionContentSlider.querySelector("[data-ref~='slidesWrapper']");
      if (slidesWrapper) {
        const flickityInst = new Flickity(slidesWrapper, {
          prevNextButtons: false,
          pageDots: true,
          selectedAttraction: 0.025,
          friction: 0.28,
          draggable: true,
          imagesLoaded: true,
        });

        // Init. slide-change event hdl...
        if (show_nextSectBtnOnLastSlide) {
          flickityInst.on("change", (INDEX) => {
            // On last slide:
            // - show mobile next - section btn...
            // - hide slider page dots...
            const isOnLastSlide = INDEX === flickityInst.slides.length - 1;
            const pageDots = sectionContentSlider.querySelector(".flickity-page-dots");
            this.setState({ mobileNextSectBtnState: { ...this.state.mobileNextSectBtnState, is_hidden: !isOnLastSlide } });
            if (pageDots) pageDots.style.opacity = isOnLastSlide ? 0 : 1;
          });
        }
      }
    }
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
