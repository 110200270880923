// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_click from "../eventHandlers/eh_click.js";
import eh_mouseMove from "../eventHandlers/eh_mouseMove.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_mouseEvents", inline: true };
const loggerMsgs = {
  initStart: ["init. mouseEvents"],
  initSuccess: ["init. mouseEvents", "init. success"],
  noValidDOMEl: ["init. mouseEvents", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_mouseEvents() {
  // Setup...
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Event hdl.
  this.element.addEventListener("click", eh_click.bind(this));
  this.element.addEventListener("mousemove", eh_mouseMove.bind(this));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
