// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_swup_page_load", inline: true, disabled: false };
const msgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_swup_page_load(ARGS) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { to: navTarget, _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Determ. active link ID (target url w/o lang. prefix and leading slash)...
  const navTarget_url = navTarget?.url === "/de" || navTarget?.url === "/en" ? "home" : navTarget?.url;
  const activeLink = navTarget_url?.replace(/\/[a-z]{2}/, "").replace(/^\//, "");

  // - Close menu...
  // - Updt. active link...
  this.setState({ menuState: { is_open: false, activeLink } });

  // If not navigating to frontpage, hide logo img. in footer...
  const footerEl = document.querySelector("[data-ref='Nav:footer']");
  if (footerEl) {
    const navTo_frontpage = navTarget?.url === "/de" || navTarget?.url === "/en";
    const logoImg = footerEl.querySelector("[data-ref='logoImg']");
    if (logoImg) logoImg.setAttribute("data-is-hidden", !navTo_frontpage);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
