// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_listItem_click from "../eventHandlers/eh_listItem_click.js";
import eh_listItem_mouseEnter from "../eventHandlers/eh_listItem_mouseEnter.js";
import eh_listItem_mouseLeave from "../eventHandlers/eh_listItem_mouseLeave.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_listItems", inline: true };
const msgs = {
  start: ["init. listItems"],
  end: ["init. listItems", "init. success"],
  no_el: ["init. listItems", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_listItems() {
  // Setup...
  const { listItems } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (listItems === undefined) return this.cancel_featInit(msgs.no_el);
  if (listItems.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  // Event hdl...
  listItems
    .filter((el) => this.validate_refEl(el))
    .forEach((el) => {
      el.addEventListener("click", eh_listItem_click.bind(this, el));
      el.addEventListener("mouseenter", eh_listItem_mouseEnter.bind(this, el));
      el.addEventListener("mouseleave", eh_listItem_mouseLeave.bind(this, el));
    });

  // Conclude...
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
