// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import lozad from "lozad";
import eventbus from "../../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../baseUtilities/logger";
import set_state from "../../../baseUtilities/state/set_state";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_content_replace from "../eventbusHandlers/LazyLoader/ebh_swup_content_replace";
import ebh_Preloader_loadingComplete from "../eventbusHandlers/LazyLoader/ebh_Preloader_loadingComplete";

import reobserve from "./api/reobserve";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class LazyLoader {
  //////////////////////////////// Constructor /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  constructor(
    CONFIG = {
      logs: { run_withLogs: false, logStyles: {} },
      observer: { rootMargin: { top: 0, right: 0, bottom: 0, left: 0 }, threshold: 0 },
    },
    OPTIONS = {}
  ) {
    ///////////////////////// Options //////////////////////////
    ////////////////////////////////////////////////////////////

    this.options = {
      ...OPTIONS,
      name: "Lazy Loader",
      run_withLogs: CONFIG?.logs?.run_withLogs,
      run_withPreloader: CONFIG?.run_withPreloader,
      logStyles: CONFIG?.logs?.logStyles,
      observer: CONFIG?.observer,
    };

    ////////////////////////// Util. ///////////////////////////
    ////////////////////////////////////////////////////////////

    this.logger = logger.bind(this);

    /////////////////////////// API ////////////////////////////
    ////////////////////////////////////////////////////////////

    this.api = {
      loadImg: ({ imgEl }) => this.observer.triggerLoad(imgEl),
      reobserve: reobserve.bind(this),
    };

    //////////////////// Eventbus listeners ////////////////////
    ////////////////////////////////////////////////////////////

    this.ebl_swup_content_replace = ebh_swup_content_replace.bind(this);
    this.ebl_Preloader_loadingComplete = ebh_Preloader_loadingComplete.bind(this);

    ////////////////////////// State ///////////////////////////
    ////////////////////////////////////////////////////////////

    this._state = { initialised: false };
    this.setState = set_state.bind(this);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  get state() {
    return this._state;
  }

  set state(state) {
    this.logger("info", ["You should not change state manually. Use setState instead."], "warning");
    this._state = state;
  }

  ////////////////////////////////// Methods ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  init() {
    this.setState({ mode: "init" });
    this.logger("init", ["module"], "default", { inline: true });
    this.init_observer();
    this.init_eventbus();
    this.setState({ initialised: true }, false);
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  init_observer() {
    // Setup...
    const { run_withPreloader } = this.options;
    const observerConfig = this.options.observer;
    const { rootMargin = { top: 0, right: 0, bottom: 0, left: 0 }, threshold = 0 } = observerConfig ?? {};
    this.logger("init", ["init", "observer"], "default", { inline: true });

    // Create observer instance...
    const selector = run_withPreloader ? '[data-lazyload="true"]:not([data-preloaded="true"])' : "[data-lazyload='true']";
    const observer = lozad(selector, {
      rootMargin: `${rootMargin.top}px ${rootMargin.right}px ${rootMargin.bottom}px ${rootMargin.left}px`,
      threshold: threshold,
      loaded: (EL) => {
        this.logger("event", ["image loaded", EL], "event");

        // Set data-is-hidden (if necessary)...
        if (EL.dataset.isHidden) setTimeout(() => EL.setAttribute("data-is-hidden", "false"), 250);

        // Find & hide placeholder...
        const placeholder = EL.parentNode.querySelector("[data-placeholder]");
        if (placeholder) setTimeout(() => placeholder.classList.add("opacity-0"), 250);

        // Load any img. el. w/ same src. or data-poster src...
        const loadedSrc = EL.getAttribute("src");
        const relatedImgEls = document.querySelectorAll(`img[data-src="${loadedSrc}"]`);
        const relatedVidEls = document.querySelectorAll(`video[data-poster="${loadedSrc}"]`);
        relatedImgEls.forEach((el) => {
          if (el !== EL) observer.triggerLoad(el);
        });
        relatedVidEls.forEach((el) => el.setAttribute("poster", loadedSrc));
      },
    });

    // Init. (if not intgr. with preloader)...
    // (will be initialised by preloader ebc. if run_withPreloader is true)
    if (!run_withPreloader) observer.observe();

    // Store instance...
    this.observer = observer;
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  init_eventbus() {
    // Setup //
    this.logger("init", ["eventbus"], "default", { inline: true });

    // Handle ebc. emitted by swup events...
    eventbus.on("swup_content_replace", this.ebl_swup_content_replace);

    // Hdl. ebc. emitted by preloader...
    eventbus.on("Preloader_loadingComplete", this.ebl_Preloader_loadingComplete);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("LazyLoader.api.loadImg", this.api.loadImg);
    eventbus.on("LazyLoader.api.reobserve", this.api.reobserve);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("mode" in CHANGES) this.logger("state-change", [`mode: ${CHANGES.mode}`], "default", { inline: true });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default LazyLoader;
