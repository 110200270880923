// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
const logArgs = { eventName: "api.reobserve", inline: true, disabled: false };
const msgs = {
  start: ["LazyLoader", "API", "slide"],
  end: ["LazyLoader", "API", "slide", "API call complete"],
  no_observer: ["LazyLoader", "API", "slide", "no observer found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function reobserve() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const observer = this.observer;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!observer || observer === undefined) return this.logger("error", msgs.no_observer, "error", logArgs);

  /////////////////////////////// ACTION /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  observer.observe();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
