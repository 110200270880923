// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_is_mobile", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`is_mobile: ${CHANGE}`],
  no_change: () => ["No change to state provided."],
  inval_stateValue: (VALUE) => [`invalid state value: ${VALUE}`],
  no_flickityInst: ["stChH_is_mobile", "draggable feat. toggle", "no Flicktiy inst. available"],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_is_mobile(CHANGES) {
  if (!("is_mobile" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.is_mobile))
    return this.logger("error", msgs.inval_stateValue(CHANGES.is_mobile), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { is_mobile } = CHANGES;
  this.logger("state-change", msgs.stateChange(is_mobile), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // DEPRECATED //
  //////////////////////// Draggable feat. toggle ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // const flickityInst = this.modules.flickity.instance;
  // if (!flickityInst) return this.logger("warning", msgs.no_flickityInst, "warning", logArgs);
  // DEPRECATED //
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
