// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.save_positions", inline: true };
const loggerMsgs = {
  eventStart: ["CardArrangement", "API", "save_positions"],
  // noPosData: ["CardArrangement", "API", "save_positions", "no positions data provided"],
  noCardElss: ["CardArrangement", "API", "save_positions", "no card elements found"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function save_positions() {
  return new Promise((resolve, reject) => {
    // Setup...
    const { cards } = this.ref;
    this.logger("event", loggerMsgs.eventStart, "event", logArgs);

    // Guard: Stop, if no card elements found...
    if (!cards || cards.length === 0) reject(loggerMsgs.noCardElss);

    // Check if saving landscape or portrait positions...
    const positionTargetRadio = window.innerWidth > window.innerHeight ? "landscape" : "portrait";
    console.log(`%c🟠 Saving ${positionTargetRadio} positions...`, consoleStyles.default);

    // Get card dimensions + positions...
    const viewPortWidth = window.innerWidth;
    const cardPositions = cards.map((cardEl) => {
      const { id } = cardEl.dataset;
      const cardElRect = cardEl.getBoundingClientRect();
      const { top, left } = cardElRect;
      const cardX = parseFloat(((left / viewPortWidth) * 100).toFixed(3)); // ← conv. abs. px to rel. viewport units
      const cardY = parseFloat(((top / viewPortWidth) * 100).toFixed(3)); // ← conv. abs. px to rel. viewport units
      const cardH = parseFloat(((cardElRect.height / viewPortWidth) * 100).toFixed(2)); // ← conv. abs. px to rel. viewport units
      return { id, x: cardX, y: cardY, h: cardH };
    });

    // Set up backend request...
    const req = new XMLHttpRequest();
    const data = new FormData();
    data.append("positionTargetRadio", positionTargetRadio);
    data.append("positionsData", JSON.stringify(cardPositions));

    // Init. + send request...
    req.open("POST", "/api.card-arrangement.save-positions", true);
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) console.log("%c🟢 Position saving successful.", consoleStyles.success);
      if (req.status !== 200) {
        console.log("%c🔴 Position saving failed.", consoleStyles.error);
        reject("save_positions(): Position saving failed.");
      }
      resolve(req.responseText);
    };
    req.send(data);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
