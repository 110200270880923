// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function make_hideTimeout() {
  const hideTimeout = setTimeout(() => {
    this.setState({ notificationState: { is_hidden: true } });
    clearTimeout(hideTimeout);
  }, 3000);
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_validationSuccess", inline: true };
const loggerMsgs = {
  eventStart: ["contact form was validated", "form data is valid"],
  noJustValidInst: ["no justValidate instance found"],
  noMssgField: ["init. justValidate", "no message field found"],
};
const notifState_success = {
  mode: "success",
  text: { de: "Nachricht erfolgreich versandt!", en: "Message successfully sent!" },
  is_hidden: false,
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_validationSuccess() {
  // Setup...
  const validInst = this.modules.justValidate.instance;
  const { notificationState } = this.state;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);
  if (!validInst) return this.logger("event", loggerMsgs.noJustValidInst, "error", logArgs);

  /////////////////////////////////////
  // Form data submission to backend //
  /////////////////////////////////////

  // Extract message field data...
  const { messageField } = this.ref;
  if (!this.validate_refEl(messageField)) return this.cancel_featInit(loggerMsgs.noMssgField);
  const message = messageField ? messageField.value : null;

  // Submit form data via API...
  const submitData = { message };
  this.setState({ mode: "submitting" });
  await this.api.submit(submitData);

  // Clear pre-existing hide-timeout...
  if (notificationState.hideTimeout) clearTimeout(this.state.notificationState.hideTimeout);

  // Show notif. + reset form...
  const notifHideTimeout = make_hideTimeout.call(this); // ← hide notif. after 3s...
  this.setState({ notificationState: { ...notifState_success, hideTimeout: notifHideTimeout } });
  this.api.reset();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
