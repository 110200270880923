// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import check_siteLang from "../../../../../app/baseUtilities/check/check_siteLang";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_notificationState", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`notif. state:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
  noNotifEl: ["Notif. state change", "No notification el. found."],
};

// const possibleStateValues = ["foo", "bar"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_notificationState(CHANGES) {
  if (!("notificationState" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { notificationState } = CHANGES;
  const { notificationEl } = this.ref;
  const siteLang = check_siteLang() ?? "de";
  this.logger("state-change", loggerMsgs.stateChange(notificationState), "default", { logArgs, inline: false });
  if (!notificationEl) return this.logger("error", loggerMsgs.noNotifEl, "error", logArgs);

  // Execute nested text state change...
  if ("text" in notificationState) {
    const { text } = notificationState;
    const text_toUse = text[siteLang] ?? text.de;
    notificationEl.textContent = text_toUse;
  }

  // Execute nested mode state change...
  if ("mode" in notificationState) {
    const { mode } = notificationState;
    notificationEl.setAttribute("data-mode", mode);
  }

  // Execute nested is_hidden state change...
  if ("is_hidden" in notificationState) {
    const { is_hidden } = notificationState;
    notificationEl.setAttribute("data-is-hidden", is_hidden);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
