// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const msgs = {
  start: "BaseUtil → get → elementTransform",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function get_elementTransform(ELEMENT) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // console.log(msgs.start);

  /////////////////////////////// ACTION /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const style = window.getComputedStyle(ELEMENT);
  const transform = style.transform || style.webkitTransform || style.mozTransform;

  // Initial transform values...
  let tx = 0;
  let ty = 0;

  // 2D transform matrix...
  if (transform.startsWith("matrix(")) {
    const values = transform.split("(")[1].split(")")[0].split(",");
    tx = parseFloat(values[4]);
    ty = parseFloat(values[5]);
  }
  // 3D transform matrix...
  else if (transform.startsWith("matrix3d(")) {
    const values = transform.split("(")[1].split(")")[0].split(",");
    tx = parseFloat(values[12]);
    ty = parseFloat(values[13]);
  }

  return { tx, ty };
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
