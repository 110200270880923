// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_searchForm_submit", inline: true, disabled: false };
const msgs = {
  start: ["searchForm submit"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_searchForm_submit(SEARCHBAREL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Toggle search input visibility...
  const searchBtnText = SEARCHBAREL.querySelector("[data-ref='searchBtnText']");
  const searchInput = SEARCHBAREL.querySelector("[data-ref='searchInput']");
  const searchBtn = SEARCHBAREL.querySelector("[data-ref='searchBtn']");
  searchBtnText.setAttribute("data-is-hidden", "false");
  searchInput.setAttribute("data-is-hidden", "true");
  searchBtn.setAttribute("data-is-hidden", "true");

  // Clear search input...
  searchInput.value = "";
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
