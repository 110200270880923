// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function format_cardData(CARDELEMENTS) {
  if (!CARDELEMENTS) return console.warn("format_cardData(): no ELEMENTS provided");

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const cardData = [];

  ////////////////// Card data format. (from card els.) //////////////////
  ////////////////////////////////////////////////////////////////////////

  // Create card data from els...
  CARDELEMENTS.forEach((el) => {
    // Create card data instance...
    const cardDataInst = { el, config: {} };

    // Add el. options (g-options attr.) to card config...
    const options = el.getAttribute("g-options");
    if (options)
      try {
        const optionsObj = JSON.parse(options);
        cardDataInst.config = cardDataInst.config ? { ...cardDataInst.config, ...optionsObj } : optionsObj;
      } catch (error) {
        console.warn("format_cardData(): failed to parse g-options attribute", error);
      }

    cardData.push(cardDataInst);
  });

  return cardData;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
