// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.bringWidgetToFront", inline: true, disabled: false };
const msgs = {
  start: ["EventCalendar", "API", "bringWidgetToFront"],
  end: ["EventCalendar", "API", "bringWidgetToFront", "API call complete"],
  no_widgetEl: ["EventCalendar", "API", "bringWidgetToFront", "No widget el. provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function bringWidgetToFront(EL_widget) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { widgetStates } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!EL_widget) return this.logger("warning", msgs.no_widgetEl, "warning", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Extract widget states...
  const dateWidgetState = widgetStates.dateWidget;
  const marqueeWidgetState = widgetStates.marqueeWidget;
  const descriptWidgetState = widgetStates.descriptionWidget;
  const imgWidgetState = widgetStates.imgWidget;
  const slideShowWidgetState = widgetStates.slideShowWidget;
  const locationWidgetState = widgetStates.locationWidget;
  const ticketLinkWidgetState = widgetStates.ticketLinkWidget;

  // Extract widget els...
  const el_dateWidget = dateWidgetState?.el;
  const el_marqueeWidget = marqueeWidgetState?.el;
  const el_descriptWidget = descriptWidgetState?.el;
  const el_imgWidget = imgWidgetState?.el;
  const el_slideShowWidget = slideShowWidgetState?.el;
  const el_locationWidget = locationWidgetState?.el;
  const el_ticketLinkWidget = ticketLinkWidgetState?.el;

  // Find widget with highest z-index...
  const widgetEls = [
    el_dateWidget,
    el_marqueeWidget,
    el_descriptWidget,
    el_imgWidget,
    el_slideShowWidget,
    el_locationWidget,
    el_ticketLinkWidget,
  ];
  const widgetZIndexes = widgetEls
    .filter((el) => el != null && el != undefined)
    .map((el) => {
      const zIndex = window.getComputedStyle(el).getPropertyValue("z-index");
      return parseInt(zIndex);
    });
  const maxZIndex = Math.max(...widgetZIndexes);

  // Bring widget to front...
  EL_widget.style.zIndex = maxZIndex + 1;

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
