// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_savePositionsBtn_click from "../eventHandlers/eh_savePositionsBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_savePositionsBtn", inline: true };
const loggerMsgs = {
  initStart: ["init. savePositionsBtn"],
  initSuccess: ["init. savePositionsBtn", "init. success"],
  noValidDOMEl: ["init. savePositionsBtn", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_savePositionsBtn() {
  // Setup...
  const { savePositionsBtn } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(savePositionsBtn)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Event hdl...
  savePositionsBtn.addEventListener("click", eh_savePositionsBtn_click.bind(this));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
