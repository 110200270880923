// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_stateChange", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`active term display widget state`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeTermWidgetState(CHANGES) {
  if (!("activeTermWidgetState" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { activeTermWidgetState } = CHANGES;
  const linkEl = this.state.activeTermWidgetState.linkEl;
  const widgetEl = this.state.activeTermWidgetState.el;
  this.logger("state-change", loggerMsgs.stateChange(activeTermWidgetState), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { is_hidden, term, text, context } = activeTermWidgetState;

  /////////////////////////// is_hidden updt. ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_hidden" in activeTermWidgetState) {
    widgetEl.setAttribute("data-is-hidden", is_hidden);
    if (linkEl) linkEl.setAttribute("data-is-active", !is_hidden);
  }

  //////////////////////////// Context updt. /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("context" in activeTermWidgetState) widgetEl.setAttribute("data-context", context);

  ///////////////////////////// Colors updt. /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("colors" in activeTermWidgetState) {
    const { bg, text } = activeTermWidgetState.colors;
    widgetEl.style.setProperty("--color-bg", bg);
    widgetEl.style.setProperty("--color-text", text);
    widgetEl.style.setProperty("--color-scrollbar", text);
  }

  ///////////////////////////// Term updt. ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("term" in activeTermWidgetState) {
    const activeTermEl = widgetEl.querySelector("[data-ref=activeTermEl]");
    if (activeTermEl) activeTermEl.innerHTML = term;
  }

  ///////////////////////////// Text updt. ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("text" in activeTermWidgetState) {
    const activeTermTextEl = widgetEl.querySelector("[data-ref=activeTermTextEl]");
    if (activeTermTextEl) activeTermTextEl.innerHTML = text;
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
