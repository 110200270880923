// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_submitWritingGameWordsBtn_click", inline: true };
const msgs = {
  start: ["submit-writingGame-words btn. was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_submitWritingGameWordsBtn_click() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { selectedWords } = this.state;
  const { config_writingGame } = this.options;
  const { textpartsperuser: textPartsPerUser } = config_writingGame;
  const submitIndex_curr = await api.fetch_currWritingSubmitIndex();
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - Submit writing game words via comp. API...
  // - If text-parts-per-user is set to 'one', add submitted word/text to word viewer
  //   (at pos. corresp.to submitIndex)...
  await api.submit_writingGame();
  if (textPartsPerUser == "one") {
    const submittedWord = selectedWords[submitIndex_curr];
    api.updt_wordViewer_wordDisplayCard({ word: submittedWord.word, wordCatID: submittedWord.catId });
  }
  this.setState({ currentStep: "writingGameSubmitted" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
