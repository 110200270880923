// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeCardFilters", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`active card filters:`, CHANGE],
  no_stateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeCardFilters(CHANGES) {
  if (!("activeCardFilters" in CHANGES)) return this.logger("error", msgs.no_stateChange(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { activeCardFilters } = CHANGES;
  const { filterBtns } = this.ref;
  this.logger("state-change", msgs.stateChange(activeCardFilters), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Filter cards...
  this.api.filter_cards(activeCardFilters);

  // Toggle filter btn active styles...
  filterBtns.forEach((btn) => {
    const is_active = activeCardFilters.includes(btn.dataset.filterId);
    btn.setAttribute("data-is-active", is_active);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
