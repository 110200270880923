// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default {
  /////////////////////////// Route selection ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  from: /\/([a-zA-Z0-9-]+)(\/([a-zA-Z0-9-]+))?/, // "/:lang" or "/:lang/:page"
  to: /\/([a-zA-Z0-9-]+)(\/([a-zA-Z0-9-]+))?/, // "/:lang" or "/:lang/:page"

  ///////////////////////////////// Out //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  out: async () => {
    // Fade out...
    await gsap.to("#swup", { opacity: 0, duration: 0.25 });
  },

  ///////////////////////////////// In ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  in: async () => {
    // Fade in...
    await gsap.fromTo("#swup", { opacity: 0 }, { opacity: 1, duration: 0.25 });
  },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
