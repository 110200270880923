// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const msgs = {
  start: "EventCalendar → create → create_slideShowSlide()",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function create_slideShowSlide(SLIDEINDEX, IMGSRC) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  console.log(msgs.start);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const slide = document.createElement("div");
  slide.setAttribute("g-ref", "slides");
  slide.setAttribute("data-id", SLIDEINDEX);
  slide.className = `tablet-ls:w-[--w-tablet]      tablet-ls:h-[--h-tablet] 
                     desktop-small:w-[--w-desk-sm] desktop-small:h-[--h-desk-sm]
                     desktop-med:w-[--w-desk-md]   desktop-med:h-[--h-desk-md]
                     desktop-large:w-[--w-desk-lg] desktop-large:h-[--h-desk-lg]`;
  const img = document.createElement("img");
  img.className = `absolute-t-l size-full object-cover object-center`;
  img.src = IMGSRC;
  img.alt = "slide";
  slide.appendChild(img);

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  return slide;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
