// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.get_activeSection", inline: true };
const loggerMsgs = {
  eventStart: ["IlluScroller", "API", "get_activeSection"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function get_activeSection() {
  // Setup...
  const { sections } = this.ref;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Find section with the largest visible area (scrolled furthest into view)...
  let maxVisibleArea = 0;
  let activeSection = null;
  sections.forEach((sectionEl) => {
    const rect = sectionEl.getBoundingClientRect();
    const vpHeight = window.innerHeight || document.documentElement.clientHeight;
    const visibleHeight = Math.min(rect.bottom, vpHeight) - Math.max(rect.top, 0);
    const visibleArea = visibleHeight * (rect.right - rect.left);
    if (visibleArea * 0.85 > maxVisibleArea) {
      maxVisibleArea = visibleArea;
      activeSection = sectionEl;
    }
  });

  return activeSection;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
