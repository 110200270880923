// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function get_subpageHeaderHeight() {
  const subpageHeader = document.querySelector("[g-component='SubpageHeader']");
  return subpageHeader ? subpageHeader.offsetHeight : 0;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_eventListItem_click", inline: true };
const loggerMsgs = {
  eventStart: ["an event occurred"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_eventListItem_click(ITEMEL, EVENTDATA) {
  // Setup...
  const { eventCardStates } = this.state;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Toggle corresponding card’s is_collapsed state (eventCardStates updt.)...
  const itemID = ITEMEL.dataset.id;
  const eventCardStates_new = eventCardStates.map((state) => {
    if (state.id === itemID) return { ...state, is_collapsed: !state.is_collapsed };
    return { ...state, is_collapsed: true }; // ← collapse all other cards
  });
  this.setState({ eventCardStates: eventCardStates_new });

  // If item has been set active, scroll to corresponding card (eventbus call to Window Scroll module)...
  const relatedCardState = eventCardStates_new.find((state) => state.id === itemID);
  if (relatedCardState.is_collapsed) return;
  const cardEl = relatedCardState.el;
  const topOffset = get_subpageHeaderHeight();
  eventbus.emit("WindowScroll.api.scroll_toTarget", { target: cardEl, config: { topOffset } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
