// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { Chart, ScatterController, PointElement, LinearScale } from "chart.js/auto";
Chart.register(ScatterController, PointElement, LinearScale);

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_matrixAnswerGraph", inline: true };
const msgs = {
  start: ["init. matrixAnswerGraph"],
  end: ["init. matrixAnswerGraph", "init. success"],
  no_el: ["init. matrixAnswerGraph", "no valid DOM element(s) provided"],
  no_canvasEl: ["init. matrixAnswerGraph", "no canvas element provided"],
  no_data: ["init. matrixAnswerGraph", "no data found on graph DOM el."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function init_matrixAnswerGraph() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { config_futureMatrix } = this.options;
  const { matrixAnswerGraph, matrixDatapointTooltip } = this.ref;
  const canvasEl = matrixAnswerGraph.querySelector("[data-ref='canvas']");
  const { is_mobile } = this.state;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(matrixAnswerGraph)) return this.cancel_featInit(msgs.no_el);
  if (!this.validate_refEl(canvasEl)) return this.cancel_featInit(msgs.no_canvasEl);

  /////////////////////// Chart instance creation ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Prep. tooltip hdl...
  const externalTooltipHdl = this.api.show_matrixDatapointTooltip;

  // Get dyn. options from config...
  const { xaxismin, xaxismax, yaxismin, yaxismax } = config_futureMatrix;

  // Fetch + format answer data...
  const answerData = await this.api.fetch_matrixAnswerData();
  if (!answerData) return this.cancel_featInit(msgs.no_data);
  const graphData_formatted = answerData.map((dP) => ({ ...dP, x: parseFloat(dP.x), y: parseFloat(dP.y) }));
  this.options.matrixAnswerData_formatted = graphData_formatted;

  // Formulate dataset...
  const datasets = [
    {
      label: null,
      data: graphData_formatted,
      pointRadius: is_mobile ? 10 : 20,
      pointHoverRadius: is_mobile ? 10 : 20,
      pointBackgroundColor: function (CTX) {
        const index = CTX.dataIndex;
        const value = CTX.dataset.data[index];
        if (!value) return;
        const is_currUser = value.is_currUser;
        return is_currUser ? "#a200ff" : "#ff7130"; // ← highlight data point corresp. to curr. user
      },
      pointHoverBackgroundColor: "#a200ff",
      pointBorderColor: "transparent",
    },
  ];

  // Instantiate chart...
  const chartInst = new Chart(canvasEl, {
    type: "scatter",
    data: { datasets },
    options: {
      layout: { padding: 0, autoPadding: false },
      responsive: true,
      maintainAspectRatio: false,
      animation: { duration: 0 },

      //// Scales config. ////
      ////////////////////////

      scales: {
        x: {
          type: "linear",
          display: true,
          position: "center",
          min: xaxismin,
          max: xaxismax,
          ticks: { display: false },
          grid: { display: false },
        },
        y: {
          type: "linear",
          display: true,
          position: "center",
          min: yaxismin,
          max: yaxismax,
          ticks: { display: false },
          grid: { display: false },
        },
      },

      // Interact. config. ///
      ////////////////////////

      interaction: {
        mode: "point",
        intersect: true,
      },

      /// Plugins config. ////
      ////////////////////////

      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: false,
          position: "nearest",
          external: externalTooltipHdl,
          events: ["mousemove", "mouseout", "touchstart", "touchmove", "touchend"],
        },
      },
    },
  });

  ////////////////////// Chart custom tooltip init. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Init. tooltip el. SVG border...
  this.init_btnSVGborders([matrixDatapointTooltip]);

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.modules.matrixAnswerGraph.chart = chartInst;
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
