// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getWidth, getHeight } from "mezr";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.updt_matrixAnswerGraph_userTooltip", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "updt_matrixAnswerGraph_userTooltip"],
  end: ["PartiForm", "API", "updt_matrixAnswerGraph_userTooltip", "API call complete"],
  no_userAnswerTooltip: ["PartiForm", "API", "updt_matrixAnswerGraph_userTooltip", "No user answer tooltip el. found"],
  no_chartInst: ["PartiForm", "API", "add_matrixAnswerGraphDataPoint", "No chart inst. found"],
  no_userAnswerDataPoint: ["PartiForm", "API", "add_matrixAnswerGraphDataPoint", "No user answer data point found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_matrixAnswerGraph_userTooltip() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { matrixDatapointTooltip_userAnser: tooltipEl } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);
  if (!tooltipEl) return this.logger("warning", msgs.no_userAnswerTooltip, "warning", logArgs);

  //////////////////////// Tooltip position updt. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  /////// User-answer data point extract. ////////
  ////////////////////////////////////////////////

  // Get answer graph chart isntance...
  const chartInst = this.modules.matrixAnswerGraph.chart;
  if (!chartInst) return this.logger("warning", msgs.no_chartInst, "warning", logArgs);

  // Get data points and their positions...
  const dataPoints_withPositions = chartInst.data.datasets.flatMap((dataset, datasetIndex) => {
    const meta = chartInst.getDatasetMeta(datasetIndex);
    return dataset.data.map((dataPoint, index) => {
      const position = meta.data[index].getProps(["x", "y"], true);
      return { dataPoint, position };
    });
  });

  // Get user answer data point (is_currUser: true)...
  const userAnswerDataPoint = dataPoints_withPositions.find((dataPoint) => dataPoint.dataPoint.is_currUser);
  if (!userAnswerDataPoint) return this.logger("warning", msgs.no_userAnswerDataPoint, "warning", logArgs);
  const userAnswerPosition = userAnswerDataPoint.position;

  //////////// Tooltip el. position. /////////////
  ////////////////////////////////////////////////

  // Dimensions...
  const wpWidth = window.innerWidth;
  const wpHeight = window.innerHeight;
  const tooltipWidth = getWidth(tooltipEl);
  const tooltipHeight = getHeight(tooltipEl);
  const { x: userAnswerX, y: userAnswerY } = userAnswerPosition;
  const offset = 20;

  // Calculate tooltip position...
  let calculatedLeft = userAnswerX + offset;
  let calculatedTop = userAnswerY + offset;

  // Adjust for horizontal & vertical overflow...
  if (calculatedLeft + tooltipWidth > wpWidth) calculatedLeft = wpWidth - tooltipWidth - offset;
  if (calculatedTop + tooltipHeight > wpHeight) calculatedTop = wpHeight - tooltipHeight - offset;

  // Apply position...
  tooltipEl.style.top = calculatedTop + "px";
  tooltipEl.style.left = calculatedLeft + "px";

  // Show tooltip...
  tooltipEl.setAttribute("data-is-hidden", false);

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
