// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function adjust_shiftAmt(shiftDir, shiftAmount, space) {
  if (space < shiftAmount) {
    console.warn(`Not enough space ${shiftDir} of card for shifting, adjusting shift amount`);
    return space;
  }
  return shiftAmount;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_initCardGridPositions(
  CARDELS,
  CONFIG = {
    shiftRange_x: [100, 200],
    shiftRange_y: [100, 200],
  }
) {
  if (!CARDELS || !CARDELS.length) return console.warn("set_initCardPositions(): No valid card els. provided");

  // Setup...
  const { shiftRange_x, shiftRange_y } = CONFIG;

  // Shift every card el. to left or right and top or bottom by a random amount between 100 and 200px...
  CARDELS.forEach((el) => {
    // Get current position of the element
    const rect = el.getBoundingClientRect();
    const cardW = rect.width;
    const currCardX = rect.left;
    const currCardY = rect.top;

    //////////////////////
    // Top-bottom shift //
    //////////////////////

    // Determ. shift direction & amount...
    const shiftDir_y = Math.random() > 0.5 ? "up" : "down";
    let shiftY = Math.random() * (shiftRange_y[1] - shiftRange_y[0]) + shiftRange_y[0];

    // If shifting up, check if card has enough space above for shifting + adjust shift amount if necessary
    if (shiftDir_y === "up") {
      const spaceAbove = currCardY;
      shiftY = adjust_shiftAmt("up", shiftY, spaceAbove);
    }

    //////////////////////
    // Left-right shift //
    //////////////////////

    // Determ. shift direction & amount...
    const shiftDir_x = Math.random() > 0.5 ? "left" : "right";
    let shiftX = Math.random() * (shiftRange_x[1] - shiftRange_x[0]) + shiftRange_x[0];

    // If shifting left, check if card has enough space to the left for shifting + adjust shift amount if necessary
    if (shiftDir_x === "left") {
      const spaceLeft = currCardX;
      shiftX = adjust_shiftAmt("left", shiftX, spaceLeft);
    }

    // If shifting right, check if card has enough space to the right for shifting + adjust shift amount if necessary
    if (shiftDir_x === "right") {
      const spaceRight = window.innerWidth - currCardX - cardW;
      shiftX = adjust_shiftAmt("right", shiftX, spaceRight);
    }

    ///////////////////////
    // Shift application //
    ///////////////////////

    // Apply shift...
    const x = shiftDir_x === "left" ? -shiftX : shiftX;
    const y = shiftDir_y === "up" ? -shiftY : shiftY;
    this.logger("init", ["shifting card by", x, "px on x-axis and", y, "px on y-axis"], "action");
    el.style.transform = `translate(${x}px, ${y}px)`;
  });
}
