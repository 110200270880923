// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.expand_cardClipPath", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "expand_cardClipPath"],
  end: ["DragCards", "API", "expand_cardClipPath", "API call complete"],
  no_cardEl: ["DragCards", "API", "expand_cardClipPath", "no valid card el. provided"],
  no_clippedContent: ["DragCards", "API", "expand_cardClipPath", "no valid clipped content el. found"],
};
let animDuration_angular = 0.4;
let animDuration_ellipse = 0.6;

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function expand_cardClipPath(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.logger("error", msgs.no_cardEl, "error", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Find clipped content el...
  const clippedContent = CARDEL.querySelector("[data-role='clippedCardContent']");
  if (!clippedContent) return this.logger("error", msgs.no_clippedContent, "error", logArgs);

  // Determ. clip path based on background shape...
  const bgShape = CARDEL.dataset.bgShape;
  const expandedClipPath =
    bgShape == "angular"
      ? "polygon(0% 0%, 100% 0%, 100% 0%, 100% 100%, 100% 100%, 0% 100%, 0% 100%, 0% 0%)"
      : "ellipse(100% 100% at 50% 50%)";

  // Expand clip path...
  switch (bgShape) {
    case "angular":
      gsap.to(clippedContent, { clipPath: expandedClipPath, duration: animDuration_angular });
      break;
    case "ellipse":
      gsap.to(clippedContent, { clipPath: expandedClipPath, duration: animDuration_ellipse });
      break;
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
