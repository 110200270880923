// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.processSteps_consentCheck", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "processSteps_consentCheck"],
  end: ["PartiForm", "API", "processSteps_consentCheck", "API call complete"],
  no_stepString: () => ["PartiForm", "API", "processSteps_consentCheck", "No step string provided."],
  consentCheckDisabled: ["PartiForm", "API", "processSteps_consentCheck", "Consent check is disabled.", "stopping step process"],
};

const possibleSteps = ["consentCheck", "consentCheckDone"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function processSteps_consentCheck(
  DATA = {
    step: null,
  }
) {
  if (!valid_str(DATA?.step)) return this.logger("error", msgs.no_stepString(), "error", logArgs);
  if (!possibleSteps.includes(DATA?.step)) return this.logger("error", msgs.stepNotAllowed(DATA?.step), "error", logArgs);
  if (!this.options.with_consentCheck) return this.logger("warning", msgs.consentCheckDisabled, "warning", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { step } = DATA;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  switch (true) {
    ////////////// "consentCheckDone" //////////////
    ////////////////////////////////////////////////

    // - reveal + scroll to "game-selection" sub-section...
    // - init. game-select. btns...
    case step == "consentCheckDone":
      api.show_subsection('[data-ref="section_gameSelection"]');
      api.scrollTo_subsection('[data-ref="section_gameSelection"]');
      this.init_gameSelectBtns();
      break;

    ///////////// Default (do nothing) /////////////
    ////////////////////////////////////////////////

    default:
      break;
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
