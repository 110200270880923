// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

function convert_tooltipLines_toXYcoords(TOOLTIPLINES) {
  // Use regex to find numbers in string...
  const regexPattern = /\((-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)\)/;
  const matches = TOOLTIPLINES.match(regexPattern);

  // If matches are found, convert them to numbers and return...
  if (matches) {
    const x = parseFloat(matches[1]);
    const y = parseFloat(matches[3]);
    return [x, y];
  } else return [];
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.show_matrixDatapointTooltip", inline: true };
const msgs = {
  start: ["PartiForm", "API", "show_matrixDatapointTooltip"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_matrixDatapointTooltip(CONTEXT) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { chart, tooltip } = CONTEXT;
  const { matrixAnswerData_formatted } = this.options;
  const { matrixDatapointTooltip } = this.ref;
  const textEl = matrixDatapointTooltip.querySelector("[data-ref='textEl']");
  const imgEl = matrixDatapointTooltip.querySelector("[data-ref='imgEl']");
  this.logger("event", msgs.start, "event", logArgs);

  // if not intended to be shown (mouse left point in chart)
  // - Hide tooltip el...
  // - Hide img el. + reset src...
  if (tooltip.opacity === 0) {
    matrixDatapointTooltip.setAttribute("data-is-hidden", true);
    // setTimeout(() => imgEl.setAttribute("data-is-hidden", true), 301); // ← allow tooltip el. fade-trans. to finish
    return;
  }

  //////////////////////// Tooltip content updt. /////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Find corresp. answer data to extract content to show...
  const tooltipLines = tooltip?.body[0]?.lines;
  const has_tooltipLines = tooltipLines && tooltipLines.length > 0;
  const tooltipCoords = has_tooltipLines ? convert_tooltipLines_toXYcoords(tooltipLines[0]) : null;
  const correspAnswerData = has_tooltipLines
    ? matrixAnswerData_formatted.find((dP) => dP.x === tooltipCoords[0] && dP.y === tooltipCoords[1])
    : null;
  const { answerText, answerImgURL } = correspAnswerData || {};
  const has_imgURL = valid_str(answerImgURL);

  // Updt. tooltip el. data-with-img. attr. (toggles content padding)...
  matrixDatapointTooltip.setAttribute("data-with-img", has_imgURL);

  // Insert tooltip content...
  // const headline = matrixDatapointTooltip.querySelector("[data-ref='headline']"); // DEPRECATED
  // if (headline) headline.setAttribute("data-index", index); // DEPRECATED
  if (textEl) textEl.textContent = answerText;
  if (imgEl) {
    if (has_imgURL) {
      imgEl.src = answerImgURL;
      imgEl.setAttribute("data-is-hidden", false);
      imgEl.onload = () => matrixDatapointTooltip.setAttribute("data-is-hidden", false); // ← show tooltip el on img. load
    } else imgEl.setAttribute("data-is-hidden", true); // ← hide img el. if no imgURL
  }

  //////////////////////// Tooltip position updt. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Dimensions...
  const wpWidth = window.innerWidth;
  const wpHeight = window.innerHeight;
  const tooltipWidth = matrixDatapointTooltip.offsetWidth;
  const tooltipHeight = matrixDatapointTooltip.offsetHeight;
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  const offset = 20;

  // Calculate tooltip position...
  let calculatedLeft = positionX + tooltip.caretX + offset;
  let calculatedTop = positionY + tooltip.caretY + offset;

  // Adjust for horizontal & vertical overflow...
  if (calculatedLeft + tooltipWidth > wpWidth) calculatedLeft = wpWidth - tooltipWidth;
  if (calculatedTop + tooltipHeight > wpHeight) calculatedTop = wpHeight - tooltipHeight;

  // Apply updt. position...
  matrixDatapointTooltip.style.left = calculatedLeft + "px";
  matrixDatapointTooltip.style.top = calculatedTop + "px";

  // Show tooltip el...
  if (!has_imgURL) matrixDatapointTooltip.setAttribute("data-is-hidden", false);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
