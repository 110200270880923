// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function create_fileFromDataURL(dataURL, fileName) {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });

  // Create a file from the Blob
  const file = new File([blob], fileName, { type: mimeString });

  return file;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.submit_drawing", inline: true };
const msgs = {
  start: ["PartiForm", "API", "submit_drawing"],
  no_fabricInst: ["PartiForm", "API", "submit_drawing", "no fabric instance found"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function submit_drawing() {
  return new Promise(async (resolve, reject) => {
    //////////////////////////////////////////// Setup /////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    const { participationPageId, with_consentCheck } = this.options;
    const fabricInst = this.modules.fabric.instances[0];
    this.logger("event", msgs.start, "event", logArgs);

    // Guard...
    if (!fabricInst) {
      console.log("%cNo fabric instance found.", consoleStyles.error);
      return this.logger("event", msgs.no_fabricInst, "error", logArgs);
    }

    ////////////////////////////////////// Drawing submission //////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // Setup...
    const submitIndex_curr = await this.api.fetch_currDrawingSubmitIndex();
    const submitIndex_new = submitIndex_curr + 1;
    console.log("%cSubmitting parti. form drawing...", consoleStyles.default);

    // Create img. from fabric canvas...
    const img = fabricInst.toDataURL({ format: "jpeg", multiplier: 2 });
    const imgFile = create_fileFromDataURL(img, "drawing.jpeg");

    // Set up backend request...
    const req = new XMLHttpRequest();
    const data = new FormData();
    data.append("partiPageID", participationPageId);
    data.append("submitIndex", submitIndex_new);
    data.append("drawingFile", imgFile);

    // Add consent check data (if enabled)...
    if (with_consentCheck) {
      const { userConsent } = this.state;
      if (userConsent) data.append("userConsentData", JSON.stringify(userConsent));
    }

    // Init. + send request...
    req.open("POST", "/api.parti-form.submit.drawing", true);
    req.onreadystatechange = async () => {
      //////////////////////// Successful subm. hdl. /////////////////////////
      ////////////////////////////////////////////////////////////////////////

      if (req.readyState === 4 && req.status === 200) {
        console.log("%cParti. form drawing submitted.", consoleStyles.success);
        resolve({ msg: req.responseText, drawingDataURL: img, submitIndex: submitIndex_new });
      }

      ////////////////////////// Failed subm. hdl. ///////////////////////////
      ////////////////////////////////////////////////////////////////////////

      if (req.status !== 200) {
        console.log("%cParti. form drawing submission failed.", consoleStyles.error);
        reject(req.responseText);
      }
    };
    req.send(data);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
