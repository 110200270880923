// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect } from "mezr";
import gsap from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.updt_commentWidgetPos", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "updt_commentWidgetPos"],
  end: ["PartiForm", "API", "updt_commentWidgetPos", "API call complete"],
  no_cardEl: ["DragCards", "API", "updt_commentWidgetPos", "no valid card el. provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_commentWidgetPos(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { commentWidget } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.logger("error", msgs.no_cardEl, "error", logArgs);

  //////////////////////// Widget position updt. /////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Get card dimensions...
  const rect_card = getRect(CARDEL);
  const l_card = rect_card.left;
  const b_card = rect_card.bottom;

  // Determ. new widget x/y pos.
  const x = l_card;
  const y = b_card;

  // Set widget pos...
  gsap.to(commentWidget, { duration: 0.3, ease: "power3.out", x, y });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
