// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Cookies from "js-cookie";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_click", inline: true };
const loggerMsgs = {
  eventStart: ["comp. el. was clicked"],
  settingCookie: ["comp. el. was clicked", "setting cookie to hide comp. el. on next visit"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_click() {
  // Setup...
  const { setCookieOnHide } = this.options;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Hide comp. el. on click...
  this.api.hideComp();

  // If applicable, set cookie to hide comp. el. on next visit...
  if (setCookieOnHide) {
    this.logger("event", loggerMsgs.settingCookie, "event", logArgs);
    Cookies.set("ash_sitePortal_hideComp", "true", { expires: 7 });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
