// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_menuBtn_click from "../eventHandlers/eh_menuBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. menuBtn"],
  initSuccess: ["init. menuBtn", "init. success"],
  noValidDOMEl: ["init. menuBtn", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_menuBtn() {
  // Setup...
  const { menuBtn } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEl(menuBtn)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to click event on menuBtn...
  menuBtn.addEventListener("click", eh_menuBtn_click.bind(this));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
