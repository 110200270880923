// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function get_cardFollowerEl(CARDEL) {
  if (!CARDEL) return console.warn("get_cardFollowerEl(): No valid card el. provided");

  // Setup...
  const { cardFollowers } = this.ref;

  // Find related follower el...
  const cardID = CARDEL.dataset.id;
  const followerEl = cardFollowers?.find((el) => el.dataset.parentCardId === cardID);
  return followerEl;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
