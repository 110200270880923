// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.insert_drawingIntoViewer", inline: true, disabled: false };
const msgs = {
  start: (SUBMITINDEX) => ["PartiForm", "API", "insert_drawingIntoViewer", `submit index: ${SUBMITINDEX}`],
  end: ["PartiForm", "API", "insert_drawingIntoViewer", "API call complete"],
  no_url: ["PartiForm", "API", "insert_drawingIntoViewer", "no drawing URL provided"],
  no_viewer: ["PartiForm", "API", "insert_drawingIntoViewer", "no viewer found"],
  no_section: ["PartiForm", "API", "insert_drawingIntoViewer", "no matching section found"],
};
const defData = { drawingDataURL: null, submitIndex: 0 };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function insert_drawingIntoViewer(DATA = defData) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const data = { ...defData, ...DATA };
  const { url_drawing, submitIndex } = data;
  this.logger("event", msgs.start(submitIndex), "event", logArgs);

  // Guard...
  if (!valid_str(url_drawing)) return this.logger("warning", msgs.no_url, "warning", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Insert drawing into viewer section w/ matching index...
  const { drawingViewer } = this.ref;
  if (!drawingViewer) return this.logger("warning", msgs.no_viewer, "warning", logArgs);
  const viewerSections = drawingViewer.querySelectorAll("[data-ref='viewerSection']");
  const matchingSection = [...viewerSections].find((s) => s.dataset.index == submitIndex - 1);
  if (!matchingSection) return this.logger("warning", msgs.no_section, "warning", logArgs);
  const image = new Image();
  image.src = url_drawing;
  image.addEventListener("load", () => matchingSection.setAttribute("src", url_drawing));

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
