// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import shrink_textToMinWidth from "../../../../../app/baseUtilities/shrink/shrink_textToMinWidth";

function stretch_textToWrapperWidth(TEXTEL) {
  if (!TEXTEL) return console.warn("No valid text el. provided to stretch_textToWrapperWidth()");

  // Scale text to fit wrapper width...
  const wrapper = TEXTEL.parentElement;
  const scale = wrapper.getBoundingClientRect().width / TEXTEL.getBoundingClientRect().width;
  TEXTEL.style.transform = `scaleX(${scale})`;
}

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. strechedCardHeaderText"],
  initSuccess: ["init. strechedCardHeaderText", "init. success"],
  noValidDOMEl: ["init. strechedCardHeaderText", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_strechedCardHeaderText(CARDEL) {
  // Setup...
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!CARDEL) return console.warn("No valid card el. provided to init_strechedHeaderText()");
  const header = CARDEL.querySelector("header");
  if (!header) return console.warn("init_strechedHeaderText(): No valid header el. found in card el.");

  ////////////////////////////
  // Header title streching //
  ////////////////////////////

  // Get header text lines...
  const lines = header.querySelectorAll('[data-role="header-text-line"]');
  if (lines && lines.length) {
    let largestLine;
    const headerRect = header.getBoundingClientRect();
    lines.forEach((l) => {
      // Shrink line to min. width...
      shrink_textToMinWidth(l);

      // Find largest line (to use for streching)...
      const isLargest = !largestLine || l.getBoundingClientRect().width > largestLine.getBoundingClientRect().width;
      if (isLargest) largestLine = l;
    });

    // Use largest line to calc. streching scale (largest line must fit, others will scale accordingly)...
    const scale = headerRect.width / largestLine.getBoundingClientRect().width;

    // Apply scale to all lines...
    lines.forEach((l) => (l.style.transform = `translateX(-50%) scaleX(${scale})`));

    // Set header height to height of tag-bar (if present) + line count * 1em...
    const tagBar = header.querySelector('[data-role="tag-bar"]');
    const tagBarHeight = tagBar ? tagBar.getBoundingClientRect().height : 0;
    header.style.height = `calc(${tagBarHeight}px + (${lines.length} * 0.85em))`;

    // Conclude...
    this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
  } else console.warn("init_strechedHeaderText(): No valid header text lines found in card el.");

  //////////////////////////
  // Header tag streching //
  //////////////////////////

  const tagEls = header.querySelectorAll('[data-role="tag"]');
  if (tagEls && tagEls.length)
    tagEls.forEach((t) => {
      const textEl = t.querySelector("span");
      if (textEl) stretch_textToWrapperWidth(textEl);
    });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
