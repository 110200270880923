// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_siteTitle_click", inline: true };
const loggerMsgs = {
  eventStart: ["an event occurred"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_siteTitle_click() {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Toggle site mode ('campus-trifft-stadt' ←→ 'campus-trifft-stadt')...
  const mode_current = document.body.getAttribute("data-mode");
  const mode_new = mode_current === "campus-trifft-stadt" ? "stadt-trifft-campus" : "campus-trifft-stadt";
  document.body.setAttribute("data-mode", mode_new);

  // Switch site title text...
  const titleText_new = mode_new === "campus-trifft-stadt" ? "Campus trifft Stadt" : "Stadt trifft Campus";
  const siteTitleEl = document.getElementById("siteTitle");
  if (siteTitleEl) siteTitleEl.textContent = titleText_new;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
