// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_btn_click", inline: true };
const start = {
  start: ["prev-/next-btn was clicked"],
  no_flickityInst: ["no Flickity inst. available"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_btn_click(BTNEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const flicktiyInst = this.modules?.flickity?.instance;
  this.logger("event", start.start, "event", logArgs);

  // Guard...
  if (!flicktiyInst) return this.logger("warning", start.no_flickityInst, "warning", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Move to prev./next slide...
  const dir = BTNEL === this.ref.prevBtn ? "prev" : "next";
  if (dir === "prev") flicktiyInst.previous();
  if (dir === "next") flicktiyInst.next();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
