// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_monthFilterBtn_click", inline: true, disabled: false };
const msgs = {
  start: ["month-filter btn. has been clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_monthFilterBtn_click(BUTTONEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { activeMonthFilters } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  ////////////////////// Active month filter updt. ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Copy active month filters...
  let activeMonthFilters_new = [...activeMonthFilters];

  // Get month filter ID from btn. el...
  const monthFilterID = BUTTONEL.dataset.month;
  if (!monthFilterID) return console.warn("eh_monthFilterBtn_click(): no month ID found on button el.");

  // If month filter is already active, remove it...
  if (activeMonthFilters_new.includes(monthFilterID)) {
    activeMonthFilters_new =
      activeMonthFilters_new.length === 1 ? ["all"] : activeMonthFilters_new.filter((ID) => ID !== monthFilterID);
  }

  // If month filter is not active, add it...
  else activeMonthFilters_new.push(monthFilterID);

  // Update state...
  this.setState({ activeMonthFilters: activeMonthFilters_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
