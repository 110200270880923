// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_is_hidden", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`is hidden: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_is_hidden(CHANGES) {
  if (!("is_hidden" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.is_hidden))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.is_hidden), "error", logArgs);

  // Setup...
  const { is_hidden } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(is_hidden), "default", logArgs);

  // Updt. comp. el. data-is-hidden attr...
  this.element.setAttribute("data-is-hidden", is_hidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
