// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_is_hidden", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`is_hidden: ${CHANGE}`],
  no_change: () => ["No change to state provided."],
  inval_stateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_is_hidden(CHANGES) {
  if (!("is_hidden" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.is_hidden))
    return this.logger("error", msgs.inval_stateValue(CHANGES.is_hidden), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { is_hidden } = CHANGES;
  this.logger("state-change", msgs.stateChange(is_hidden), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // [action]...

  ///////////////////// Comp. is-hidden attr. updt. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.element.setAttribute("data-is-hidden", is_hidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
