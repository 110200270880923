// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.fetch_termText", inline: true };
const loggerMsgs = {
  eventStart: ["GlossaryLinks", "API", "fetch glossary term text"],
  noTerm: ["GlossaryLinks", "API", "fetch glossary term text", "no term provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function fetch_termText(TERM) {
  return new Promise((resolve, reject) => {
    // Guard: stop, if no term provided...
    if (!TERM) return reject("No term provided.");

    // Setup...
    this.logger("event", loggerMsgs.eventStart, "event", logArgs);

    // Fetch drawings via API route...
    const url = "/api.glossary.get.term-text";
    const data = { term: TERM };
    const options = { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(data) };
    try {
      fetch(url, options)
        .then((res) => res.json())
        .then((respData) => {
          // Guard...
          if (!respData) return reject("No submit index data found for page.");

          // Resolve...
          const termText = respData.termText;
          resolve(termText);
        })
        .catch((respError) => {
          // Guard...
          if (respError) return reject(respError);

          // Resolve...
          resolve([]);
        });
    } catch (error) {
      reject(error);
    }
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
