// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_cardCollapsible", inline: true };
const loggerMsgs = {
  initStart: ["init. cardCollapsible"],
  initSuccess: ["init. cardCollapsible", "init. success"],
  noValidDOMEl: ["init. cardCollapsible", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_cardCollapsible(CARDEL) {
  // Setup...
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Hdl. click on collapsible btn...
  const collapBtn = CARDEL.querySelector("[data-role='collap-btn']");
  collapBtn.addEventListener("click", () => {
    // Toggle card el. data-collap-is-open attr...
    const is_open = CARDEL.getAttribute("data-collap-is-open") === "true";
    CARDEL.setAttribute("data-collap-is-open", !is_open);
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
