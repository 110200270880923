// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect } from "mezr/getRect";
import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.keepCardsInViewport", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "keepCardsInViewport"],
  end: ["DragCards", "API", "keepCardsInViewport", "API call complete"],
  no_cardInst: ["DragCards", "API", "keepCardsInViewport", "no card instance(s) found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function keepCardsInViewport(
  DATA = {
    amt_vpWidthChange: null, // ← amount of px. viewport width changed
  }
) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const cardInstances = this.modules.cards.instances;
  const { amt_vpWidthChange } = DATA;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard: stop, if no card instances found...
  if (!cardInstances || !cardInstances.length) return this.logger("warning", msgs.no_cardInst, "warning", logArgs);

  // Determ. if viewport grew or shrunk...
  const vp_grew = amt_vpWidthChange > 0;

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Check every card instance’s element’s right edge...
  const w_window = window.innerWidth;
  const types_toResize = ["img_withoutBg"];
  cardInstances.forEach((cInst) => {
    const { el: cardEl, type } = cInst;
    const cardElRect = getRect(cardEl);
    const r_cardEl = cardElRect.right;

    // Updt. card el. width (30vw)...
    if (types_toResize.includes(type)) cardEl.style.setProperty("--w", `${w_window * 0.3}px`);

    // If viewport grew, move card to right by amount of viewport width change...
    if (vp_grew) {
      gsap.to(cardEl, { x: `+=${amt_vpWidthChange}`, duration: 0.5 });
    }

    // Otherwise, if right edge is outside viewport (> viewport’s right edge), move card
    // to left, by difference between card’s right edge and viewport’s right edge...
    else {
      const is_outsideVP = r_cardEl > w_window;
      if (is_outsideVP) {
        const diff = r_cardEl - w_window;
        gsap.to(cardEl, { x: `-=${diff}`, duration: 0.5 });
      }
    }
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
