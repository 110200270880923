// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_feature", inline: true, disabled: false };
const msgs = {
  start: ["init. init_footnotes"],
  end: ["init. init_footnotes", "init. success"],
  no_footnoteLinks: ["init. init_footnotes", "no footnote link els. found"],
  no_footnoteEls: ["init. init_footnotes", "no footnote els. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_footnotes() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Find footnote link & footnote DOM elements on page...
  const footnoteLinks = document.querySelectorAll("[data-footnote-link]");
  const footnotes = document.querySelectorAll("[data-ref='footnote']");

  // Guard: Stop, is no footnote links or footnote els. found...
  if (!footnoteLinks.length) return this.logger("init", msgs.no_footnoteLinks, "warning", logArgs);
  if (!footnotes.length) return this.logger("init", msgs.no_footnoteEls, "warning", logArgs);

  // Set footnote positions:
  footnoteLinks.forEach((link) => this.api.set_footnotePos(link));

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
