// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_listItem_mouseEnter", inline: true };
const loggerMsgs = {
  eventStart: ["mouse entered list item"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_listItem_mouseEnter(ITEMEL, EVENTDATA) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // // - Updt. active-list-item state...
  // // - Updt. info-display state...
  // const itemID = ITEMEL.dataset.id;
  // const itemInfoText = ITEMEL.dataset.infoText;
  // this.setState({
  //   activeListItem: { id: itemID },
  //   infoDisplayState: {
  //     text: itemInfoText,
  //     position: { x: EVENTDATA.clientX, y: EVENTDATA.clientY },
  //     is_hidden: !validate_infoText(itemInfoText),
  //   },
  // });

  // - Updt. hovered-list-item state...
  const itemID = ITEMEL.dataset.id;
  this.setState({ hoveredListItem: { id: itemID } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
