// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_card_mouseLeave", inline: true };
const msgs = {
  start: ["mouse left cart"],
  is_mobile: ["mouse left card", "while on mobile", "stopping event hdl."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_card_mouseLeave(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { is_mobile } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard: stop, if on mobile...
  if (is_mobile) return this.logger("warning", msgs.is_mobile, "warning", logArgs);

  ///////////////////////// Card contract anim. //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (CARDEL.dataset.expandOnHover === "true") api.contract_card(CARDEL);

  ///////////////////////// Card follower hide ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (CARDEL.dataset.withFollower === "true") api.hide_cardFollower(CARDEL);

  /////////////// Card SVG backgr./clip path/border reset ////////////////
  ////////////////////////////////////////////////////////////////////////

  if (CARDEL.dataset.hasExpandingBg === "true") {
    // - Contract SVG background...
    // - Contract clip path...
    // - Contract border path...
    api.contract_cardBg(CARDEL);
    api.contract_cardClipPath(CARDEL);
    api.contract_cardBorder(CARDEL);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
