// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_scrollPosition(CHANGES) {
  // Setup...
  const { maxScrollPosition } = this.state;
  const { scrollPosition } = CHANGES;
  const scrollPercentage = (scrollPosition / maxScrollPosition) * 100;
  this.logger("state-change", [`scroll position: ${scrollPosition}`], "default", { inline: true });

  //////////////////
  // Default hdl. //
  //////////////////

  // Store scroll pos. in CSS var. on doc. el...
  document.documentElement.style.setProperty("--scroll-y", `${scrollPosition}px`);

  // Emit global eventbus call...
  const args = { maxScrollPosition, scrollPosition, scrollPercentage };
  eventbus.emit("windowScroll_stCh_scrollPosition", args);

  /////////////////
  // Custom hdl. //
  /////////////////

  const customStChHdls = this.config.customHandlers.stateChange;
  if (!customStChHdls.length) return;
  const customScrollPositionStChHdls = customStChHdls.filter((hdl) => hdl.id === "scrollPosition");
  if (!customScrollPositionStChHdls.length) return;
  customScrollPositionStChHdls.forEach((hdl) => hdl.handler(args));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
