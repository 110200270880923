// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_windowScroll_stCh_scrollPosition" };
const msgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_windowScroll_stCh_scrollPosition(ARGS) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Toggle filter menu position when scrolling into page (further than 50px)...
  const { scrollPosition } = ARGS;
  this.setState({ filterMenuPosition: scrollPosition > 50 ? "center" : "bottom" });

  // DEPRECATED //
  // Toggle filter menu visibility when reaching/leaving bottom of page...
  // const { scrollPercentage } = ARGS;
  // const is_atBottom = scrollPercentage >= 99;
  // this.setState({ filterMenuHidden: is_atBottom });
  // DEPRECATED //
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
