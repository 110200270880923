// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import fullpage from "fullpage.js";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_fullpage", inline: true };
const msgs = {
  start: ["init. fullpage"],
  end: ["init. fullpage", "init. success"],
  no_el: ["init. fullpage", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_fullpage() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { sectionsWrapper } = this.ref;
  const { is_mobile } = this.state;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(sectionsWrapper)) return this.cancel_featInit(msgs.no_el);

  ///////////////////////////////////// fullpage inst. init. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Create fullpage.js instance...
  const fullpageInst = new fullpage(sectionsWrapper, {
    scrollOverflow: true,
    sectionSelector: ".sectionGroup",
    normalScrollElements: '[g-ref="sections"]',
    keyboardScrolling: false,
  });

  // On mobile, programmatically disable scroll on all fp overflow elements...
  if (is_mobile) {
    const fpOverflowEls = sectionsWrapper.querySelectorAll(".fp-overflow");
    fpOverflowEls.forEach((el) => (el.style.overflowY = "hidden"));
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.modules.fullpage.instance = fullpageInst;
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
