// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_confirmBtn_researchUseConsent_click", inline: true, disabled: false };
const msgs = {
  start: ["research-use-consent-confirm btn. has been clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_confirmBtn_researchUseConsent_click() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { consentQuest_researchUse, consentQuest_nameAgeQuery } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Register in state that user has confirmed research-use consent...
  const { userConsent } = this.state;
  const userConsent_new = { ...userConsent, researchUseConsent: true };
  this.setState({ userConsent: userConsent_new });

  // - Hide research-use consent question...
  // - Show name-age-query consent question...
  consentQuest_researchUse.setAttribute("data-is-hidden", "true");
  consentQuest_nameAgeQuery.setAttribute("data-is-hidden", "false");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
