// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../baseUtilities/logger.js";
import set_state from "../../../baseUtilities/state/set_state";

import scrollTo_target from "./utilities/scrollTo_target";
import disable_scroll_onBody from "./utilities/disable_scroll_onBody";
import disable_scroll_fully from "./utilities/disable_scroll_fully";
import enable_scroll_onBody from "./utilities/enable_scroll_onBody";
import enable_scroll_fully from "./utilities/enable_scroll_fully";
import preventDefault from "./utilities/preventDefault";
import preventDefault_forScrollKeys from "./utilities/preventDefault_forScrollKeys";
import check_supportsPassive from "./utilities/check_supportsPassive";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_sweetScroll from "./init/init_sweetScroll";
import init_scrollObserver from "./init/init_scrollObserver";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_page_load from "./eventbusHandlers/ebh_swup_page_load.js";
import ebh_swup_content_replace from "./eventbusHandlers/ebh_swup_content_replace.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode";
import stChH_scrollPosition from "./stateChangeHandlers/stChH_scrollPosition";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logStyles = { default: "#4361BC", action: "#c7d0ff", event: "#97a5ce", warning: "#ffaf00", error: "#ff3232" };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default class WindowScroll {
  //////////////////////////////// Constructor /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  constructor(
    CONFIG = {
      logs: { run_withLogs: false, logStyles: logStyles },
      customHandlers: { stateChange: [] },
    },
    OPTIONS
  ) {
    ///////////////////////// Config. //////////////////////////
    ////////////////////////////////////////////////////////////

    this.config = {
      customHandlers: CONFIG?.customHandlers ?? { stateChange: [] },
    };

    ///////////////////////// Options //////////////////////////
    ////////////////////////////////////////////////////////////

    this.options = {
      ...OPTIONS,
      name: "WindowScroll",
      run_withLogs: CONFIG?.logs?.run_withLogs,
      logStyles: CONFIG?.logs?.logStyles,
      //////
      supportsPassive: check_supportsPassive(),
      keys: { 37: 1, 38: 1, 39: 1, 40: 1 },
      wheelOption: check_supportsPassive() ? { passive: false } : false,
      wheelEventName: "onwheel" in document.createElement("div") ? "wheel" : "mousewheel",
    };

    ////////////////////////// State ///////////////////////////
    ////////////////////////////////////////////////////////////

    this._state = { initialised: false };
    this.setState = set_state.bind(this);

    ////////////////////////// Util. ///////////////////////////
    ////////////////////////////////////////////////////////////

    this.logger = logger.bind(this);
    this.disable_scroll_onBody = disable_scroll_onBody.bind(this);
    this.disable_scroll_fully = disable_scroll_fully.bind(this);
    this.enable_scroll_onBody = enable_scroll_onBody.bind(this);
    this.enable_scroll_fully = enable_scroll_fully.bind(this);
    this.scrollTo_target = scrollTo_target.bind(this);
    this.preventDefault = preventDefault.bind(this);
    this.preventDefault_forScrollKeys = preventDefault_forScrollKeys.bind(this);

    /////////////////////// Init. f() //////////////////////////
    ////////////////////////////////////////////////////////////

    this.init_sweetScroll = init_sweetScroll.bind(this);
    this.init_scrollObserver = init_scrollObserver.bind(this);

    ///////////////////////// Modules //////////////////////////
    ////////////////////////////////////////////////////////////

    this.modules = {
      SweetScroll: { instance: this.init_sweetScroll() },
    };

    /////////////////////////// API ////////////////////////////
    ////////////////////////////////////////////////////////////

    this.api = {
      scroll_toTarget: (ARGS = { target: null, config: {} }) => this.scrollTo_target(ARGS?.target, ARGS?.config),
      scroll_toEndOfPage: () => this.modules.SweetScroll.instance.to(document.body.scrollHeight, { duration: 1000 }),
    };

    //////////////////// Eventbus listeners ////////////////////
    ////////////////////////////////////////////////////////////

    this.ebl_swup_page_load = ebh_swup_page_load.bind(this);
    this.ebl_swup_content_replace = ebh_swup_content_replace.bind(this);

    ////////////////// State-change listeners //////////////////
    ////////////////////////////////////////////////////////////

    this.stChH_mode = stChH_mode.bind(this);
    this.stChH_scrollPosition = stChH_scrollPosition.bind(this);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.logger("init", ["module"], "default", { inline: true });
    this.init_state();
    this.init_eventbus();
    this.init_scrollObserver();
    this.setState({ initialised: true, mode: "scrollEnabled" });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_state() {
    this.logger("init", ["state"], "default", { inline: true });
    this.setState({
      mode: "initialising",
      is_mobile: window.innerWidth <= 640,
      scrollPosition: window.scrollY,
      maxScrollPosition: document.body.scrollHeight - window.innerHeight,
    });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("swup_page_load", this.ebl_swup_page_load);
    eventbus.on("swup_content_replace", this.ebl_swup_content_replace);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("WindowScroll.api.scroll_toTarget", this.api.scroll_toTarget);
    eventbus.on("WindowScroll.api.scroll_toEndOfPage", this.api.scroll_toEndOfPage);
  }

  ////////////////////////////// State management //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  get state() {
    return this._state;
  }

  set state(state) {
    this.logger("info", ["You should not change state manually. Use setState instead."], "warning");
    this._state = state;
  }

  stateChange(CHANGES) {
    if ("mode" in CHANGES) this.stChH_mode(CHANGES);
    if ("scrollPosition" in CHANGES) this.stChH_scrollPosition(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
