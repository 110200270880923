// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function check_el_isVisible(EL) {
  // Check if el. is in doc...
  if (!document.body.contains(EL)) return false;

  // Check for `display: none` in ancestor chain...
  function has_displayNone(el) {
    while (el) {
      if (getComputedStyle(el).display === "none") return true;
      el = el.parentElement;
    }
    return false;
  }
  if (has_displayNone(EL)) return false;

  // Extra: Check if el. is off-screen
  const rect = EL.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const is_offScreen = rect.bottom < 0 || rect.top > windowHeight || rect.right < 0 || rect.left > windowWidth;
  if (is_offScreen) return true; // ← consider el. off-screen as visible

  // Extra: Check if el. is hidden by `visibility: hidden`
  const has_visibilityHidden = getComputedStyle(EL).visibility === "hidden";
  return !has_visibilityHidden;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
