// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_btnBorderSVGCornerRadius(BTNEL) {
  if (!BTNEL) return;
  const borderSVG = BTNEL.querySelector("[data-ref='borderSVG']");
  const bgSVG = BTNEL.querySelector("[data-ref='bgSVG']");
  if (!borderSVG && !bgSVG) return;

  // Adjust border SVG corner radius...
  let cornerRadius = 0;
  if (borderSVG) {
    const borderShape = borderSVG.querySelector("rect");
    const { width, height } = BTNEL.getBoundingClientRect();
    cornerRadius = Math.min(width, height) * 0.5;
    borderShape.setAttribute("rx", cornerRadius);
    borderShape.setAttribute("ry", cornerRadius);
    borderShape.setAttribute("data-rx-init", cornerRadius);
    borderShape.setAttribute("data-ry-init", cornerRadius);
  }

  // Adjust backgr. SVG corner radius...
  if (bgSVG) {
    const bgShape = bgSVG.querySelector("rect");
    bgShape.setAttribute("rx", cornerRadius);
    bgShape.setAttribute("ry", cornerRadius);
    bgShape.setAttribute("data-rx-init", cornerRadius);
    bgShape.setAttribute("data-ry-init", cornerRadius);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
