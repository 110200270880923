// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_openInfoOverlayBtn_click", inline: true, disabled: false };
const msgs = {
  eventStart: ["open-info-overlay-btn was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_openInfoOverlayBtn_click() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.eventStart, "event", logArgs);

  //////////////////// Info overlay is-hidden toggle /////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { infoOverlayState } = this.state;
  const infoOverlayState_new = { ...infoOverlayState, is_hidden: !infoOverlayState.is_hidden };
  this.setState({ infoOverlayState: infoOverlayState_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
