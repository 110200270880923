// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.expand_cardBg", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "expand_cardBg"],
  end: ["DragCards", "API", "expand_cardBg", "API call complete"],
  no_cardEl: ["DragCards", "API", "expand_cardBg", "no valid card el. provided"],
  no_bgSVG: ["DragCards", "API", "expand_cardBg", "no valid card bg. SVG found"],
  no_elToExpand: ["DragCards", "API", "expand_cardBg", "no valid SVG el. to expand found"],
};
const animDuration = 0.6;

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function expand_cardBg(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.logger("error", msgs.no_cardEl, "error", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - Get card background SVG...
  // - Extract background shape data...
  const bgSVG = CARDEL.querySelector("[data-role='cardBgShape']");
  if (!bgSVG) return this.logger("error", msgs.no_bgSVG, "error", logArgs);
  const bgShape = CARDEL.dataset.bgShape;

  // - Get the SVG el. to expand...
  const el_toExpand = bgSVG.querySelector(bgShape == "angular" ? "polygon" : "ellipse");
  if (!el_toExpand) return this.logger("error", msgs.no_elToExpand, "error", logArgs);

  // Expand background el...
  switch (bgShape) {
    case "angular":
      const points_expanded = el_toExpand.dataset.pointsExpanded;
      gsap.to(el_toExpand, { duration: 0.4, attr: { points: points_expanded } });
      break;
    case "ellipse":
      const clipPathToExpand = bgSVG.getElementById("ellipseClip");
      const ellipseEl = clipPathToExpand.querySelector("ellipse");
      gsap.to(ellipseEl, { attr: { rx: 100, ry: 100 }, duration: animDuration });
      break;
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
