// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_cardCommentBtn_click from "../eventHandlers/eh_cardCommentBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_cardCommentBtn", inline: true, disabled: false };
const msgs = {
  start: ["init. cardCommentBtn"],
  end: ["init. cardCommentBtn", "init. success"],
  no_el: ["init. cardCommentBtn", "no valid DOM element(s) provided"],
  no_commentBtn: ["init. cardCommentBtn", "no valid commentBtn el. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_cardCommentBtn(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Event hdl. //
  const commentBtn = CARDEL.querySelector("[data-ref='commentBtn']");
  if (!this.validate_refEl(commentBtn)) return this.cancel_featInit(msgs.no_commentBtn);
  commentBtn.addEventListener("click", eh_cardCommentBtn_click.bind(this, CARDEL));

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
