// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_canvas_mouseMove", inline: true };
const msgs = {
  start: ["mouse is moving on canvas"],
  no_fabricInst: ["no fabric inst. available"],
  no_cursorEl: ["no cursor el. found"],
};

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_canvas_mouseMove(ARGS = { fabricInst: null }, EVENTDATA) {
  // Stop, if no pointer events...
  const globalState = localStorage.getItem("globalState");
  const { features } = JSON.parse(globalState);
  if (!features?.has_pointerEvents) return;

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { fabricInst } = ARGS;
  const { drawingCanvasWrapper, drawingCursor } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!fabricInst) return this.logger("warning", msgs.no_fabricInst, "warning", logArgs);
  if (!this.validate_refEl(drawingCursor)) return this.logger("warning", msgs.no_cursorEl, "warning", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Updt. cursor position...
  const wrapperRect = drawingCanvasWrapper.getBoundingClientRect();
  const canvasRect = fabricInst.getElement().getBoundingClientRect();
  const dist_canvasTop_wrapperTop = canvasRect.top - wrapperRect.top;
  const pointer = fabricInst.getPointer(EVENTDATA.e);
  const offset = fabricInst.freeDrawingBrush.width / 2;
  drawingCursor.style.left = `${pointer.x - offset}px`;
  drawingCursor.style.top = `${pointer.y + dist_canvasTop_wrapperTop - offset}px`;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
