// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";
import compare_stateArrays from "../../../../../app/baseUtilities/compare/compare_stateArrays";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.processSteps_updtInstructCards_writingGame", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "processSteps_updtInstructCards_writingGame"],
  end: ["PartiForm", "API", "processSteps_updtInstructCards_writingGame", "API call complete"],
  no_stepString: () => ["PartiForm", "API", "processSteps_updtInstructCards_writingGame", "No step string provided."],
  stepNotAllowed: (step) => ["PartiForm", "API", "processSteps_updtInstructCards_writingGame", `step not allowed: ${step}`],
  writingGameDisabled: [
    "PartiForm",
    "API",
    "processSteps_updtInstructCards_writingGame",
    "Writing Game is disabled.",
    "stopping step process",
  ],
};

const possibleSteps = ["startWritingGame", "startWriting", "submitWords", "writingGameSubmitted", "viewWords"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function processSteps_updtInstructCards_writingGame(
  DATA = {
    step: null,
  }
) {
  if (!valid_str(DATA?.step)) return this.logger("error", msgs.no_stepString(), "error", logArgs);
  if (!possibleSteps.includes(DATA?.step)) return this.logger("error", msgs.stepNotAllowed(DATA?.step), "error", logArgs);
  if (!this.options.with_writingGame) return this.logger("warning", msgs.writingGameDisabled, "warning", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { step } = DATA;
  const { instructCardStates } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const instructCardStates_new = instructCardStates.map((cS) => {
    const { sectionId, stepId } = cS;
    let is_hidden = cS.is_hidden;
    let is_active = cS.is_active;
    switch (true) {
      ////////////// Case: does not belong to "writing" section //////////////
      ////////////////////////////////////////////////////////////////////////

      case sectionId != "writing":
        is_hidden = true;
        is_active = false;
        break;

      ////////////////// Case: belongs to "writing" section //////////////////
      ////////////////////////////////////////////////////////////////////////

      case sectionId == "writing":
        switch (true) {
          ////// Case: Card corresp. to curr. step ///////
          ////////////////////////////////////////////////

          case stepId == step:
            is_hidden = false; // ← show card
            is_active = true; // ← set active (reveals card text)
            break;

          // Case: Card does not corresp. to curr. step //
          ////////////////////////////////////////////////

          case stepId != step:
            is_active = false; // ← set inactive (hides card text)
            break;

          // DEPRECATED //
          // // Case: step index is equal or less than curr. step index //
          // /////////////////////////////////////////////////////////////

          // case cardStepIndex <= currStepIndex:
          //   is_hidden = false;
          //   is_active = false;
          //   break;
          // DEPRECATED //
        }

      ///////////////////////////// Default case /////////////////////////////
      ////////////////////////////////////////////////////////////////////////

      default:
        break;
    }

    return { ...cS, is_hidden, is_active };
  });

  // Conditional state updt. //
  const instructCardStates_haveChanged = compare_stateArrays(instructCardStates, instructCardStates_new);
  if (instructCardStates_haveChanged) this.setState({ instructCardStates: instructCardStates_new });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
