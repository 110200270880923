// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_sectionGroupStates", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`section group states:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_sectionGroupStates(CHANGES) {
  if (!("sectionGroupStates" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { sectionGroupStates } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(sectionGroupStates), "default", { ...logArgs, inline: false });

  //////////////////// Section group visibility updt. ////////////////////
  ////////////////////////////////////////////////////////////////////////

  sectionGroupStates?.forEach((s) => {
    const { el, is_hidden } = s;
    if (el) el.setAttribute("data-is-hidden", is_hidden);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
