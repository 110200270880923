// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_windowScroll_stCh_scrollPosition", inline: true, disabled: false };
const msgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_windowScroll_stCh_scrollPosition(ARGS) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  ////////////////////////////////// Active-group display updt. //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { listItemGroups, activeGroupDisplay } = this.ref;

  // Determ. which group is closest to center of viewport...
  const center_vp = window.innerHeight / 2;
  const groupDistances_toVpCenter = listItemGroups.map((g) => {
    const groupRect = g.getBoundingClientRect();
    const t_group = groupRect.top;
    const b_group = groupRect.bottom;
    const center_group = t_group + (b_group - t_group) / 2;
    return { groupEl: g, distance: Math.abs(center_vp - center_group) };
  });
  const closestGroup = groupDistances_toVpCenter.reduce((a, b) => (a.distance < b.distance ? a : b)).groupEl;

  // Updt. active-group display to ID of closest group el...
  const activeGroupTitle = closestGroup.getAttribute("data-title");
  const activeGroupTitleEl = activeGroupDisplay.querySelector("[data-ref='activeGroupTitle']");
  if (activeGroupTitleEl) activeGroupTitleEl.textContent = activeGroupTitle;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
