// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_listItem_mouseEnter", inline: true };
const loggerMsgs = {
  eventStart: ["mouse entered list item"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_listItem_mouseEnter(ITEMEL) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // - Updt. hovered-list-item state...
  const itemID = ITEMEL.dataset.id;
  this.setState({ hoveredListItem: { id: itemID } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
