// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.scrollTo_sectionGroup", inline: true };
const msgs = {
  eventStart: ["PartiForm", "API", "scrollTo_sectionGroup"],
  eventEnd: ["PartiForm", "API", "scrollTo_sectionGroup", "API call complete"],
  noSectionGroupID: ["PartiForm", "API", "show_section", "no section group ID provided"],
  noFullpageInst: ["PartiForm", "API", "show_section", "no fullpage inst. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function scrollTo_sectionGroup(SECTIONGROUPID, CALLBACK = null) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const fullpageInst = this.modules.fullpage.instance;
  this.logger("event", msgs.eventStart, "event", logArgs);

  // Guard...
  if (!valid_str(SECTIONGROUPID)) return this.logger("error", msgs.noSectionID, "error", logArgs);
  if (!fullpageInst) return this.logger("error", msgs.noFullpageInst, "error", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Scroll to section group...
  fullpageInst.moveTo(SECTIONGROUPID);

  // Callback...
  if (CALLBACK) CALLBACK();

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.eventEnd, "event", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
