// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.submit", inline: true };
const loggerMsgs = {
  eventStart: ["DragCards", "API", "submit_contactForm"],
  noSubmData: "submit_contactForm(DATA): No data provided.",
  noMessage: "submit_contactForm(DATA): No message found in subm. data.",
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function submit(SUBMITDATA = { message: null }) {
  return new Promise((resolve, reject) => {
    if (!SUBMITDATA || SUBMITDATA == undefined) reject(loggerMsgs.noSubmData);
    if (!SUBMITDATA.message || SUBMITDATA.message == undefined) reject(loggerMsgs.noMessage);

    // Setup...
    this.logger("event", loggerMsgs.eventStart, "event", logArgs);

    // Set up backend request...
    const req = new XMLHttpRequest();
    const data = new FormData();
    data.append("message", SUBMITDATA.message);

    // Init. + send request...
    req.open("POST", "/api.contact-form.submit", true);
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) console.log("%cContact form submitted.", consoleStyles.success);
      if (req.status !== 200) {
        console.log("%cContact form submission failed.", consoleStyles.error);
        reject("submit_contactForm(): Contact form submission failed.");
      }
      resolve(req.responseText);
    };
    req.send(data);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
