// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_animCardBorder", inline: true };
const loggerMsgs = {
  initStart: ["init. animCardBorder"],
  initSuccess: ["init. animCardBorder", "init. success"],
  noCardEl: ["init. animCardBorder", "no card element provided"],
  noBorderPath: ["init. animCardBorder", "no border path found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_animCardBorder(CARDEL) {
  // Setup...
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(loggerMsgs.noCardEl);

  // Measure border path...
  const borderPath = CARDEL.querySelector("[data-role='cardBorderPath']");
  if (!borderPath) return this.cancel_featInit(loggerMsgs.noBorderPath);
  const borderPathLength = borderPath.getTotalLength();

  // Set card el.’s --border-anim-dash-offset CSS var...
  CARDEL.style.setProperty("--border-anim-dash-offset", borderPathLength);

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
