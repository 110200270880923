// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEl from "../../../../baseUtilities/validate/validate_refEl";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function scrollTo_target(
  TARGET,
  CONFIG = {
    topOffset: (window.innerHeight / 2) * 0.8,
    duration: 800,
  }
) {
  if (!validate_refEl(TARGET)) return;

  // Setup...
  const { topOffset = (window.innerHeight / 2) * 0.8, duration = 800 } = CONFIG;
  const { instance: sweetScrollInstance } = this.modules.SweetScroll;

  // Determine target position (scroll top of target to ~ center of viewport)...
  const targetPosition = TARGET.offsetTop - topOffset;

  // Scroll to target...
  sweetScrollInstance.to(targetPosition, { easing: "easeOutExpo", duration });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
