// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "scrollToSubsectionBtns", inline: true, disabled: false };
const msgs = {
  initStart: ["init. scrollToSubsectionBtns"],
  initSuccess: ["init. scrollToSubsectionBtns", "init. success"],
  noValidDOMEl: ["init. scrollToSubsectionBtns", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_scrollToSubsectionBtns() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { scrollToSubsectionBtns } = this.ref;
  this.logger("init", msgs.initStart, "action", logArgs);

  // Guard...
  if (scrollToSubsectionBtns === undefined) return this.cancel_featInit(msgs.noValidDOMEl);
  if (scrollToSubsectionBtns.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.noValidDOMEl);

  ////////////////////////////// Event hdl. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Scroll to subsection on click //
  scrollToSubsectionBtns.forEach((btn, i) => {
    const targetSubsectionId = btn.dataset.id;
    btn.addEventListener("click", () => {
      const targetSubSectSelector = `[data-ref="${targetSubsectionId}"]`;
      console.log("targetSubSectSelector:", targetSubSectSelector);
      this.api.scrollTo_subsection(targetSubSectSelector, { scrollTo_bottom: true });
    });
  });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
