// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.filter_cards", inline: true };
const msgs = {
  start: ["DragCards", "API", "filter_cards"],
  in_initMode: ["DragCards", "API", "filter_cards", "in init mode", "stopping API hdl. execution"],
  no_filterId: ["DragCards", "API", "filter_cards", "no filter ID provided", "stopping API hdl. execution"],
  no_cardStates: ["DragCards", "API", "filter_cards", "no card states found", "stopping API hdl. execution"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function filter_cards(FILTERIDS = ["all"]) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { mode, cardStates } = this.state;
  this.logger("event", msgs.start, "event", logArgs);
  if (mode === "init") return this.logger("warning", msgs.in_initMode, "warning", logArgs);

  // Guard...
  if (!FILTERIDS) return this.logger("warning", msgs.no_filterId, "warning", logArgs);
  if (!cardStates) return this.logger("warning", msgs.no_cardStates, "warning", logArgs);

  //////////////////////////////////////// Card filtering ////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const newCardStates = cardStates.map((cardState) => {
    // If no filter selected (i.e. only "all" filter), show all cards...
    const noFilterSelected = FILTERIDS.includes("all") && FILTERIDS.length === 1;
    if (noFilterSelected) return { ...cardState, state: { ...cardState.state, is_hidden: false } };

    // Get tags of card (to match against filters)...
    const cardTags = cardState?.config?.tags;

    // If no tags found, hide card...
    if (!cardTags) return { ...cardState, state: { ...cardState.state, is_hidden: true } };

    // Match card tags against filter IDs...
    return { ...cardState, state: { ...cardState.state, is_hidden: !FILTERIDS.some((filterID) => cardTags.includes(filterID)) } };
  });
  console.log("newCardStates", newCardStates);
  this.setState({ cardStates: newCardStates });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
