// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getHeight } from "mezr";
import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import get_elementTransform from "../../../../../app/baseUtilities/get/get_elementTransform";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_mouseMove", inline: true, disabled: false };
const msgs = {
  start: ["mouse moved on comp. element"],
  stop_blockedStep: ["PartiForm", "mouseMove", "blocked step", "stopped event propagation"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_mouseMove(EVENTDATA) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { clientY: mouseY } = EVENTDATA;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Guard: stop event propagation if not enabled for current step...
  const enabledSteps = ["intro", "startAnsweringMatrix", "viewMatrixAnswerGraph"];
  if (!enabledSteps.includes(this.state.currentStep)) return this.logger("warning", msgs.stop_blockedStep, "warning", logArgs);

  // - Check if page header is hidden offscreen...
  // - Get page header height...
  // - Check if already transformed offscreen...
  // - Show page header when within upper 15% of the viewport...
  // - Hide page header when outside upper 15% of the viewport
  const pageHeaderEl = document.querySelector("[g-component='SubpageHeader']");
  const h_pageHeader = Math.ceil(getHeight(pageHeaderEl));
  const transformValues = get_elementTransform(pageHeaderEl);
  const ty = Math.ceil(transformValues.ty);
  const is_hidden = pageHeaderEl.dataset.hiddenOffscreen === "true";
  const is_transformedOffscreen = ty === h_pageHeader * -1;

  if (mouseY < window.innerHeight * 0.15) {
    if (is_hidden) {
      gsap.to(pageHeaderEl, { y: 0, duration: 0.4 });
    }
  } else {
    if (is_hidden && !is_transformedOffscreen) {
      gsap.to(pageHeaderEl, { y: h_pageHeader * -1, duration: 0.4 });
    }
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
