// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_bgIllus", inline: true };
const loggerMsgs = {
  initStart: ["init. bgIllus"],
  initSuccess: ["init. bgIllus", "init. success"],
  noValidDOMEl: ["init. bgIllus", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_bgIllus() {
  // Setup...
  const { bgIllus } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (bgIllus === undefined) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (bgIllus.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Set init. backgr. illu. states...
  const bgIlluStates = bgIllus.map((el) => ({
    el,
    id: el.dataset.id,
    is_hidden: el.dataset.isHidden === "true",
  }));
  this.setState({ bgIlluStates });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
