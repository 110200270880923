// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getHeight } from "mezr";
import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.hide_pageHeader", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "hide_pageHeader"],
  end: ["PartiForm", "API", "hide_pageHeader", "API call complete"],
  no_headerEl: ["PartiForm", "API", "hide_pageHeader", "No pageHeader el. found"],
  already_hidden: ["PartiForm", "API", "hide_pageHeader", "PageHeader already hidden"],
};

// ——————————————————————————————————————— API METHOD DEF. ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function hide_pageHeader() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const pageHeaderEl = document.querySelector("[g-component='SubpageHeader']");
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!pageHeaderEl) return this.logger("error", msgs.no_headerEl, "error", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Get page header height...
  const pageHeaderHeight = Math.ceil(getHeight(pageHeaderEl));

  // Guard: check if already hidden...
  const pageHeaderTransform = pageHeaderEl.style.transform;
  const transformAmount = pageHeaderTransform.match(/translateY\(([^)]+)\)/);
  const transformAmountInt = transformAmount ? Math.ceil(parseInt(transformAmount[1])) : 0;
  if (transformAmountInt === -pageHeaderHeight) return this.logger("warning", msgs.already_hidden, "warning", logArgs);

  // Hide page header by moving it off-screen on y-axis...
  gsap.to(pageHeaderEl, {
    y: pageHeaderHeight * -1,
    duration: 0.5,
    onComplete: () => pageHeaderEl.setAttribute("data-hidden-offscreen", true),
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
