// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_writingGameWordCard_click", inline: true };
const msgs = {
  start: ["writing game word card has been clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_writingGameWordCard_click(WORDCARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { config_writingGame } = this.options;
  const { textpartsperuser: textPartsPerUser } = config_writingGame;
  this.logger("event", msgs.start, "event", logArgs);

  ////////////////////// Selected-words state updt. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Add word to selectedWords state...
  const selectedWordsState = this.state.selectedWords;
  const clickedWord = WORDCARDEL.dataset.word;
  const clickedWordCatId = WORDCARDEL.dataset.catId;
  const selectedWordsState_new = selectedWordsState.map((wordObj) => {
    if (wordObj.catId === clickedWordCatId) return { ...wordObj, word: clickedWord };
    return wordObj;
  });
  this.setState({ selectedWords: selectedWordsState_new });

  //////////////////// Curr. word category state updt. ///////////////////
  ////////////////////////////////////////////////////////////////////////

  // If multiple text parts per user:
  // - Select next word category with no word selected...
  // - If all word categories have a word selected, set currentWordCat to 'null'
  if (textPartsPerUser == "all") {
    const has_remainingWordCatsToSelect = selectedWordsState_new.some((wordStateObj) => wordStateObj.word === null);
    let nextWordCatId = has_remainingWordCatsToSelect
      ? selectedWordsState_new.find((wordStateObj) => wordStateObj.word === null).catId
      : "null";
    this.setState({ currentWordCat: nextWordCatId });

    // If all words have been selected:
    // - Hide word cat. instruct.-cards...
    // - Wait for hide-anim. to finish...
    // - set currentStep to 'submitWords'...
    if (!has_remainingWordCatsToSelect) {
      const { instructCards_wordCat } = this.ref;
      gsap.to(instructCards_wordCat, {
        delay: 0,
        x: "-120%",
        duration: 0.5,
        stagger: 0.05,
        ease: "power2.inOut",
        immediateRender: true,
        onComplete: () => {
          setTimeout(() => {
            instructCards_wordCat.forEach((cardEl) => (cardEl.style.display = "none"));
            this.setState({ currentStep: "submitWords" });
          }, 250);
        },
      });
    }
  }

  // If only one text parts per user:
  // - Hide word cat. instruct.-cards...
  // - Set current word cat. to 'null'...
  // - Move to next step "submitWords"...
  else {
    this.setState({ currentWordCat: "null" });

    const { instructCards_wordCat } = this.ref;
    const visibleInstructCards = instructCards_wordCat.filter((cardEl) => cardEl.dataset.isHidden === "false");
    gsap.fromTo(
      visibleInstructCards,
      { x: "0%" },
      {
        delay: 0,
        x: "-120%",
        duration: 0.5,
        stagger: 0.05,
        ease: "power2.inOut",
        immediateRender: true,
        onComplete: () => {
          setTimeout(() => {
            instructCards_wordCat.forEach((cardEl) => (cardEl.style.display = "none"));
            this.setState({ currentStep: "submitWords" });
          }, 250);
        },
      }
    );
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
