// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.submit_writingGame", inline: true };
const msgs = {
  start: ["PartiForm", "API", "submit_writingGame"],
  end: ["PartiForm", "API", "submit_writingGame", "API call complete"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function submit_writingGame() {
  return new Promise(async (resolve, reject) => {
    //////////////////////////////////////////// Setup /////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    const { selectedWords } = this.state;
    const { participationPageId, with_consentCheck } = this.options;
    this.logger("event", msgs.start, "event", logArgs);

    //////////////////////////////// Writing game words submission /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // Setup...
    const submitIndex_curr = await this.api.fetch_currWritingSubmitIndex();
    const submitIndex_new = submitIndex_curr + 1;
    console.log("%cSubmitting parti. form writing game words...", consoleStyles.default);

    // Set up backend request...
    const req = new XMLHttpRequest();
    const data = new FormData();
    data.append("partiPageID", participationPageId);
    data.append("submitIndex", submitIndex_new);
    data.append("selectedWords", JSON.stringify(selectedWords));

    // Add consent check data (if enabled)...
    if (with_consentCheck) {
      const { userConsent } = this.state;
      if (userConsent) data.append("userConsentData", JSON.stringify(userConsent));
    }

    // Init. + send request...
    req.open("POST", "/api.parti-form.submit.writing", true);
    req.onreadystatechange = () => {
      ///////////////////////////
      // Successful subm. hdl. //
      ///////////////////////////

      if (req.readyState === 4 && req.status === 200) {
        console.log("%cParti. form writing game words submitted.", consoleStyles.success);
        resolve(req.responseText);
      }

      ///////////////////////
      // Failed subm. hdl. //
      ///////////////////////

      if (req.status !== 200) {
        console.log("%cParti. form writing game words submission failed.", consoleStyles.error);
        reject(req.responseText);
      }
    };
    req.send(data);

    /////////////////////////////////////////// Conclude ///////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    this.logger("success", msgs.end, "success", logArgs);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
