// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.updt_mobileInstructCard", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "updt_mobileInstructCard"],
  end: ["PartiForm", "API", "updt_mobileInstructCard", "API call complete"],
};
const defConfig = {
  hide: false,
  show: false,
  text: null,
  step: null,
  color_bg: null,
  color_text: null,
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_mobileInstructCard(CONFIG = defConfig) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const config = { ...defConfig, ...CONFIG };
  const { mobileInstructCardTexts } = this.options;
  this.logger("event", msgs.start, "event", logArgs);

  /////////////////////////////// Mob. instruct. card state updt. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { mobileInstructCardState } = this.state;
  const mobileInstructCardState_new = { ...mobileInstructCardState };

  //////////////// Text updt. (directly from text prop.) /////////////////
  ////////////////////////////////////////////////////////////////////////

  const { text } = config;
  if (valid_str(text)) mobileInstructCardState_new.text = text;

  ///////////////////// Text updt. (from step prop.) /////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { step } = config;
  if (step) {
    const text_matchingStep = mobileInstructCardTexts.find((t) => t.stepId === step)?.text;
    if (valid_str(text_matchingStep)) {
      mobileInstructCardState_new.text = text_matchingStep;
      mobileInstructCardState_new.is_hidden = false; // ← show card if text corresp. to step found
    } else mobileInstructCardState_new.is_hidden = true; // ← hide card if no text corresp. to step found
  }

  ///////////////////// is_hidden updt. (hide/show) //////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { hide, show } = config;
  if (hide) mobileInstructCardState_new.is_hidden = true;
  if (show) mobileInstructCardState_new.is_hidden = false;

  ///////////////////// Color updt. (hide/show) //////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { color_bg, color_text } = config;
  if (valid_str(color_bg)) mobileInstructCardState_new.color_bg = color_bg;
  if (valid_str(color_text)) mobileInstructCardState_new.color_text = color_text;

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Updt. state...
  this.setState({ mobileInstructCardState: mobileInstructCardState_new });

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
