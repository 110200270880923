// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.show_sectionGroup", inline: true };
const loggerMsgs = {
  eventStart: ["PartiForm", "API", "show_sectionGroup"],
  eventEnd: ["PartiForm", "API", "show_sectionGroup", "API call complete"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_sectionGroup(SECTIONGROUPID) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const fullpageInst = this.modules.fullpage.instance;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Set section group states w/ updt. is_hidden values...
  const sectionGroupStates = this.state.sectionGroupStates || [];
  const sectionGroupStates_new = sectionGroupStates.map((state) => {
    const { id } = state;
    const is_hidden = id === SECTIONGROUPID ? false : state.is_hidden;
    return { ...state, is_hidden };
  });
  this.setState({ sectionGroupStates: sectionGroupStates_new });

  // Re-render + re-build fullpage.js inst...
  if (fullpageInst) {
    fullpageInst.render();
    fullpageInst.reBuild();
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", loggerMsgs.eventEnd, "event", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
