// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function get_cardOpts(CARDEL) {
  const cardOptions = CARDEL.getAttribute("g-options");
  return JSON.parse(cardOptions);
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.updt_cardPos_toBeRelativeToVp", inline: true };
const loggerMsgs = {
  eventStart: ["CardArrangement", "API", "updt_cardPos_toBeRelativeToVp"],
  noCard: ["No card elements found"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_cardPos_toBeRelativeToVp() {
  // Setup...
  const { cards } = this.ref;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (cards === undefined) return this.logger("error", loggerMsgs.noCard, "error", logArgs);
  if (cards.every((el) => !this.validate_refEl(el))) return this.logger("error", loggerMsgs.noCard, "error", logArgs);

  // Update card positions...
  cards.forEach((cardEl) => {
    // Check if card is to be kept pos. relative to viewport bottom...
    const cardOpts = get_cardOpts(cardEl);
    const { posRelativeTo_vpBottom } = cardOpts ?? { posRelativeTo_vpBottom: false };

    // Adjust card pos...
    if (posRelativeTo_vpBottom) {
      const cardRect = cardEl.getBoundingClientRect();
      const cardHeight = cardRect.height;
      const vpBottom = window.innerHeight;
      const cardPos = vpBottom - cardHeight;
      gsap.to(cardEl, { y: `${cardPos}px`, duration: 0.5, ease: "power2.out" });
    }
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
