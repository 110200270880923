// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function validate_infoText(TEXT) {
  if (TEXT === undefined || !TEXT || typeof TEXT !== "string" || TEXT.length === 0) return false;
  return true;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_infoDisplayState", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`info display state:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_infoDisplayState(CHANGES) {
  if (!("infoDisplayState" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { infoDisplayState } = CHANGES;
  const { infoDisplay } = this.ref;
  this.logger("state-change", msgs.stateChange(infoDisplayState), "default", { ...logArgs, inline: false });

  ///////////////////////////////////// Info display updt. ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////// Info display context updt. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("context" in infoDisplayState) {
    const { context } = infoDisplayState;
    const scrollContentWrapper = infoDisplay.querySelector("[data-ref=scrollContentWrapper]");
    if (scrollContentWrapper) scrollContentWrapper.setAttribute("data-context", context);
  }

  /////////////////////// Info display text updt. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("text" in infoDisplayState) {
    const { text } = infoDisplayState;
    const contentWrapper = infoDisplay.querySelector("[data-ref=contentWrapper]");
    if (!validate_infoText(text)) contentWrapper.innerHTML = "";
    else {
      const decodedText = decodeURIComponent(text.replace(/\\u/g, "%")); // ← decode unicode characters
      contentWrapper.innerHTML = decodedText;
    }
  }

  ///////////////////// Info display position updt. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("position" in infoDisplayState) {
    const { position } = infoDisplayState;
    const { x, y } = position;
    infoDisplay.style.transform = `translate(${x}px, ${y}px)`;
  }

  ///////////////////// Info display is-hidden updt. /////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_hidden" in infoDisplayState) {
    const is_hidden = infoDisplayState.is_hidden ?? true;
    infoDisplay.setAttribute("data-is-hidden", is_hidden);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
