// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { fabric } from "fabric";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_selectedDrawTool", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`selected drawing tool: ${CHANGE}`],
  no_change: () => ["No change to state provided."],
  inval_stateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = ["pencil", "spraycan", "shadowBrush", "none"];

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_selectedDrawTool(CHANGES) {
  if (!("selectedDrawTool" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.selectedDrawTool))
    return this.logger("error", msgs.inval_stateValue(CHANGES.selectedDrawTool), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { selectedDrawTool } = CHANGES;
  const { pencilToolBtn, spraycanToolBtn, shadowBrushToolBtn } = this.ref;
  const fabricInst = this.modules.fabric.instances[0];
  this.logger("state-change", msgs.stateChange(selectedDrawTool), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - If selected tool is 'spraycan', updt. fabric inst. brush...
  // - Updt. active attr. on spraycan-tool btn...
  if (selectedDrawTool === "spraycan") {
    fabricInst.freeDrawingBrush = new fabricInst.CustomSprayBrush(fabricInst);
  }
  spraycanToolBtn.setAttribute("data-is-active", selectedDrawTool === "spraycan");

  // - If selected tool is 'pencil', updt. fabric inst. brush...
  // - Updt. active attr. on pencil-tool btn...
  if (selectedDrawTool === "pencil") {
    fabricInst.freeDrawingBrush = new fabric.PencilBrush(fabricInst);
    fabricInst.freeDrawingBrush.width = 5;
  }
  pencilToolBtn.setAttribute("data-is-active", selectedDrawTool === "pencil");

  // - If selected tool is 'shadowBrush', updt. fabric inst. brush...
  // - Updt. active attr. on shadowBrush-tool btn...
  if (selectedDrawTool === "shadowBrush") {
    fabricInst.freeDrawingBrush = new fabric.PencilBrush(fabricInst);
    fabricInst.freeDrawingBrush.shadow = new fabric.Shadow({ blur: 12, offsetX: 0, offsetY: 0, color: "rgba(255,0,255,0.6)" });
    fabricInst.freeDrawingBrush.color = "#fff";
    fabricInst.freeDrawingBrush.width = 25;
  }
  shadowBrushToolBtn.setAttribute("data-is-active", selectedDrawTool === "shadowBrush");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
