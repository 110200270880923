// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_matrixMultipleChoiceOpt_change", inline: true, disabled: false };
const msgs = {
  eventStart: ["matrix multiple choice opt. has been changed"],
  updt_submitBtnPos: ["updt. submit btn. position"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_matrixMultipleChoiceOpt_change(QUESTIONEL, SUBMITBTNEL, EVENTDATA) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.eventStart, "event", logArgs);

  /////////////////////// Option select./deselect. ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - If selected, deselect other options in group...
  // - Updt. data-is-checked attr. on option wrapper el...
  const multipleChoiceOpts = QUESTIONEL.querySelectorAll('[data-ref="multipleChoiceOpt"]');
  multipleChoiceOpts.forEach((opt) => {
    const optWrapper = opt.closest('[data-ref="checkboxWrapper"]');
    if (opt !== EVENTDATA.target) opt.checked = false;
    optWrapper.setAttribute("data-is-checked", opt === EVENTDATA.target);
  });

  ////////////////////// Submit btn. position updt. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Move submit btn. below selected option...
  if (SUBMITBTNEL) {
    // Setup //
    this.logger("event", msgs.updt_submitBtnPos, "event", logArgs);
    const selectedOptWrapperEl = QUESTIONEL.querySelector('[data-is-checked="true"]');
    const selectedOptWrapperRect = selectedOptWrapperEl.getBoundingClientRect();
    const selectedOptWrapperBottom = selectedOptWrapperRect.bottom;
    const btnRect = SUBMITBTNEL.getBoundingClientRect();

    // Measure dist. between selected option and submit btn...
    const dist_optToBtn_vertical = selectedOptWrapperBottom - btnRect.top;
    const dist_optToBtn_horizontal = selectedOptWrapperRect.right - btnRect.left - btnRect.width;

    // Move btn...
    gsap.set(SUBMITBTNEL, {
      x: `+=${dist_optToBtn_horizontal}`,
      y: `+=${dist_optToBtn_vertical}`,
      onComplete: () => SUBMITBTNEL.setAttribute("data-is-hidden", "false"),
    });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
