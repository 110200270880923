// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.fetch_matrixAnswerData", inline: true };
const msgs = {
  start: ["PartiForm", "API", "fetch_matrixAnswerData"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function fetch_matrixAnswerData() {
  return new Promise((resolve, reject) => {
    //////////////////////////////// Setup /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.logger("event", msgs.start, "event", logArgs);

    ///////////////////////////// Data fetch. //////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // Fetch answer data from page’s JSON route...
    const { participationPageId } = this.options;
    const url = `/${participationPageId}.json`;
    try {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          // Guard...
          if (!data) return reject("No data found on page JSON route.");

          // Resolve...
          resolve(data);
        })
        .catch((err) => {
          // Guard...
          if (err) return reject(err);

          // Resolve...
          resolve([]);
        });
    } catch (error) {
      reject(error);
    }
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
