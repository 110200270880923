// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_openInfoOverlayBtn_click from "../eventHandlers/eh_openInfoOverlayBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_infoOverlay", inline: true, disabled: false };
const msgs = {
  initStart: ["init. infoOverlay"],
  initSuccess: ["init. infoOverlay", "init. success"],
  noValidDOMEl: ["init. infoOverlay", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_infoOverlay() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { infoOverlay, openInfoOverlayBtn } = this.ref;
  const elements = [infoOverlay, openInfoOverlayBtn];
  this.logger("init", msgs.initStart, "action", logArgs);

  // Guard...
  if (elements.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.noValidDOMEl);

  /////////////////////// Info Overlay state init. ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  const infoOverlayState = { is_hidden: true };
  this.setState({ infoOverlayState });

  ////////////////////////////// Event hdl. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  openInfoOverlayBtn.addEventListener("click", eh_openInfoOverlayBtn_click.bind(this));

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
