// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_searchBar_click from "../eventHandlers/eh_searchBar_click.js";
import eh_searchForm_submit from "../eventHandlers/eh_searchForm_submit.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_searchBar", inline: true, disabled: false };
const msgs = {
  start: ["init. searchBar"],
  end: ["init. searchBar", "init. success"],
  noValidDOMEl: ["init. searchBar", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_searchBar() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { searchBar } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(searchBar)) return this.cancel_featInit(msgs.noValidDOMEl);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const searchForm = searchBar.querySelector("[data-ref='searchForm']");

  // Event hdl. //
  searchBar.addEventListener("click", eh_searchBar_click.bind(this, searchBar));
  searchForm.addEventListener("submit", eh_searchForm_submit.bind(this, searchBar));

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
