// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function calc_maxHeadlineScale(HEADLINEEL, WINDOWWIDTH = window.innerWidth) {
  if (!HEADLINEEL) return console.warn("calc_maxHeadlineScale(): no headline el. provided.");
  const headlineElWidth = HEADLINEEL.offsetWidth; // ← use offsetWidth instead of getBoundingClientRect().width
  return WINDOWWIDTH / headlineElWidth;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_headlineStrech", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`headline stretch: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// const possibleStateValues = ["foo", "bar"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_headlineStrech(CHANGES) {
  if (!("headlineStrech" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { headlineStrech } = CHANGES;
  const { headline } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(headlineStrech), "default", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Updt. comp. headline stretch CSS var...
  const minHeadlineScale = 1;
  const maxHeadlineScale = calc_maxHeadlineScale(headline);
  const scaleToUse = minHeadlineScale + (maxHeadlineScale - minHeadlineScale) * (headlineStrech / 100);
  this.element.style.setProperty("--headlineStretch", scaleToUse);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
