// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getHeight } from "mezr";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_measurements", inline: true, disabled: false };
const msgs = {
  start: ["init. measurements"],
  end: ["init. measurements", "init. success"],
  no_el: ["init. measurements", "no valid DOM el. provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_measurements() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const compEl = this.element;
  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Measure + set mobile section height var...
  const h_viewport = window.innerHeight;
  compEl.style.setProperty("--h-viewport", `${h_viewport}px`);

  // Measure + set subpage header height var...
  const subpageHeaderEl = document.querySelector("[g-component='SubpageHeader']");
  if (subpageHeaderEl) {
    const h_subpageHeader = getHeight(subpageHeaderEl);
    compEl.style.setProperty("--h-subpageHeader", `${h_subpageHeader}px`);
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
