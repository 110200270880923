// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeMonthFilters", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`activeMonthFilters:`, CHANGE],
  no_stateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeMonthFilters(CHANGES) {
  if (!("activeMonthFilters" in CHANGES)) return this.logger("error", msgs.no_stateChange(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { activeMonthFilters } = CHANGES;
  const { eventCards: evCards, activeMonthDisplay } = this.ref;
  this.logger("state-change", msgs.stateChange(activeMonthFilters), "default", { ...logArgs, inline: false });

  ///////////////////// Visible event card filtering /////////////////////
  ////////////////////////////////////////////////////////////////////////

  // If only active filter is 'all', show all event cards...
  const showAll = activeMonthFilters.length === 1 && activeMonthFilters.includes("all");
  if (showAll) evCards.forEach((el) => el.setAttribute("data-is-hidden", false));
  // Hide all event cards with month not included in activeMonthFilters...
  else evCards.forEach((el) => el.setAttribute("data-is-hidden", !activeMonthFilters.includes(el.dataset.monthFilter)));

  /////////////// Month-filter btns. is-active attr. updt ////////////////
  ////////////////////////////////////////////////////////////////////////

  const monthFilterBtns = activeMonthDisplay.querySelectorAll("[data-ref='monthFilterBtn']");
  monthFilterBtns.forEach((btn) => {
    const btnIsActive = activeMonthFilters.includes(btn.dataset.month);
    btn.setAttribute("data-is-active", btnIsActive);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
