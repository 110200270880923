// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_currentWordCat", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`curr. writing game word category: ${CHANGE}`],
  no_change: () => ["No change to state provided."],
};

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_currentWordCat(CHANGES) {
  if (!("currentWordCat" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { currentWordCat } = CHANGES;
  const { writingGameWordGroups, instructCards_wordCat } = this.ref;
  const selectedWordsState = this.state.selectedWords;
  const { is_mobile } = this.state;
  this.logger("state-change", msgs.stateChange(currentWordCat), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////// Word group hidden attr. updt. /////////////////////
  ////////////////////////////////////////////////////////////////////////

  writingGameWordGroups.forEach((wordGroupEl) => {
    const wordGroupIO = wordGroupEl.dataset.id;
    wordGroupEl.setAttribute("data-is-hidden", wordGroupIO !== currentWordCat);
  });

  ///////////////////// Mobile instruct. card updt. //////////////////////
  //////////////////////////// (mobile only) /////////////////////////////

  if (is_mobile) {
    const instructCardEl_curr = instructCards_wordCat.find((el) => el.dataset.catId === currentWordCat);
    if (instructCardEl_curr) {
      const cardText = instructCardEl_curr.dataset.text;
      if (valid_str(cardText))
        this.api.updt_mobileInstructCard({
          text: cardText,
          show: true,
          color_bg: "#fff",
          color_text: "var(--color-parti-bg)",
        });
    }
  }

  ///////////// Word cat. instruct.-card hidden attr. updt. //////////////
  ////////////////////////// (non-mobile only) ///////////////////////////
  else {
    instructCards_wordCat.forEach((cardEl) => {
      const cardCatID = cardEl.dataset.catId;
      const correspSelectedWord = selectedWordsState
        ? selectedWordsState.find((stateObj) => stateObj.catId === cardCatID).word
        : null;
      const correspWordHasBeenSelected = selectedWordsState
        ? correspSelectedWord != null && correspSelectedWord != undefined
        : false;
      const is_hidden = cardCatID !== currentWordCat && !correspWordHasBeenSelected;
      const hiddenAttr = cardEl.getAttribute("data-is-hidden");

      if (is_hidden) {
        if (hiddenAttr === "true") cardEl.style.display = "none"; // ← if already offscreen, fully hide
        else {
          gsap.fromTo(
            cardEl,
            { x: "0%" },
            { x: "-120%", duration: 0.5, onComplete: () => cardEl.setAttribute("data-is-hidden", true) }
          );
        }
      } else {
        if (hiddenAttr === "false") return; // ← stop if card already visible
        cardEl.style.display = "block"; // ← ensure card is visible
        gsap.fromTo(
          cardEl,
          { x: "-120%" },
          { x: "0%", duration: 0.5, onComplete: () => cardEl.setAttribute("data-is-hidden", false) }
        );
      }
    });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
