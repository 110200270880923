// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.contract_cardBorder", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "contract_cardBorder"],
  end: ["DragCards", "API", "contract_cardBorder", "API call complete"],
  no_cardEl: ["DragCards", "API", "contract_cardBorder", "no valid card el. provided"],
  no_borderSVG: ["DragCards", "API", "contract_cardBorder", "no valid card border SVG found"],
  no_borderPath: ["DragCards", "API", "contract_cardBorder", "no valid SVG path to contract found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function contract_cardBorder(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.logger("error", msgs.no_cardEl, "error", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - Get border SVG el...
  // - Get border path el. to contract...
  // - Extract border shape data...
  const borderSVG = CARDEL.querySelector("[data-role='cardBorderShape']");
  if (!borderSVG) return this.logger("error", msgs.no_borderSVG, "error", logArgs);
  const borderPathToExpand = borderSVG.querySelector("[data-role='cardBorderPath']");
  if (!borderPathToExpand) return this.logger("error", msgs.no_borderPath, "error", logArgs);
  const borderShape = CARDEL.dataset.bgShape;

  // Contract border path...
  switch (borderShape) {
    case "angular":
      const d_initial = elToCollapse.dataset.dInitial;
      gsap.to(elToCollapse, { duration: animDuration, attr: { d: d_initial }, ease: "power2.inOut" });
      break;
    case "ellipse":
      console.warn("Card border shape 'ellipse' not yet supported");
      break;
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
