// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.contract_cardClipPath", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "contract_cardClipPath"],
  end: ["DragCards", "API", "contract_cardClipPath", "API call complete"],
  no_cardEl: ["DragCards", "API", "contract_cardClipPath", "no valid card el. provided"],
  no_clippedContent: ["DragCards", "API", "contract_cardClipPath", "no valid clipped content el. found"],
  no_clipPathStyle: ["DragCards", "API", "contract_cardClipPath", "no valid clip path style found"],
};
const animDuration = 0.4;

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function contract_cardClipPath(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.logger("error", msgs.no_cardEl, "error", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Find clipped content el...
  const clippedContent = CARDEL.querySelector("[data-role='clippedCardContent']");
  if (!clippedContent) return this.logger("error", msgs.no_clippedContent, "error", logArgs);

  // Check if el. has clip path...
  const clipPath = clippedContent.style.clipPath;
  if (!clipPath) return this.logger("error", msgs.no_clipPathStyle, "error", logArgs);

  // Determ. clip path based on background shape...
  const bgShape = CARDEL.dataset.bgShape;
  const initialClipPath =
    bgShape == "angular"
      ? "polygon(40% 0%, 60% 0%, 90% 25%, 90% 75%, 60% 100%, 40% 100%, 10% 75%, 10% 25%)"
      : "ellipse(50% 50% at 50% 50%)";

  // Contract clip path...
  switch (bgShape) {
    case "angular":
      gsap.to(clippedContent, { clipPath: initialClipPath, duration: animDuration });
      break;
    case "ellipse":
      gsap.to(clippedContent, { clipPath: initialClipPath, duration: animDuration });
      break;
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
