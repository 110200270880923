// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function set_initCardPositions(CARDELS) {
  return new Promise((resolve, reject) => {
    if (!CARDELS || !CARDELS.length) return reject("No card elements provided.");

    // Setup...
    const pageContentWrapper = document.getElementById("pageContentWrapper");
    const { width: pageWidth, height: pageHeight } = pageContentWrapper.getBoundingClientRect();
    const { cardPlacmentMethod = "maxOverlap" } = this.options;

    // Wait until page height is available...
    setTimeout(() => {
      // Distribute cards...
      this.api.distribute_cards(CARDELS, pageWidth, pageHeight, {
        placementMethod: cardPlacmentMethod,
        minDistance: 50,
        maxOverlap: 0.025,
        topWhitespace: 200,
      });

      // Resolve...
      resolve();
    }, 500);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
