// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect, getWidth } from "mezr";
import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.updt_position", inline: true, disabled: false };
const msgs = {
  start: ["NextPostWidget", "API", "updt_position"],
  end: ["PartiForm", "API", "updt_position", "API call complete"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_position() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Find comment section of inner page layout wrapper...
  const commentSection = document.querySelector("[g-component='CommentSection']");
  const innerLayoutWrapper = document.querySelector("[data-role='innerPageLayoutWrapper']");
  const anchorEl = commentSection || innerLayoutWrapper;
  const anchorEl_isCommentSection = anchorEl === commentSection;
  if (!innerLayoutWrapper && !commentSection) return this.logger("init", msgs.no_anchoEl, "error", logArgs);

  // - Determ. bottom edge of anchor el...
  // - Determ. right edge of anchor el...
  const rect_anchorEl = getRect(anchorEl);
  const b_anchorEl = rect_anchorEl.bottom;
  const t_anchorEl = rect_anchorEl.top;
  const r_anchorEl = rect_anchorEl.right;

  // Measure horizontal space btw. anchor el. right edge and viewport right edge...
  const spaceRight = window.innerWidth - r_anchorEl;

  // Determ. gap btw. anchor el. right edge and comp. el. left edge...
  const gap = (spaceRight - getWidth(this.element)) / 2;

  // Move comp. el. to bottom right corner of anchor el...
  const compEl = this.element;
  const x = r_anchorEl + gap;
  const y = anchorEl_isCommentSection ? t_anchorEl : b_anchorEl;
  gsap.to(compEl, { x, y });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
