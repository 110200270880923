// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_window_resize(ARGS) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  //////////////////////////////////// is-mobile state updt. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Updt. state...
  const is_mobile = window.innerWidth < 768;
  this.setState({ is_mobile });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
