// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.add_matrixAnswerGraphDataPoint", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "add_matrixAnswerGraphDataPoint"],
  end: ["PartiForm", "API", "add_matrixAnswerGraphDataPoint", "API call complete"],
  no_chartInst: ["PartiForm", "API", "add_matrixAnswerGraphDataPoint", "No chart inst. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function add_matrixAnswerGraphDataPoint(DATAPOINT) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Get answer graph chart isntance...
  const chartInst = this.modules.matrixAnswerGraph.chart;
  if (!chartInst) return this.logger("warning", msgs.no_chartInst, "warning", logArgs);

  // Format data point...
  const { answerText, answerImg, xValue, yValue } = DATAPOINT;
  const answerImgURL = answerImg ? answerImg.dataURL : null;
  const dataPoint_formatted = { answerText, answerImgURL, x: parseFloat(xValue), y: parseFloat(yValue), is_currUser: true };

  // Add data point to chart...
  const currData = chartInst.data.datasets[0].data;
  const newDataPointIndex = currData.length;
  dataPoint_formatted.index = newDataPointIndex;
  chartInst.data.datasets[0].data.push(dataPoint_formatted);
  chartInst.update("none");

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
