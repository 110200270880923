// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_gameSelectBtn_mouseEnter", inline: true };
const loggerMsgs = {
  eventStart: ["mouse entered game-select. btn."],
  noBtnEl: ["no btn. el. provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_gameSelectBtn_mouseEnter(BTNEL) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!BTNEL) return this.logger("warning", loggerMsgs.noBtnEl, "warning", logArgs);

  ////////////////////////////
  // Btn. SVG border expand //
  ////////////////////////////

  const borderSVG = BTNEL.querySelector("[data-ref='borderSVG']");
  const borderShape = borderSVG?.querySelector("rect");
  if (!borderShape) return;
  // gsap.to(BTNEL, { duration: 0.25, borderRadius: "0px" });
  gsap.to(borderShape, { duration: 0.5, attr: { rx: 0, ry: 0 } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
