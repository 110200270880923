// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.updt_wordViewer_wordDisplayCard", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "updt_wordViewer_wordDisplayCard"],
  end: ["PartiForm", "API", "updt_wordViewer_wordDisplayCard", "API call complete"],
  no_wordCard: ["PartiForm", "API", "updt_wordViewer_wordDisplayCard", "No corresp. word-display-card found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_wordViewer_wordDisplayCard(
  DATA = {
    word: null,
    wordCatID: null,
  }
) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { word, wordCatID } = DATA;
  const { wordViewer } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Find the word-display-card with the corresponding word-category-id...
  const correspWordCard = wordViewer.querySelector(`[data-cat-id="${wordCatID}"]`);

  // Guard...
  if (!correspWordCard || correspWordCard == undefined) return this.logger("error", msgs.no_wordCard, "error", logArgs);

  // Insert word into corresp. word-display-card...
  correspWordCard.innerHTML = word;

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
