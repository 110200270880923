// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_enterWordViewerBtn_click from "../eventHandlers/eh_enterWordViewerBtn_click.js";
import eh_wordViewerNextBtn_click from "../eventHandlers/eh_wordViewerNextBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_wordViewer", inline: true };
const msgs = {
  initStart: ["init. wordViewer"],
  initSuccess: ["init. wordViewer", "init. success"],
  noValidDOMEl: ["init. wordViewer", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_wordViewer() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { wordViewer, enterWordViewerBtn, wordViewerDownloadBtn, wordViewerNextBtn } = this.ref;
  this.logger("init", msgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(wordViewer)) return this.cancel_featInit(msgs.noValidDOMEl);

  /////////////////////// Enter-viewer btn. init. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (enterWordViewerBtn) enterWordViewerBtn.addEventListener("click", eh_enterWordViewerBtn_click.bind(this));

  /////////////////////// Download-btn. init. ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (wordViewerDownloadBtn) wordViewerDownloadBtn.addEventListener("click", () => this.api.download_wordViewerImg());

  //////////////////////////// Next-btn. init. ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (wordViewerNextBtn) wordViewerNextBtn.addEventListener("click", eh_wordViewerNextBtn_click.bind(this));

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
