// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.disable_cardDrag_all", inline: true };
const msgs = {
  start: ["DragCards", "API", "disable_cardDrag_all", "disabling all card dragging"],
  end: ["DragCards", "API", "disable_cardDrag_all", "all card dragging disabled"],
  no_dragCardInst: ["DragCards", "API", "disable_cardDrag_all", "no drag. card instance(s) found"],
  alreadyDisabled: ["DragCards", "API", "disable_cardDrag_all", "card dragging already disabled"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function disable_cardDrag_all() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Guard: stop, if card dragging is already disabled...
  if (!this.state.cardDragEnabled) return this.logger("warning", msgs.alreadyDisabled, "warning", logArgs);

  const dragCardInstances = this.modules.cards.instances;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard: stop, if no drag. card instances found...
  if (!dragCardInstances || !dragCardInstances.length) return this.logger("error", msgs.no_dragCardInst, "error", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Disable all drag. card instances...
  dragCardInstances.forEach((c) => c.dragInst[0].disable());
  this.setState({ cardDragEnabled: false });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.end, "event", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
