// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.fetch_postComments", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "fetch_postComments"],
  end: ["PartiForm", "API", "fetch_postComments", "API call complete"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function fetch_postComments(POSTID) {
  return new Promise((resolve, reject) => {
    //////////////////////////////// Setup /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.logger("event", msgs.start, "event", logArgs);

    ///////////////////////////// Data fetch. //////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // Fetch comments data from posts’s JSON route...
    const url = `/${POSTID}.json`;
    try {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          // Guard...
          if (!data) return reject("No data found on page JSON route.");

          // Resolve...
          resolve(data);
        })
        .catch((err) => {
          // Guard...
          if (err) return reject(err);

          // Resolve...
          resolve([]);
        });
    } catch (error) {
      reject(error);
    }

    /////////////////////////////// Conclude ///////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.logger("success", msgs.end, "success", logArgs);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
