// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_cardSlideShow", inline: true, disabled: false };
const msgs = {
  start: ["init. cardSlideShow"],
  end: ["init. cardSlideShow", "init. success"],
  no_el: ["init. cardSlideShow", "no valid DOM el. provided"],
  no_slidesWrapper: ["init. cardSlideShow", "no slidesWrapper el. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_cardSlideShow(EL_card) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(EL_card)) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Init. flickity inst...
  const slidesWrapper = EL_card.querySelector("[data-ref='slidesWrapper']");
  if (!slidesWrapper) return this.cancel_featInit(msgs.no_slidesWrapper);
  const flickityInst = new Flickity(slidesWrapper, {
    prevNextButtons: false,
    pageDots: false,
    wrapAround: true,
    cellAlign: "center",
    adaptiveHeight: true,
    selectedAttraction: 0.025,
    friction: 0.28,
    draggable: false,
    fade: false,
    imagesLoaded: true,
  });

  // Init. slide show controls...
  const prevBtn = EL_card.querySelector("[g-ref='prevBtn']");
  const nextBtn = EL_card.querySelector("[g-ref='nextBtn']");
  if (prevBtn) prevBtn.addEventListener("click", () => flickityInst.previous());
  if (nextBtn) nextBtn.addEventListener("click", () => flickityInst.next());

  // Init. slide-change hdl...
  const credits = EL_card.dataset.credits;
  const has_credits = valid_str(credits);
  const imgCreditsDisplay = EL_card.querySelector("[data-ref='imgCreditsDisplay']");
  flickityInst.on("change", (index) => {
    // Set active img credits...
    if (has_credits && imgCreditsDisplay) {
      const credits_parsed = JSON.parse(credits);
      let activeImgCredits = credits_parsed[index];
      if (!activeImgCredits || activeImgCredits === undefined) activeImgCredits = "";
      imgCreditsDisplay.textContent = activeImgCredits;
    }
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
